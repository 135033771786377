<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 669 83.25">
    <defs>
      <style>
        .cls-1 {
          fill: #fdff00;
          stroke: #2e3192;
        }
  
        .cls-1, .cls-2, .cls-3, .cls-4 {
          stroke-miterlimit: 10;
        }
  
        .cls-5 {
          fill: #873000;
        }
  
        .cls-5, .cls-6 {
          stroke-width: 0px;
        }
  
        .cls-7 {
          letter-spacing: -.02em;
        }
  
        .cls-8 {
          font-family: MyriadPro-Regular, 'Myriad Pro';
          font-size: 12px;
        }
  
        .cls-2 {
          stroke: #ff371f;
        }
  
        .cls-2, .cls-3, .cls-4 {
          fill: none;
        }
  
        .cls-9 {
          letter-spacing: 0em;
        }
  
        .cls-3 {
          stroke: #fff;
          stroke-width: 10px;
        }
  
        .cls-4 {
          stroke-dasharray: 0 0 8 2;
          stroke-width: 2px;
        }
  
        .cls-10 {
          letter-spacing: -.04em;
        }
      </style>
    </defs>
    <g id="revealR">
      <rect class="cls-5" x="615.5" y="19.75" width="53" height="60"/>
      <path class="cls-6" d="M668,20.25v59h-52V20.25h52M669,19.25h-54v61h54V19.25h0Z"/>
    </g>
    <g id="revealL">
      <rect class="cls-5" x=".5" y="19.75" width="53" height="63"/>
      <path class="cls-6" d="M53,20.25v62H1V20.25h52M54,19.25H0v64h54V19.25h0Z"/>
    </g>
    <path id="curveReveal" class="cls-3" d="M61,54.25c138.53,0,393.1,0,546,0"/>
    <text class="cls-8 dimAB" transform="translate(311.04 77.89)">A-B Width</text>
    <polygon class="cls-1" points="614.5 26.75 54 26.42 54 34.42 614.5 34.42 614.5 26.75"/>
    <g id="measureAB">
      <g>
        <line class="cls-4 dimABs" x1="64.4" y1="42.25" x2="604.6" y2="43.24"/>
        <path class="dimAB" d="M78.06,51.88c.3-.46.16-1.08-.31-1.38l-12.89-8.24,12.92-8.2c.47-.3.61-.91.31-1.38-.3-.47-.92-.6-1.38-.31l-14.25,9.04c-.29.18-.46.5-.46.84s.17.66.46.84l14.21,9.09c.17.11.35.16.54.16.33,0,.65-.16.85-.46Z"/>
        <path class="dimAB" d="M590.91,52.82c-.3-.47-.16-1.08.31-1.38l12.92-8.2-12.89-8.24c-.47-.3-.6-.92-.31-1.38.3-.46.92-.6,1.38-.3l14.21,9.09c.29.18.46.5.46.84s-.18.66-.46.84l-14.25,9.04c-.17.11-.35.16-.54.16-.33,0-.65-.17-.84-.46Z"/>
      </g>
    </g>
    <line id="guideLReveal" class="cls-2" x1="61.5" y1="12.25" x2="61.5" y2="63.06"/>
    <text class="cls-8" transform="translate(604.3 10.04)"><tspan x="0" y="0">B</tspan></text>
    <text class="cls-8" transform="translate(57.3 10.04)"><tspan x="0" y="0">A</tspan></text>
    <line id="guideLReveal-2" data-name="guideLReveal" class="cls-2" x1="607.6" y1="11.78" x2="607.6" y2="62.6"/>
  </svg>