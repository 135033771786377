import { NgModule } from "@angular/core";
import { Routes, RouterModule, ExtraOptions } from "@angular/router";
import { HomeComponent } from "../pages/home/home.component";
import { TermsComponent } from "../pages/terms/terms.component";
import { PrivacyComponent } from "../pages/privacy/privacy.component";
import { GdprComponent } from "../pages/gdpr/gdpr.component";
import { BlogComponent } from "../pages/blog/blog.component";
import { BlogFullPostComponent } from "../pages/blog-full-post/blog-full-post.component";
import { FaqComponent } from "../pages/faq/faq.component";
import { PricingComponent } from "../pages/pricing/pricing.component";
import { LoginRegisterComponent } from "../components/users/login-register/login-register.component";
import { RetailersComponent } from "../pages/retailers/retailers.component";
import { AccountComponent } from "../pages/account/account.component";
import { OrdersComponent } from "../pages/orders/orders.component";
import { RegisterComponent } from "../components/users/register/register.component";
import { CurvesComponent } from "../pages/curves/curves.component";
import { TracksComponent } from "../pages/tracks/tracks.component";
import { MapComponent } from "../components/map/map.component";
import { AccountHomeComponent } from "../pages/account/account-home/account-home.component";
import { RegisterCustomerComponent } from "../components/users/register-customer/register-customer.component";

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: "enabled",
  anchorScrolling: "enabled",
  scrollOffset: [0, 64],
  relativeLinkResolution: "legacy",
  onSameUrlNavigation: "reload",
  enableTracing: true,
};

const routes: Routes = [
  { path: "home", component: HomeComponent },
  { path: "retailers", component: MapComponent },
  { path: "terms", component: TermsComponent },
  { path: "privacy", component: PrivacyComponent },
  { path: "gdpr", component: GdprComponent },
  { path: "faq", component: FaqComponent },
  { path: "blog", component: BlogComponent },
  { path: "pricing", component: PricingComponent },
  { path: "blog/:blogId", component: BlogFullPostComponent },
  { path: "login", component: LoginRegisterComponent },
  { path: "account-home", component: AccountHomeComponent },
  { path: "account", component: AccountComponent },
  { path: "orders", component: OrdersComponent },
  { path: "curved-headrails", component: CurvesComponent },
  { path: "curtain-tracks", component: TracksComponent },
  {
    path: "register/:websiteID/:customerID/:email/:first/:last",
    component: RegisterComponent,
  },
  {
    path: "register/:websiteID/:customerID/:email/:company",
    component: RegisterCustomerComponent,
  },
  { path: "", component: HomeComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
