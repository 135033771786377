import { Component, OnInit } from '@angular/core';
import { OpeningTime } from 'src/app/models/OpeningTime';
import { DataService } from 'src/app/services/data.service';
import * as moment from 'moment';

@Component({
  selector: 'app-opening-times',
  templateUrl: './opening-times.component.html',
  styleUrls: ['./opening-times.component.scss']
})

export class OpeningTimesComponent implements OnInit {

  openingtimes: OpeningTime[];

  isDay = true;
  isOpen = false;
  isClosingSoon = false;
  openStateClass = '';
  today: OpeningTime;

  weekday = 0;

  constructor(private dataService: DataService) {
    dataService.GetOpeningTimes().subscribe((data) => {
      this.openingtimes = data;
      this.SetTimes();
    });
   }

  ngOnInit(): void {
    this.setTimer();
  }

  setTimer() {
    setInterval(
      () => {
        this.SetTimes();
      },
      60000
    );
  }

  SetTimes() {
    this.weekday = moment().weekday();
    this.today = this.openingtimes.find(f => f.id === this.weekday);
    // tslint:disable-next-line: max-line-length
    this.isOpen = moment().isBetween( moment(this.today.open, 'hh:mm'), moment(this.today.closed, 'hh:mm'));
    // tslint:disable-next-line: max-line-length
    this.isClosingSoon = moment().isBetween( moment(this.today.closed, 'hh:mm').subtract(1, 'hour'), moment(this.today.closed, 'hh:mm'));

    this.SetOpenStateClass();
  }

  SetOpenStateClass() {
    this.openStateClass = this.isOpen ? this.isClosingSoon ? 'table-warning' : 'table-success' : 'table-danger';
  }
}
