<div class="card card-padding">
    <div class="row">

        <div class="col-12 col-md-3 text-center pt-3">
            <i class="fa-8x icon-colour" [ngClass]="icon"></i>
        </div>

        <div class="col-12 col-md-6">
            <div class="card-body">
                <h2 class="card-title">{{title}}</h2>
                <p class="card-text my-4">
                    {{discription}}
                </p>
            </div>
        </div>

        <div class="col-12 col-md-3">
            <button class="btn btn-outline-success btn-large" [routerLink]="[route]" [queryParams]="params">
                {{buttonText}}
            </button>
        </div>

    </div>
</div>