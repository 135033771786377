<div class="container">

<div class="row">
  <h1>
    By choosing Custom Web Solutions Ltd for your project, you agree to the following terms.
  </h1>
  <div class="col-12 wow fadeInUp">
    <h2>1. Ownership of the website/software</h2>
      <p><b>When choosing the traditional way:</b></p>
      <p>
        Once the final payment has cleared which is due on completion of the project and is required before the site goes live as the client you will then own the website but do not have the legal right to re sell the design or development of the project.
      </p>
      <p><b>When choosing the “Cashflow Workflow” way:</b></p>
      <p>We will maintain ownership of the website/software and continue maintenance and any packages chosen alongside for the duration of the time you stay with us. A fee may be payable if you choose to discontinue services with us in order to gain ownership of the website/software.</p>
    </div>
<hr>
  <div class="col-12 wow fadeInUp">
    <h2>2. Ownership of the domain name</h2>
      <p>
        Website domain names are leased from domain name registration companies. When Custom Web Solutions Ltd registers a domain name for a client, that registration will be made in the client’s name. Upon payment of any outstanding fees, the client has the right to use that domain name regardless of whether he/she chooses to continue further services with Custom Web Solutions Ltd. Domain name renewal and renewal fees are the responsibility of the client. If the client chooses to have Custom Web Solutions renew the domain name on behalf of the client, an additional service fee will apply. Clients are advised to closely monitor the renewal date of a registered domain name and to verify that the renewal of said domain name has been completed. Custom Web Solutions Ltd cannot be held responsible for expired domain names.
      </p>
  </div>
  <hr>

  <div class="col-12 wow fadeInUp">
    <h2>3. Ownership and copyright</h2>
      <p>
        During the discovery phase of your project we will ask you to provide the copy and images to be used in your project, these will continue to be owned by you but you must initially have permission or ownership to use all content you provide to us for use. It is up to you as the client to make sure your design and content do not go against any trademark or copyright. Custom Web Solutions Ltd cannot be held responsible for intellectual property infringements on your behalf or for content submitted.
      </p>
      <p>
        Should you need Custom Web Solutions Ltd to create this content for you further charges will apply.
      </p>
  </div>
  <hr>

  <div class="col-12 wow fadeInUp">
    <h2>4. Project initiation</h2>
      <p>
        The initiation of the website/software requires the 10% deposit to be paid and cleared in our account, all copy and content (images, logos) plus any other information required in order to be able to start the project within 10 working days of the project start date.
      </p>
  </div>
  <hr>

  <div class="col-12 wow fadeInUp">
    <h2>5. Project timelines and penalties</h2>
      <p>
        During the discovery part of the process we will give you an estimate of how long the design process will take which can only start once we receive all content required from you. Once the design is complete you are invited to review the designs and let us know of any changes you would like to make. The nature of those changes may impact on timescales and prices may need to be re-quoted.
      </p>
      <p>
        Once the design has been completed we will advise you on how long the Build stage will take.
      </p>
      <p>
        Custom Web Solutions will keep your project on track and within the timeframes specified and agreed to the best of their abilities, we can not be held responsible for delays outside of our control or where we are waiting on content or decisions from you.
      </p>
  </div>
  <hr>

  <div class="col-12 wow fadeInUp">
    <h2>6. How content can be submitted</h2>
      <p>
        All content must be provided via email in a clear manner stating what each piece of content is for to allow us to use it in your design/build in a timely and efficient manner.
      </p>
  </div>
  <hr>

  <div class="col-12 wow fadeInUp">
    <h2>7. Website maintenance</h2>
      <p><b>When choosing the Traditional way:</b></p>
      <p>
        All maintenance work including but not limited to content changes, bug fixing, adding additional pages, adding further functionality are chargeable at current hourly rate. All requests must be submitted by email where we will then provide a quote in terms of how long the work will take to complete based on the requests made. All work will be completed on our test site and will go live once the work has been signed off by you and on the day the payment has cleared in our account.
      </p>
      <p><b>When choosing the “Cashflow Workflow” way:</b></p>
      <p>General maintenance of the site including bug fixing, hosting fees and minor content changes such as text or images is included in your monthly payments. Any additional work such as extra pages or functionality may incur an extra fee.</p>
  </div>
  <hr>

  <div class="col-12 wow fadeInUp">
    <h2>8. Technical support</h2>
      <p>
        If your website is hosted by Custom Web Solutions Ltd you have access to technical support 24 hours a day. Please note technical support cannot be provided for third party products or services.
      </p>
  </div>
  <hr>

  <div class="col-12 wow fadeInUp">
    <h2>9. Payment policies</h2>
        <p><b>When choosing the Traditional way:</b></p>
      <p>
        10% of the project price is required at the build stage once all designs are confirmed. Once the project is completed the remaining balance of the project is due, the site will go live once this balance has cleared. As the client you will then own the website but do not have the legal right to re sell the design or development of the project.
      </p>
      <p><b>When choosing the “Cashflow Workflow” way:</b></p>
      <p>
        The cost of the project will be calculated and 10% off this price will be required at the build stage once all designs are confirmed. The remaining balance is then built into the monthly instalments which includes items set out in 7. Website Maintenance, these monthly payments must be set up by direct debit via our payment service and will continue for the lifetime of the service. Should a payment be missed it must be settled within 30 days of that missed payment. Should a payment lapse by 60 days the site will be removed until payments are re established. Should at any point you come under financial difficulty please do contact us at your earliest convenience so we can discuss payment options.
      </p>
      <p>For either payment option – should the project change significantly such as but not limited to extra functionality or pages be required we reserve the right to extend the length of time it will take to complete the project and re quote the entire project.</p>
  </div>
  <hr>

  <div class="col-12 wow fadeInUp">
    <h2>10. Billing errors</h2>
      <p>
        If Custom Web Solutions Ltd make a billing error please do notify us straight away. We will refund any over payment if notified within 14 working days so please do check your accounts regularly Should Custom Web Solutions Ltd undercharge you Custom Web Solutions Ltd reserve the right to bill you for undercharges for up to 3 months. Refunds after 60 days will not be processed.
      </p>
  </div>
  <hr>

  <div class="col-12 wow fadeInUp">
    <h2>11. SEO and Social Media plans.</h2>
      <p>
        Our Marketing Plans offer a variety of services, including search engine optimization and social media (Facebook, Twitter, etc.) management. These plans require a three month minimum since it takes time to ramp up the effectiveness of these campaigns. After three months, the Marketing Plans convert to month- to-month plans which may be cancelled at any time with a 30 day written notification. Services provided in the marketing plans will be specified in a written agreement since plans may be tailored to meet each client’s needs. No service or standard of service is implied unless in writing in the agreement, thus, clients must be sure to specify any specific request (such as a particular number of followers or a particular social networking tool used, etc.) in writing.
      </p>
  </div>

  <div class="col-12 wow fadeInUp">
    <h2>12. Quality guarantee</h2>
      <p>
        We take pride in offering high quality services and professional customer service. While we make every effort to ensure every aspect of our service is to the client’s liking, we cannot control every variable. It is up to each client to regularly test the functionality of their website/software, email, and any other product or service to ensure everything works as desired. If any problem is discovered or error has been made, please report the problem to us immediately so we may take appropriate action. Custom Web Solutions Ltd cannot be responsible for perceived loss of revenue under any circumstances. Content changes resulting from a client’s error and/or problems related to third party vendors or technology, such as hosting servers or browser software may result in additional charges.
      </p>
  </div>
  <hr>

  <div class="col-12 wow fadeInUp">
    <h2>13. Cancellation of services</h2>
      <p>
        If you decide you would not like to continue the development of your project with Custom Web Solutions Ltd during the discovery or design phase no fee is incurred.
      </p>
      <p>
        Once the deposit is paid for the project this is non refundable. If you decide you would not like to continue the development of your project once the build phase, we will retain the right to own any completed work and you will be responsible for any payments due to cover the work completed and any monthly instalments up until 30 days after the cancellation has been received and acknowledged by Custom Web Solutions Ltd.
      </p>
      <p>
        Should you wish to terminate services you must contact us in writing.
      </p>
  </div>
  <hr>

  <div class="col-12 wow fadeInUp">
    <h2>14.	Third party vendors</h2>
      <p>
        Third party vendors may have separate terms of service agreements. Custom Web Solutions Ltd cannot be held liable for disputes with third party vendors. Custom Web Solutions Ltd cannot be held responsible for lack of functionality or any other aspect of third party services.
      </p>
  </div>
  <hr>

  <div class="col-12 wow fadeInUp">
    <h2>15. Refusal of service</h2>
      <p>
        Custom Web Solutions Ltd reserves the right to decline to do business with any client for any reason.
      </p>
  </div>
  <hr>

  <div class="col-12 wow fadeInUp">
    <h2>16. Changes to terms of service</h2>
      <p>
        Custom Web Solutions Ltd reserves the right to change these terms of service at any time for any reason
      </p>
  </div>

</div>
</div>
