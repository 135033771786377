<div class="c-blue-dark-dk-bk pt-5 pb-5">
  <div class="container">
    <div class="p-3">


      <div class="row text-center">
        <div class="col-sm-5">
            <img class="img-fluid" src="./assets/images/contact_us.jpg">
        </div>
        <div class="col-sm-7">
          <div class="text-center text-white">
            <h2>{{title}}</h2>
            <p class="contact text-white">{{message}}</p>
            <div class="">

              <a class="contact contact-tel pb-3" [href]="'tel:{{contactTel}}'">{{contactTel}}</a><br>
              <p class="contact contact-addr">{{contactAddr}}</p>
              <a class="contact contact-email" [href]="'mailTo:{{contactEmail}}'">{{contactEmail}}</a>


            </div>
        </div>

        </div>

      </div>

    </div>
  </div>
</div>

