<div class="delete-dialog">
  <div class="text-center">
    <h1 mat-dialog-title>{{data.MessageTitle}}</h1>

    <div class="text-center">
      <b>{{data.MessageSub}}</b>
    </div>
    <br />
    <div mat-dialog-content>
      <p>
        {{data.Message}}
      </p>
    </div>
    <br />
    <div mat-dialog-actions>
      <div class="container">
        <div class="row">
          <div class="col-sm-6">
            <button class="btn btn-default w-100" focus mat-dialog-close>Cancel</button>
          </div>
          <div class="col-sm-6">
            <button class="btn btn-danger w-100" [mat-dialog-close]="true">Yes, Confirm</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

