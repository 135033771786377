
export interface IContact {
  email: string;
  name: string;
  phone: string;
  desc: string;
  client: string;
}

export class Contact implements IContact {
  email: string;
  name: string;
  phone: string;
  desc: string;
  client: string;
}
