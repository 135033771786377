<div class="col-12 wow fadeInUp" data-wow-delay="0.6s">

  <div class="pt-2 pb-2">
    <div class="text-center">

      <h3 *ngIf="showTitle" class="title">{{title}}</h3>

      <h4 *ngIf="showSubTitle" class="subTitle">{{subtitle}}</h4>

      <p *ngIf="showText" class="text">{{text}}</p>

      <span *ngIf="showFooter" class="footer">{{footer}}</span>

    </div>
  </div>

</div>
