<div class="row">
    <div class="col">
        <app-product [product]="product" [showFull]="true"></app-product>
    </div>
</div>

<div class="row">
    <div class="col">

        <div class="d-flex">
            <div class="col-6 d-flex justify-content-center">
                <app-numeric-input [min]="1" [max]="99" [buttonText]="'Add to Order'" (number)="setNumber($event)"
                    (numberEntered)="addExtra($event)"></app-numeric-input>
            </div>

            <div class="col-6 text-start totals ms-2 p-2">
                <app-attribute-display [title]="'Item Total'" [big]="true" [value]="product.price * quantity | currency:'GBP'"></app-attribute-display>
            </div>
        </div>
    </div>
    <div class="d-flex justify-content-end">
        <div class="vat p-2">
            <span>All prices are subject to 20% Vat</span>
        </div>
    </div>
</div>