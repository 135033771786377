import { Component, Input, OnInit } from '@angular/core';
import { Curve } from 'src/app/models/orders';
import { OrderItem } from 'src/app/services/API.service';

@Component({
  selector: 'app-curve-item-view',
  templateUrl: './curve-item-view.component.html',
  styleUrls: ['./curve-item-view.component.scss']
})
export class CurveItemViewComponent implements OnInit {

  curve: Curve;
  @Input() orderItem: OrderItem;

  constructor() { }

  ngOnInit(): void {
    this.ParseToCurve();
  }
  ParseToCurve() {
    this.curve = JSON.parse(this.orderItem.customerInputs) as Curve;
  }
}
