<div class="item-box" (click)="GalleryItemClicked()">
  <img class="item-image" [src]="safeSRC">

  <div class="overlay text-white">
    <i class="item-icon fas 3x expand-arrows-alt"></i>
    <i class="item-tl fas fa-3x chevron-left"></i>
    <i class="item-tr fas fa-3x chevron-left"></i>
    <i class="item-bl fa-3x chevron-left"></i>
    <i class="item-br fa-3x chevron-left"></i>
  </div>
</div>
