<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 670.71 211.37">
    <defs>
      <style>
        .cls-1 {
          letter-spacing: 0em;
        }
  
        .cls-2 {
          stroke: #ef3f2b;
        }
  
        .cls-2, .cls-3 {
          fill: none;
        }
  
        .cls-2, .cls-3, .cls-4 {
          stroke-miterlimit: 10;
        }
  
        .cls-3 {
          stroke-dasharray: 0 0 8 2;
          stroke-width: 2px;
        }
 
        .cls-5, .cls-7, .cls-8 {
          stroke-width: 0px;
        }
  
        .cls-9 {
          letter-spacing: 0em;
        }
  
        .cls-6 {
          font-family: MyriadPro-Regular, 'Myriad Pro';
          font-size: 12px;
        }
  
        .cls-10 {
          letter-spacing: 0em;
        }
  
        .cls-11 {
          letter-spacing: .01em;
        }
  
        .cls-7 {
          fill: #fff;
        }
  
        .cls-8 {
          fill: #87331b;
        }
  
        .cls-4 {
          fill: #f5eb16;
          stroke: #2e3690;
        }
  
        .cls-12 {
          letter-spacing: .01em;
        }
  
        .cls-13 {
          letter-spacing: 0em;
        }
      </style>
    </defs>
    <g id="revealR">
      <rect class="cls-8" x="617.21" y="150.87" width="53" height="60"/>
      <path class="cls-5" d="M669.71,151.37v59h-52v-59h52M670.71,150.37h-54v61h54v-61h0Z"/>
    </g>
    <g id="revealL">
      <rect class="cls-8" x=".5" y="152.85" width="53" height="56"/>
      <path class="cls-5" d="M53,153.35v55H1v-55h52M54,152.35H0v57h54v-57h0Z"/>
    </g>
    <polygon class="cls-4" points="56.23 156.86 51.77 149.59 181.89 72.14 337.61 39.69 487.9 71.59 618.22 149.6 614.02 156.86 484.92 79.28 337.18 48.15 185.06 79.47 56.23 156.86"/>
    <line id="guideA" class="cls-2" x1="358.67" y1="47.99" x2="311.81" y2="47.99"/>
    <line id="guideAB" class="cls-2" x1="360.31" y1="73.11" x2="313.45" y2="73.11"/>
    <line id="guideLReveal" class="cls-2" x1="40.93" y1="134.82" x2="65.84" y2="172.61"/>
    
    <path class="cls-7" d="M68.42,176.75c8.68-8.46,127.33-74.91,127.45-74.97,0,0,140.46-28.68,140.77-28.68l140.23,30.06c.34.09,122.47,71.13,122.47,71.13,1.72,1.04,2.22,3.31,1.1,4.98h0c-1.05,1.57-3.15,2.04-4.77,1.06l-121.41-70.63s-137.22-29.74-137.53-29.66l-137.44,27.89-126.07,73.77s-5.64-4.14-4.81-4.96Z"/>
    
    <g id="measureCE">
      <g>
        <line class="cls-3 dimCEs" x1="340.42" y1="60.48" x2="610.72" y2="164.26"/>
        <path class="dimCE" d="M349.74,74.34c.44-.33.54-.96.21-1.4l-9.1-12.3,14.99-3.05c.54-.11.89-.64.78-1.18-.11-.54-.64-.89-1.18-.78l-16.53,3.36c-.33.07-.61.3-.73.62-.12.32-.07.68.13.95l10.03,13.56c.12.16.27.27.45.34.31.12.67.08.96-.13Z"/>
        <path class="dimCE" d="M594.52,168.32c-.11-.54.24-1.07.78-1.18l14.99-3.05-9.1-12.3c-.33-.44-.24-1.07.21-1.4.44-.33,1.07-.23,1.4.21l10.03,13.56c.2.27.25.63.13.95s-.4.55-.73.62l-16.53,3.36c-.19.04-.39.02-.56-.05-.31-.12-.55-.39-.62-.74Z"/>
      </g>
    </g>
    <g id="measureY">
      <g>
        <line class="cls-3 dimYs" x1="337.87" y1="76.21" x2="337.66" y2="101.8"/>
        <path class="dimY" d="M328.16,89.8c.46.3,1.08.17,1.38-.3l8.32-12.84,8.12,12.97c.29.47.91.61,1.38.32.47-.29.61-.92.32-1.38l-8.95-14.3c-.18-.29-.5-.47-.84-.47s-.66.17-.85.46l-9.18,14.16c-.11.17-.16.35-.16.54,0,.33.16.66.46.85Z"/>
      </g>
    </g>
    <g id="measureY-2" data-name="measureY">
      <g>
        <line class="cls-3 dimYs" x1="337.77" y1="44.99" x2="337.77" y2="16.4"/>
        <path class="dimY" d="M347.37,31.32c-.46-.3-1.08-.16-1.38.31l-8.22,12.9-8.22-12.9c-.3-.47-.92-.61-1.38-.31-.46.3-.6.92-.31,1.38l9.06,14.23c.18.29.5.46.84.46s.66-.18.84-.46l9.06-14.23c.11-.17.16-.35.16-.54,0-.33-.16-.65-.46-.85Z"/>
      </g>
    </g>
    <g id="measureBE">
      <g>
        <line class="cls-3 dimBEs" x1="191.99" y1="90.26" x2="609.99" y2="165.08"/>
        <path class="dimBE" d="M203.75,102.11c.37-.41.35-1.04-.06-1.41l-11.25-10.36,14.15-5.82c.51-.21.76-.79.55-1.3-.21-.51-.8-.75-1.31-.55l-15.6,6.41c-.31.13-.54.41-.6.75-.06.34.06.68.31.91l12.41,11.43c.15.13.32.22.5.25.32.06.67-.05.91-.31Z"/>
        <path class="dimBE" d="M594.84,172.12c-.21-.51.04-1.09.55-1.3l14.15-5.82-11.25-10.36c-.41-.37-.43-1.01-.06-1.41.37-.41,1.01-.43,1.42-.06l12.41,11.43c.25.23.37.58.31.91-.06.34-.29.62-.6.75l-15.6,6.41c-.18.07-.38.09-.56.06-.32-.06-.62-.28-.75-.6Z"/>
      </g>
    </g>
    <g id="measureAD">
      <g>
        <line class="cls-3 dimADs" x1="63.96" y1="168.11" x2="479.61" y2="89.3"/>
        <path class="dimAD" d="M79.18,175c.21-.51-.05-1.09-.56-1.3l-14.21-5.67,11.15-10.48c.4-.38.42-1.01.05-1.41-.38-.4-1.02-.42-1.42-.04l-12.29,11.55c-.25.23-.36.58-.3.92.06.34.3.62.61.74l15.67,6.25c.18.07.38.09.56.05.32-.06.61-.28.74-.61Z"/>
        <path class="dimAD" d="M467.97,101.27c-.38-.4-.36-1.04.05-1.41l11.15-10.48-14.21-5.67c-.51-.2-.77-.79-.56-1.3.21-.51.79-.76,1.3-.56l15.67,6.25c.32.13.55.41.61.74.06.34-.05.68-.3.92l-12.29,11.55c-.15.13-.32.22-.5.25-.32.06-.67-.04-.92-.3Z"/>
      </g>
    </g>
    <g id="measureAC">
      <g>
        <line class="cls-3 dimACs" x1="63.43" y1="167.97" x2="335.94" y2="60.5"/>
        <path class="dimAC" d="M79.67,171.89c.11-.54-.25-1.07-.79-1.17l-15.02-2.91,8.99-12.38c.33-.45.23-1.07-.22-1.4-.45-.32-1.08-.22-1.4.22l-9.91,13.65c-.2.28-.25.64-.12.95s.41.55.74.62l16.56,3.21c.19.04.39.02.56-.05.31-.12.55-.39.62-.74Z"/>
        <path class="dimAC" d="M326.74,74.44c-.45-.32-.54-.95-.22-1.4l8.99-12.38-15.02-2.91c-.54-.1-.9-.63-.79-1.17.11-.54.64-.9,1.17-.79l16.56,3.21c.33.07.61.3.74.62.13.32.08.68-.12.95l-9.91,13.65c-.12.16-.27.28-.44.34-.31.12-.67.09-.96-.12Z"/>
      </g>
    </g>
    <g id="measureDE">
      <g>
        <line class="cls-3 dimDEs" x1="495.87" y1="57.57" x2="626.58" y2="135.63"/>
        <path class="dimDE" d="M502.69,72.82c.49-.25.69-.85.44-1.34l-6.87-13.67,15.29-.44c.55-.02.99-.48.97-1.03-.02-.55-.48-.99-1.03-.97l-16.86.49c-.34.01-.65.19-.83.49s-.19.66-.04.96l7.57,15.08c.09.18.22.32.38.41.28.17.65.19.96.04Z"/>
        <path class="dimDE" d="M609.93,136.86c-.02-.55.42-1.01.97-1.03l15.29-.44-6.87-13.67c-.25-.49-.05-1.1.44-1.34.49-.25,1.1-.04,1.34.45l7.57,15.08c.15.3.14.67-.04.96-.18.29-.49.48-.83.49l-16.86.49c-.2,0-.38-.05-.54-.14-.28-.17-.48-.48-.49-.83Z"/>
      </g>
    </g>
    <g id="measureCD">
      <g>
        <line class="cls-3 dimCDs" x1="340.21" y1="24.17" x2="491.45" y2="55.85"/>
        <path class="dimCD" d="M351.62,36.36c.39-.39.38-1.03-.02-1.41l-10.95-10.69,14.32-5.4c.52-.19.78-.77.59-1.29-.2-.52-.78-.78-1.29-.58l-15.78,5.95c-.32.12-.56.4-.63.73-.07.33.04.68.28.92l12.07,11.79c.14.14.31.23.49.26.32.07.67-.03.92-.28Z"/>
        <path class="dimCD" d="M476.11,62.44c-.2-.52.07-1.09.59-1.29l14.32-5.4-10.95-10.69c-.4-.39-.4-1.02-.02-1.41.39-.39,1.02-.4,1.42-.02l12.07,11.79c.24.24.35.59.28.92-.07.33-.31.61-.63.73l-15.78,5.95c-.19.07-.38.08-.56.04-.32-.07-.61-.29-.73-.63Z"/>
      </g>
    </g>
    <g id="measureBC">
      <g>
        <line class="cls-3 dimBCs" x1="178.3" y1="58.75" x2="335.5" y2="24.19"/>
        <path class="dimBC" d="M193.71,65.19c.19-.52-.08-1.09-.6-1.28l-14.37-5.26,10.84-10.8c.39-.39.39-1.02,0-1.41-.39-.39-1.03-.39-1.42,0l-11.95,11.91c-.24.24-.34.59-.27.92.07.33.31.61.63.72l15.84,5.8c.19.07.38.08.56.04.32-.07.6-.3.73-.63Z"/>
        <path class="dimBC" d="M324.21,36.5c-.39-.39-.39-1.02,0-1.41l10.84-10.8-14.37-5.26c-.52-.19-.79-.76-.6-1.28.19-.52.77-.78,1.28-.6l15.84,5.8c.32.12.56.39.63.72.07.33-.03.68-.27.92l-11.95,11.91c-.14.14-.31.23-.49.27-.32.07-.67-.02-.93-.27Z"/>
      </g>
    </g>
    <g id="measureAB">
      <g>
        <line class="cls-3 dimABs" x1="45.56" y1="136.49" x2="173.89" y2="60.49"/>
        <path class="dimAB" d="M62.21,137.79c.02-.55-.42-1.01-.97-1.03l-15.29-.5,6.91-13.65c.25-.49.05-1.1-.44-1.35-.49-.25-1.1-.05-1.35.44l-7.62,15.05c-.15.3-.14.67.03.96s.49.48.83.49l16.86.55c.2,0,.38-.05.54-.14.28-.17.48-.47.49-.83Z"/>
        <path class="dimAB" d="M167.02,75.71c-.49-.25-.69-.85-.44-1.35l6.91-13.65-15.29-.5c-.55-.02-.99-.48-.97-1.03.02-.55.49-.98,1.03-.97l16.86.55c.34.01.65.2.83.49.17.29.19.66.03.96l-7.62,15.05c-.09.18-.22.31-.38.41-.28.17-.65.19-.96.03Z"/>
      </g>
    </g>
    <g id="measureAE">
      <g>
        <line class="cls-3 dimAEs" x1="62.92" y1="168.41" x2="611.13" y2="165.51"/>
        <path class="dimAE" d="M76.64,177.94c.29-.47.15-1.08-.32-1.38l-12.95-8.15,12.86-8.29c.47-.3.6-.92.3-1.38-.3-.46-.92-.59-1.38-.3l-14.18,9.14c-.29.19-.46.5-.46.85s.18.66.47.84l14.28,8.99c.17.1.35.15.54.15.33,0,.65-.17.84-.47Z"/>
        <path class="dimAE" d="M597.51,175.18c-.3-.46-.16-1.08.3-1.38l12.86-8.29-12.95-8.15c-.47-.29-.61-.91-.32-1.38s.92-.6,1.38-.31l14.28,8.99c.29.18.47.5.47.84,0,.34-.17.66-.46.85l-14.18,9.14c-.17.11-.35.16-.54.16-.33,0-.66-.16-.85-.46Z"/>
      </g>
    </g>
    <line id="guideLReveal-2" data-name="guideLReveal" class="cls-2" x1="631.31" y1="132.91" x2="606.3" y2="170.06"/>
    <line id="guideLReveal-3" data-name="guideLReveal" class="cls-2" x1="496.29" y1="49.51" x2="479.92" y2="94.49"/>
    <line id="guideLReveal-4" data-name="guideLReveal" class="cls-2" x1="337.77" y1="14.46" x2="337.77" y2="65.46"/>
    <line id="guideLReveal-5" data-name="guideLReveal" class="cls-2" x1="172.37" y1="51.25" x2="192.65" y2="96.6"/>
    <text class="cls-6" transform="translate(34.75 136.41) rotate(-33.91)"><tspan x="0" y="0">A</tspan></text>
    <text class="cls-6" transform="translate(167.09 47.99) rotate(-24.09)"><tspan x="0" y="0">B</tspan></text>
    <text class="cls-6" transform="translate(333.42 10.04) rotate(.46)"><tspan x="0" y="0">C</tspan></text>
    <text class="cls-6" transform="translate(495.38 42.83) rotate(20)"><tspan x="0" y="0">D</tspan></text>
    <text class="cls-6" transform="translate(632.01 127.53) rotate(31.88)"><tspan x="0" y="0">E</tspan></text>
    <text class="cls-6 dimAB" transform="translate(94.35 101.89) rotate(-33.27)"><tspan class="cls-10" x="0" y="0">A-B</tspan></text>
    <text class="cls-6 dimBC" transform="translate(243.57 38.69) rotate(-12.4)"><tspan class="cls-1" x="0" y="0">B-C</tspan></text>
    <text class="cls-6 dimCD" transform="translate(412.48 33.9) rotate(11.83)"><tspan x="0" y="0">C-D</tspan></text>
    <text class="cls-6 dimDE" transform="translate(552.62 84.37) rotate(30.84)"><tspan class="cls-11" x="0" y="0">D-E</tspan></text>
    <text class="cls-6 dimAC" transform="translate(152.67 127.02) rotate(-21.52)"><tspan class="cls-10" x="0" y="0">A-C</tspan></text>
    <text class="cls-6 dimAD" transform="translate(231.92 130.96) rotate(-10.74)"><tspan class="cls-10" x="0" y="0">A-D</tspan></text>
    <text class="cls-6 dimAE" transform="translate(330.7 164.2)"><tspan class="cls-10" x="0" y="0">A-E</tspan></text>
    <text class="cls-6 dimBE" transform="translate(424.3 126.43) rotate(10.15)"><tspan class="cls-1" x="0" y="0">B-E</tspan></text>
    <text class="cls-6 dimCE" transform="translate(503.17 115.51) rotate(24.16)"><tspan x="0" y="0">C-E</tspan></text>
    <text class="cls-6 dimY" transform="translate(334.19 111.54)"><tspan x="0" y="0">Y</tspan></text>
  </svg>