<ng-container *ngIf="authenticator.route !== 'authenticated'">
    <div class="contaner-fluid h-100">
      <div class="flex-middle h-100">
            <amplify-authenticator initialState="signUp" [hideSignIn]="true" [services]="services" [signUpAttributes]="['address']">
                <ng-template amplifySlot="sign-up-header">
                    <h3 class="amplify-heading" style="padding: var(--amplify-space-xl) 0 0 var(--amplify-space-xl)">
                        <h3>Hi {{company}}</h3>
                        <p>Dereck and Adrian from Bend It Ltd are inviting you to register your retail online account.</p>
                        <p>Just enter a username and password and once your email address is confirmed you'll be able to login.</p>
                    </h3>
                </ng-template>
            </amplify-authenticator>
        </div>
    </div>
</ng-container>