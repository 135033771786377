<div class="card mb-2">
    <div class="row">
        <div class="col">
            <div class="cursor">
                <span (click)="onSelectProduct(product)">{{product.name}}</span>
                <div *ngIf="images.length > 0">
                    <app-image-viewer (selectDocument)="onSelectDocument($event)" [images]="images"></app-image-viewer>
                </div>
                <span *ngIf="!showFull && product.price > 0" (click)="onSelectProduct(product)">{{product.price | currency:'GBP':'symbol':'1.2-2' }}</span>
                <span class="alert alert-primary pt-0 pb-0 pl-3 pr-3" matTooltip="Price will be calculated according to size and options entered" *ngIf="!showFull && product.price === 0" (click)="onSelectProduct(product)">calculated</span>
            </div>
        </div>
        <div *ngIf="showFull" class="col">

            <div class="p-2">
                <app-attribute-display [title]="'title'" [value]="product.title"></app-attribute-display>
                <app-attribute-display [title]="'description'" [value]="product.description"></app-attribute-display>
                <app-attribute-display [title]="'size'" [value]="product.size"></app-attribute-display>
                <app-attribute-display [title]="'colour'" [value]="product.colour"></app-attribute-display>
                <app-attribute-display [title]="'product notes'" [value]="product.productNotes"></app-attribute-display>
                <app-attribute-display *ngIf="product.price > 0" [title]="'price per item'" [value]="product.price | currency:'GBP'"></app-attribute-display>
            </div>
        </div>
    </div>
</div>