import { Component, OnInit } from '@angular/core';
import { btnTypeEnum } from 'src/app/enums/enums';
import { DataService } from 'src/app/services/data.service';
import { IFeature } from 'src/app/models/IFeature';
import { AuthService } from 'src/app/services/auth.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  features1: IFeature[] = [];
  featuresWhyUs: IFeature[] = [];
  featuresTypes: IFeature[] = [];

  btnTypeEnum = btnTypeEnum;

  constructor(private dataservice: DataService, private auth: AuthService) {
    dataservice.GetFeatures().subscribe((data) => {
      this.features1 = data.filter(w => w.set === 1);
      this.featuresWhyUs = data.filter(w => w.set === 2);
      this.featuresTypes = data.filter(w => w.set === 3);
    },
    (err) => {
      console.log(err);
    });
   }
  ngOnInit(): void {
    this.auth.isRegistering.next(false);
  }
}
