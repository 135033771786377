import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vogue-colours',
  templateUrl: './vogue-colours.component.html',
  styleUrls: ['./vogue-colours.component.scss']
})
export class VogueColoursComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
