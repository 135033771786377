<app-blog-header></app-blog-header>
<div class="container">

  <app-category-picker (categoryPicked)="categoryPicked($event)"></app-category-picker>

  <app-tag-picker (tagPicked)="tagPicked($event)"></app-tag-picker>

  <div class="row">
    <div *ngFor="let post of posts" class="col-md-4">
      <div class="col-sm-12 p-3">
        <app-blog-post [post]="post"></app-blog-post>
      </div>
    </div>
  </div>
  
</div>
