import { BrowserModule } from "@angular/platform-browser";
import { NgModule, NO_ERRORS_SCHEMA } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AppRoutingModule } from "./modules/app-routing.module";
import { CustomMaterialModule } from "./modules/material.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { DataService } from "./services/data.service";
import { AuthService } from "./services/auth.service";
import { BlogService } from "./services/blog.service";
import { StorageService } from "./services/storage.service";
import { GalleryService } from "./services/gallery.service";
import { SubscriptionService } from "./services/subscription.service";
import { ArticleComponent } from "./components/articles/article/article.component";
import { CwsLogoComponent } from "./svg/cws-logo/cws-logo.component";
import { HeaderComponent } from "./components/header/header.component";
import { HomeComponent } from "./pages/home/home.component";
import { FooterComponent } from "./components/footer/footer.component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { ArticleCenterComponent } from "./components/articles/article-center/article-center.component";
import { CardComponent } from "./components/cards/card/card.component";
import { CardsComponent } from "./components/cards/cards/cards.component";
import { HttpClientModule } from "@angular/common/http";
import { TermsComponent } from "./pages/terms/terms.component";
import { PrivacyComponent } from "./pages/privacy/privacy.component";
import { GdprComponent } from "./pages/gdpr/gdpr.component";
import { IntroComponent } from "./components/intros/intro/intro.component";
import { BannerParallaxComponent } from "./components/banners/banner-parallax/banner-parallax.component";
import { FeatureComponent } from "./components/features/feature/feature.component";
import { FeatureCardsComponent } from "./components/features/feature-cards/feature-cards.component";
import { FeatureCardComponent } from "./components/features/feature-card/feature-card.component";
import { TimelineWorkflowComponent } from "./components/timelines/timeline-workflow/timeline-workflow.component";
import { ContactFormComponent } from "./components/forms/contact-form/contact-form.component";
import { ModalPopupComponent } from "./components/popups/modal-popup/modal-popup.component";
import { QuoteComponent } from "./components/articles/quote/quote.component";
import { ContactComponent } from "./components/banners/contact/contact.component";
import { FeatureCardImageComponent } from "./components/features/feature-card-image/feature-card-image.component";
import { TeamComponent } from "./components/features/team/team.component";
import { TeamsComponent } from "./components/features/teams/teams.component";
import { TabComponent } from "./components/tabs/tab/tab.component";
import { BlogComponent } from "./pages/blog/blog.component";
import { BlogPostComponent } from "./components/blogs/blog-post/blog-post.component";
import { BlogMiniViewComponent } from "./components/blogs/blog-mini-view/blog-mini-view.component";
import { BlogHeaderComponent } from "./components/blogs/blog-header/blog-header.component";
import { BlogCommentsComponent } from "./components/blogs/blog-comments/blog-comments.component";
import { BlogCommentComponent } from "./components/blogs/blog-comment/blog-comment.component";
import { TagPickerComponent } from "./components/tag-picker/tag-picker.component";
import { CategoryPickerComponent } from "./components/category-picker/category-picker.component";
import { BlogFullPostComponent } from "./pages/blog-full-post/blog-full-post.component";
import { OpeningTimesComponent } from "./components/opening-times/opening-times.component";
import { FaqComponent } from "./pages/faq/faq.component";
import { FilterNamePipe, FilterSearchFieldsPipe } from "./filters";
import { FaqItemComponent } from "./pages/faq/faq-item/faq-item.component";
import { CategoryLinkComponent } from "./components/category-link/category-link.component";
import { TagLinkComponent } from "./components/tag-link/tag-link.component";
import { EmailSignupComponent } from "./components/email-signup/email-signup.component";
import { GalleryComponent } from "./components/gallery/gallery.component";
import { GalleryItemComponent } from "./components/gallery-item/gallery-item.component";
import { QvcComponent } from "./components/features/qvc/qvc.component";
import { PricingComponent } from "./pages/pricing/pricing.component";
import { InfoPanelComponent } from "./components/info-panel/info-panel.component";
import { SliderComponent } from "./components/intros/slider/slider.component";
import { AnimationIntroComponent } from "./components/intros/animation-intro/animation-intro.component";
import { HeaderUserMenuComponent } from "./components/users/header-user-menu/header-user-menu.component";
import { LoginRegisterComponent } from "./components/users/login-register/login-register.component";
import { AmplifyAuthenticatorModule } from "@aws-amplify/ui-angular";
import { RetailersComponent } from "./pages/retailers/retailers.component";
import { GoogleMapsModule } from "@angular/google-maps";
import { MapComponent } from "./components/map/map.component";
import { VogueColoursComponent } from "./components/vogue-colours/vogue-colours.component";
import { BenthinSystemComponent } from "./components/benthin-system/benthin-system.component";

// import { LottieModule } from 'ngx-lottie';
// import player from 'lottie-web';
import { OrdersComponent } from "./pages/orders/orders.component";
import { AccountComponent } from "./pages/account/account.component";
import { OrderFormComponent } from "./components/orders/order-form/order-form.component";
import { OrderTableComponent } from "./components/orders/order-table/order-table.component";
import { ConfirmDeleteComponent } from "./components/confirm-delete/confirm-delete.component";
import { HeadrailEditComponent } from "./components/orders/headrail-edit/headrail-edit.component";
import { OrderItemTableComponent } from "./components/orders/order-item-table/order-item-table.component";
import { CurveItemViewComponent } from "./components/orders/curve-item-view/curve-item-view.component";
import { ItemPickerImageComponent } from "./components/forms/item-picker-image/item-picker-image.component";
import { BowBayComponent } from "./svg/bow-bay/bow-bay.component";
import { SplayBayComponent } from "./svg/splay-bay/splay-bay.component";
import { HockeyLeftComponent } from "./svg/hockey-left/hockey-left.component";
import { HockeyRightComponent } from "./svg/hockey-right/hockey-right.component";
import { CurvePreviewComponent } from "./components/curve-preview/curve-preview.component";
import { API_CWSService } from "./services/API_CWS.service";
import { RegisterComponent } from "./components/users/register/register.component";
import { NewAccGuideComponent } from "./pages/account/new-acc-guide/new-acc-guide.component";
import { CurvesComponent } from "./pages/curves/curves.component";
import { TracksComponent } from "./pages/tracks/tracks.component";
import { BannerContentComponent } from "./components/banners/banner-content/banner-content.component";
import { AccountHomeComponent } from "./pages/account/account-home/account-home.component";
import { DocumentDownloadComponent } from "./components/document-download/document-download.component";
import { RegisterCustomerComponent } from "./components/users/register-customer/register-customer.component";
import { ToastrModule } from "ngx-toastr";
import { OrderDetailsComponent } from "./components/orders/order-details/order-details.component";
import { AddressModalComponent } from "./components/address-modal/address-modal.component";
import { CurtaintrackEditComponent } from './components/orders/curtaintrack-edit/curtaintrack-edit.component';
import { ProductPickerComponent } from './components/orders/product-picker/product-picker.component';
import { ProductComponent } from './components/orders/product/product.component';
import { ExtraItemEditComponent } from './components/orders/extra-item-edit/extra-item-edit.component';
// export function playerFactory() {
//   return player;
// }
import { registerLocaleData } from '@angular/common';
import localeEnGb from '@angular/common/locales/en-GB';
import { ImageViewerComponent } from './components/image-viewer/image-viewer.component';
import { NumericInputComponent } from './components/numeric-input/numeric-input.component';
import { AttributeDisplayComponent } from './components/attribute-display/attribute-display.component';
import { CurtainAngularComponent } from './svg/curtain-angular/curtain-angular.component';
import { CurtainAngularV2Component } from './svg/curtain-angular-v2/curtain-angular-v2.component';
import { CurtainStraightComponent } from './svg/curtain-straight/curtain-straight.component';
import { CurtainUShapedComponent } from './svg/curtain-u-shaped/curtain-u-shaped.component';
import { CurtainLShapedComponent } from './svg/curtain-l-shaped/curtain-l-shaped.component';
import { CurtainCurvedComponent } from './svg/curtain-curved/curtain-curved.component';
import { CurtainAngularThreeSidedComponent } from './svg/curtain-angular-three-sided/curtain-angular-three-sided.component';
import { CurtainAngularFourSidedComponent } from './svg/curtain-angular-four-sided/curtain-angular-four-sided.component';
import { CurtainAngularFiveSidedComponent } from './svg/curtain-angular-five-sided/curtain-angular-five-sided.component';
import { CurtainAngularSixSidedComponent } from './svg/curtain-angular-six-sided/curtain-angular-six-sided.component';
import { BannerIconClickComponent } from './components/banners/banner-icon-click/banner-icon-click.component';
import { ChangeCustomerComponent } from './pages/account/change-customer/change-customer.component';
import { CustomerTableComponent } from './pages/account/customer-table/customer-table.component';
import { LoadingComponent } from './components/loading/loading.component';

@NgModule({
  declarations: [
    AppComponent,
    ArticleComponent,
    CwsLogoComponent,
    HeaderComponent,
    HomeComponent,
    FooterComponent,
    ArticleCenterComponent,
    CardComponent,
    CardsComponent,
    TermsComponent,
    PrivacyComponent,
    GdprComponent,
    IntroComponent,
    BannerParallaxComponent,
    FeatureComponent,
    FeatureCardComponent,
    FeatureCardsComponent,
    TimelineWorkflowComponent,
    ContactFormComponent,
    ModalPopupComponent,
    QuoteComponent,
    ContactComponent,
    FeatureCardImageComponent,
    TeamComponent,
    TeamsComponent,
    TabComponent,
    BlogComponent,
    BlogPostComponent,
    BlogMiniViewComponent,
    BlogHeaderComponent,
    BlogCommentsComponent,
    BlogCommentComponent,
    TagPickerComponent,
    CategoryPickerComponent,
    BlogFullPostComponent,
    OpeningTimesComponent,
    FaqComponent,
    FilterNamePipe,
    FilterSearchFieldsPipe,
    FaqItemComponent,
    CategoryLinkComponent,
    TagLinkComponent,
    EmailSignupComponent,
    GalleryComponent,
    GalleryItemComponent,
    QvcComponent,
    PricingComponent,
    InfoPanelComponent,
    SliderComponent,
    AnimationIntroComponent,
    HeaderUserMenuComponent,
    LoginRegisterComponent,
    RetailersComponent,
    MapComponent,
    VogueColoursComponent,
    BenthinSystemComponent,
    OrdersComponent,
    AccountComponent,
    OrderFormComponent,
    OrderTableComponent,
    ConfirmDeleteComponent,
    HeadrailEditComponent,
    OrderItemTableComponent,
    CurveItemViewComponent,
    ItemPickerImageComponent,
    BowBayComponent,
    SplayBayComponent,
    HockeyLeftComponent,
    HockeyRightComponent,
    CurvePreviewComponent,
    RegisterComponent,
    NewAccGuideComponent,
    CurvesComponent,
    TracksComponent,
    BannerContentComponent,
    AccountHomeComponent,
    DocumentDownloadComponent,
    RegisterCustomerComponent,
    OrderDetailsComponent,
    AddressModalComponent,
    CurtaintrackEditComponent,
    ProductPickerComponent,
    ProductComponent,
    ExtraItemEditComponent,
    ImageViewerComponent,
    NumericInputComponent,
    AttributeDisplayComponent,
    CurtainAngularComponent,
    CurtainAngularV2Component,
    CurtainStraightComponent,
    CurtainUShapedComponent,
    CurtainLShapedComponent,
    CurtainCurvedComponent,
    CurtainAngularThreeSidedComponent,
    CurtainAngularFourSidedComponent,
    CurtainAngularFiveSidedComponent,
    CurtainAngularSixSidedComponent,
    BannerIconClickComponent,
    ChangeCustomerComponent,
    CustomerTableComponent,
    LoadingComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CustomMaterialModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AmplifyAuthenticatorModule,
    GoogleMapsModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: "toast-bottom-center",
      preventDuplicates: true,
    }),
    //  LottieModule.forRoot({ player: playerFactory })
  ],
  schemas: [NO_ERRORS_SCHEMA],
  providers: [
    DataService,
    AuthService,
    BlogService,
    GalleryService,
    SubscriptionService,
    API_CWSService,
    StorageService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(auth: AuthService) {
    console.log("starting AppModule");
    registerLocaleData(localeEnGb);
  }
}
