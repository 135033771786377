  <div class="picker-container container">

    <!-- <app-tag-picker [tags]="tags" (TagsPicked)="TagsPicked($event)"></app-tag-picker> -->


    <div class="row">

      <div class="col-3">
        <mat-form-field class="full-width p-1">
          <mat-label>System</mat-label>
          <mat-select  [(ngModel)]="systemSelected" (selectionChange)="filterProducts()">
            <mat-option *ngFor="let item of systems" [value]="item">
              {{ item.display }}
            </mat-option>
          </mat-select>
        </mat-form-field>
    </div>

    <div class="col-3">
      <mat-form-field class="full-width p-1">
        <mat-label>Type</mat-label>
        <mat-select  [(ngModel)]="typeSelected" (selectionChange)="filterProducts()">
          <mat-option *ngFor="let item of types" [value]="item">
            {{ item.display }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-3">
      <mat-form-field class="full-width p-1">
        <mat-label>Supplier</mat-label>
        <mat-select  [(ngModel)]="supplierSelected" (selectionChange)="filterProducts()">
          <mat-option *ngFor="let item of suppliers" [value]="item">
            {{ item.display }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-3 pt-2 text-start">
      <button class="btn btn-sm btn-outline-primary" (click)="selectTrack('Vertical')">Vertical</button>&nbsp;
      <button class="btn btn-sm btn-outline-primary" (click)="selectTrack('Curtan')">Curtain</button>&nbsp;
      <button class="btn btn-sm btn-outline-primary" (click)="resetSelectors()"><i class="fas fa-undo"></i></button>
    </div>
  </div>

  <div class="row">
    <div *ngFor="let product of productsFiltered" class="col-3">
      <app-product [product]="product" (selectProduct)="selectProduct($event)"></app-product>
    </div>
  </div>
</div>