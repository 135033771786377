<div class="navbar-dark c-blue-dark-dk-bk p-5">
  <div class="container">
    <div class="text-center text-white">
      <h1>{{title}}</h1>
      <p class="text-white">{{message}}</p>
    </div>
    <div class="row">
      <div *ngFor="let team of teams" [class]="class">
        <div class="p-4">
          <app-team (cardButtonClicked)="cardClicked(team.buttonRoute)"
            [name]="team.name" [position]="team.position"
            [src]="team.src" [cardTitle]="team.cardTitle"
            [cardText]="team.cardText" [buttonText]="team.buttonText" [paddingCSSclass]="'p-5'" [delay]="GetDelay(team.id)">
          </app-team>
        </div>
      </div>
    </div>

  </div>
</div>
