<div class="m-3">
  <div class="row">
    <div class="col text-center">
      <h2 class="pt-2">Benthin - System</h2>
      <p class="pb-3">Multi-sectional system means ultra-smooth action</p>
    </div>
  </div>
  <div class="row pb-5 text-center">
    <div class="col-12 col-md mx-auto py-2">
      <img
        class="img-fluid"
        src="./assets/images/benthin-vertical-1.jpg"
        alt="benthin vertical blind"
      />
    </div>

    <div class="col-12 col-md mx-auto py-2">
      <img
        class="img-fluid"
        src="./assets/images/benthin-rail-2.jpg"
        alt="benthin vertical headrail"
      />
    </div>

    <div class="col-12 col-md mx-auto py-2">
      <img
        class="img-fluid"
        src="./assets/images/benthin-carrier.jpg"
        alt="benthin carrier truck"
      />
    </div>

    <div class="col-12 col-md mx-auto py-2">
      <img
        class="img-fluid"
        src="./assets/images/benthin-vertical-headrail.jpg"
        alt="benthin headrail"
      />
    </div>
  </div>
</div>
