<!-- Generator: Adobe Illustrator 28.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 669 160" xml:space="preserve">
<style type="text/css">
	.st0{fill:#873000;}
	.st1{fill:none;stroke:#FDFF00;stroke-width:8;stroke-miterlimit:10;}
	.st2{fill:none;stroke:#FFFFFF;stroke-width:10;stroke-miterlimit:10;}
	.st3{font-family:'ArialMT';}
	.st4{font-size:12px;}
	.st5{stroke-width:2;stroke-miterlimit:10;stroke-dasharray:8,2}
	.st6{fill:none;stroke:#FF371F;stroke-miterlimit:10;}
</style>
<g id="revealR">
	<rect x="616" y="101" class="st0" width="53" height="53"/>
</g>
<g id="revealL">
	<rect x="0.8" y="101" class="st0" width="53" height="53"/>
</g>
<polyline id="window" class="st1" points="53,105 154,38.5 155,38 273,4 274.5,4 396.5,4 397.7,4.5 514.9,38.3 516,39 618,104 "/>
<path id="curveReveal" class="st2" d="M69.2,136.6C213.7,2.2,446.1,1.5,601.1,135.6"/>
<text transform="matrix(1 0 0 1 225.3066 129.6359)" class="st3 st4 dimAB">A-B Width</text>
<text transform="matrix(1 0 0 1 215.77 98.64)" class="st3 st4"> </text>
<g id="measureAB">
	<line class="st5 dimABs" x1="71.4" y1="135.6" x2="598.8" y2="135.6"/>
	<path class="dimAB" d="M84.4,145.2c0.3-0.5,0.2-1.1-0.3-1.4l-12.2-8.2l12.2-8.2c0.4-0.3,0.6-0.9,0.3-1.4c-0.3-0.5-0.9-0.6-1.3-0.3c0,0,0,0,0,0
		l-13.5,9.1c-0.4,0.3-0.6,0.9-0.3,1.4c0.1,0.1,0.2,0.2,0.3,0.3l13.5,9.1c0.2,0.1,0.3,0.2,0.5,0.1C83.9,145.6,84.2,145.5,84.4,145.2z
		"/>
	<path class="dimAB" d="M585.8,145.2c-0.3-0.5-0.2-1.1,0.3-1.4l12.2-8.2l-12.2-8.2c-0.4-0.3-0.6-0.9-0.3-1.4c0.3-0.5,0.9-0.6,1.3-0.3c0,0,0,0,0,0
		l13.5,9.1c0.4,0.3,0.6,0.9,0.3,1.4c-0.1,0.1-0.2,0.2-0.3,0.3l-13.5,9.1c-0.2,0.1-0.3,0.2-0.5,0.1
		C586.3,145.6,586,145.5,585.8,145.2z"/>
</g>
<g id="measureY">
	<line class="st5 dimYs" x1="334.3" y1="37.9" x2="334.3" y2="132.1"/>
	<path class="dimY" d="M324.7,51.6c0.5,0.3,1.1,0.2,1.4-0.3l8.2-12.9l8.2,12.9c0.3,0.5,0.9,0.6,1.4,0.3c0.5-0.3,0.6-0.9,0.3-1.4c0,0,0,0,0,0
		L335.1,36c-0.3-0.5-0.9-0.6-1.4-0.3c-0.1,0.1-0.2,0.2-0.3,0.3l-9.1,14.2c-0.1,0.2-0.2,0.3-0.1,0.5
		C324.2,51.1,324.4,51.4,324.7,51.6z"/>
	<path class="dimY" d="M324.7,118.5c0.5-0.3,1.1-0.2,1.4,0.3l8.2,12.9l8.2-12.9c0.3-0.5,0.9-0.6,1.4-0.3c0.5,0.3,0.6,0.9,0.3,1.4c0,0,0,0,0,0
		l-9.1,14.2c-0.3,0.5-0.9,0.6-1.4,0.3c-0.1-0.1-0.2-0.2-0.3-0.3l-9.1-14.2c-0.1-0.2-0.2-0.3-0.1-0.5
		C324.2,119,324.4,118.6,324.7,118.5z"/>
</g>
<text transform="matrix(1 0 0 1 341.027 88.9401)" class="st3 st4 dimY">Y Depth</text>
<line id="guideA" class="st6" x1="358.1" y1="35.9" x2="311.2" y2="35.9"/>
<line id="guideAB" class="st6" x1="358.1" y1="134.9" x2="311.2" y2="134.9"/>
<line id="guideLReveal" class="st6" x1="56.4" y1="124.1" x2="81.9" y2="149.2"/>
<line id="guideRReveal" class="st6" x1="612.8" y1="122.1" x2="587.5" y2="151.2"/>
<text transform="matrix(0.7776 -0.6288 0.6288 0.7776 62.8843 148.5305)" class="st3 st4">A</text>
<text transform="matrix(0.7725 0.635 -0.635 0.7725 602.7562 139.9127)" class="st3 st4">B</text>
</svg>
