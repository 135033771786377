<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 571.9 152.02">
    <defs>
      <style>
        .cls-1 {
          fill: #fdff00;
          stroke: #2e3192;
        }
  
        .cls-1, .cls-2, .cls-3, .cls-4 {
          stroke-miterlimit: 10;
        }
  
        .cls-5 {
          letter-spacing: 0em;
        }
  
        .cls-6 {
          font-family: MyriadPro-Regular, 'Myriad Pro';
          font-size: 12px;
        }
  
        .cls-7, .cls-8 {
          stroke-width: 0px;
        }
  
        .cls-2 {
          stroke: #ff371f;
        }
  
        .cls-2, .cls-3, .cls-4 {
          fill: none;
        }
  
        .cls-9 {
          letter-spacing: 0em;
        }
  
        .cls-3 {
          stroke: #fff;
          stroke-width: 10px;
        }
  
        .cls-4 {
          stroke-dasharray: 0 0 8 2;
          stroke-width: 2px;
        }
  
        .cls-8 {
          fill: #8b2d0f;
        }
  
        .cls-10 {
          letter-spacing: .01em;
        }
      </style>
    </defs>
    <g id="Reveal2">
      <rect class="cls-8" x="7.59" y="115.29" width="53" height="35.87"/>
      <path class="cls-7" d="M60.09,115.79v34.87H8.09v-34.87h52M61.09,114.79H7.09v36.87h54v-36.87h0Z"/>
    </g>
    <g id="Reveal1">
      <rect class="cls-8" x="510.36" y="115.21" width="53" height="36.31"/>
      <path class="cls-7" d="M562.86,115.71v35.31h-52v-35.31h52M563.86,114.71h-54v37.31h54v-37.31h0Z"/>
    </g>
    <polygon class="cls-1" points="536.62 114.71 544.07 114.71 544.07 .5 27.66 .5 27.66 114.37 36.44 114.37 36.44 8.37 535.85 8.5 536.62 114.71"/>
    <g id="measureBC">
        <g>
            <line class="cls-4 dimCDs" x1="525.45" y1="29.52" x2="525.73" y2="107.54"/>
            <path class="dimCD" d="M515.9,43.23c.47.3,1.08.15,1.38-.31l8.17-12.93,8.27,12.87c.3.47.92.6,1.38.3.46-.3.6-.92.3-1.38l-9.11-14.2c-.18-.29-.5-.46-.85-.46s-.66.18-.84.47l-9.01,14.26c-.1.17-.15.35-.15.54,0,.33.17.65.47.84Z"/>
            <path class="dimCD" d="M516.08,93.9c.46-.3,1.08-.16,1.38.3l8.27,12.87,8.17-12.93c.3-.47.91-.61,1.38-.31.47.3.6.92.31,1.38l-9.01,14.26c-.18.29-.5.46-.84.47-.34,0-.66-.17-.85-.46l-9.11-14.2c-.11-.17-.16-.35-.16-.54,0-.33.16-.65.46-.85Z"/>
        </g>
    </g>
    <g id="messureBC">
        <g>
            <line class="cls-4 dimBCs" x1="514.2" y1="17.92" x2="57.14" y2="18.07"/>
            <path class="dimBC" d="M500.53,8.32c-.3.46-.16,1.08.31,1.38l12.91,8.21-12.9,8.22c-.47.3-.61.92-.31,1.38.3.46.92.6,1.38.31l14.23-9.07c.29-.18.46-.5.46-.84s-.18-.66-.46-.84l-14.23-9.06c-.17-.11-.35-.16-.54-.16-.33,0-.65.16-.85.46Z"/>
            <path class="dimBC" d="M70.81,8.47c.3.46.16,1.08-.31,1.38l-12.9,8.22,12.91,8.21c.47.3.61.92.31,1.38-.3.46-.92.6-1.38.31l-14.23-9.06c-.29-.18-.46-.5-.46-.84,0-.34.18-.66.46-.84l14.23-9.07c.17-.11.35-.16.54-.16.33,0,.65.16.85.46Z"/>
        </g>
    </g>
    <line id="GuideACNoReveal" class="cls-2" x1="560.14" y1="110.2" x2="484.38" y2="109.8"/>
    <text class="cls-6 dimAB" transform="translate(72.71 81.37)">A-B</text>
    <text class="cls-6 dimBC" transform="translate(279.45 51)">B-C</text>
    <text class="cls-6 dimCD" transform="translate(480.62 81.37)">C-D</text>
    <text class="cls-6" transform="translate(563.91 112.95)"><tspan x="0" y="0">D</tspan></text>
    <text class="cls-6" transform="translate(522.59 21.46)"><tspan x="0" y="0">C</tspan></text>
    <text class="cls-6" transform="translate(0 112.95)"><tspan x="0" y="0">A</tspan></text>
    <path id="curveNoReveal" class="cls-3" d="M59.83,109.54v-57.58c0-10.41,10.32-20.57,22.05-20.57l407.11-.66c11.73,0,22.4,11.23,22.4,21.64v57.58"/>
    <g id="measureAB">
      <g>
        <line class="cls-4 dimABs" x1="46.94" y1="28.75" x2="47.23" y2="107.54"/>
        <path class="dimAB" d="M37.39,42.46c.47.3,1.08.15,1.38-.31l8.17-12.93,8.27,12.87c.3.47.92.6,1.38.3.46-.3.6-.92.3-1.38l-9.11-14.2c-.18-.29-.5-.46-.85-.46-.34,0-.66.18-.84.47l-9.01,14.26c-.1.17-.15.35-.15.54,0,.33.17.65.47.84Z"/>
        <path class="dimAB" d="M37.58,93.9c.46-.3,1.08-.16,1.38.3l8.27,12.87,8.17-12.93c.3-.47.91-.61,1.38-.31.47.3.6.92.31,1.38l-9.01,14.26c-.18.29-.5.46-.84.47-.34,0-.66-.17-.85-.46l-9.11-14.2c-.11-.17-.16-.35-.16-.54,0-.33.16-.65.46-.85Z"/>
      </g>
    </g>
    <line id="GuideACNoReveal-2" data-name="GuideACNoReveal" class="cls-2" x1="87.51" y1="110.14" x2="11.75" y2="109.75"/>
    <text class="cls-6" transform="translate(43.44 20.4)"><tspan x="0" y="0">B</tspan></text>
    <line id="guideBC" class="cls-2" x1="516.61" y1="10.05" x2="516.61" y2="56.91"/>
    <line id="GuideACNoReveal-3" data-name="GuideACNoReveal" class="cls-2" x1="106.27" y1="26.3" x2="35.7" y2="25.93"/>
    <line id="GuideACNoReveal-4" data-name="GuideACNoReveal" class="cls-2" x1="534.26" y1="26.29" x2="465.58" y2="25.93"/>
    <line id="guideBC-2" data-name="guideBC" class="cls-2" x1="54.54" y1="10" x2="54.54" y2="62.96"/>
  </svg>