<div class="content page-content">
  <div class="container">
    <app-intro></app-intro>
  </div>

  <div class="container-fluid">
    <app-feature-cards
      [title]="'Why Bend It Ltd'"
      [message]="'Here is why we are different'"
      [features]="featuresWhyUs"
    ></app-feature-cards>
  </div>

  <div class="container-fluid" id="WhoAreWe">
    <app-teams
      [title]="'Who are we?'"
      [message]="
        'A local team dedicated to offering you the very best products and service'
      "
    ></app-teams>

    <app-opening-times></app-opening-times>

    <app-banner-contact
      [title]="'Contact Bend It Ltd'"
      [message]="'We welcome trade enquiries'"
      [contactEmail]="'trade@bendit.uk'"
      [contactTel]="'01492 536674'"
      [contactAddr]="'Europa House, Greenfield Road, Colwyn Bay, LL29 8EP'"
    >
    </app-banner-contact>
  </div>

  <div class="container-fluid">
    <app-contact-form id="ContactForm"></app-contact-form>
  </div>

</div>
