import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-article-center',
  templateUrl: './article-center.component.html',
  styleUrls: ['./article-center.component.scss']
})
export class ArticleCenterComponent implements OnInit {

  @Input()
  title: string;
  showTitle: boolean;

  @Input()
  subtitle: string;
  showSubTitle: boolean;

  @Input()
  text: string;
  showText: boolean;

  @Input()
  footer: string;
  showFooter: boolean;

  constructor() {
   }

  ngOnInit(): void {
    this.showTitle = this.title !== undefined && this.title.length > 0;
    this.showSubTitle = this.subtitle !== undefined && this.subtitle.length > 0;
    this.showText = this.text !== undefined && this.text.length > 0;
    this.showFooter = this.footer !== undefined && this.footer.length > 0;
  }
}
