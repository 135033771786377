<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 699.88 238.59">
    <defs>
      <style>
        .cls-1 {
          letter-spacing: -.02em;
        }
  
        .cls-1, .cls-6, .cls-3, .cls-4, .cls-5 {
          isolation: isolate;
        }
  
        .cls-1, .cls-3, .cls-4, .cls-5 {
          font-family: ArialMT, Arial;
          font-size: 12px;
        }
  
        .cls-7 {
          stroke: #ef3f2b;
        }
  
        .cls-7, .cls-8, .cls-9 {
          stroke-miterlimit: 10;
        }
  

  
        .cls-2, .cls-10, .cls-11 {
          stroke-width: 0px;
        }
  
        .cls-4 {
          letter-spacing: -.06em;
        }
  
        .cls-5 {
          letter-spacing: .01em;
        }
  
        .cls-10 {
          fill: #fff;
        }
  
        .cls-11 {
          fill: #87331b;
        }
  
        .cls-8 {
          fill: #f5eb16;
          stroke: #2e3690;
        }
  
        .cls-9 {
         
          stroke-dasharray: 0 0 0 0 0 0 8 2;
          stroke-width: 2px;
        }
      </style>
    </defs>
    <g id="revealR">
      <rect class="cls-11" x="646.38" y="178.09" width="53" height="60"/>
      <path class="cls-2" d="M698.88,178.59v59h-52v-59h52M699.88,177.59h-54v61h54v-61h0Z"/>
    </g>
    <g id="revealL">
      <rect class="cls-11" x=".5" y="180.09" width="53" height="56"/>
      <path class="cls-2" d="M53,180.59v55H1v-55h52M54,179.59H0v57h54v-57h0Z"/>
    </g>
    <path class="cls-8" d="M51.94,183.25l-6.09-4.36,66.17-80.2,112.5-47.1,131.68-17.7c.37-.03.75.02,1.1.15l129.06,18.11,108.05,45.54,58.7,81.48-6.54,4.31-58.24-79.99-104.82-43.62-127.89-17.83c-.35-.13-.73-.18-1.11-.15l-127.86,17.48-110.23,46.07-.42.21-64.06,77.6h0Z"/>
    <line id="guideA" class="cls-7" x1="380.5" y1="41.2" x2="333.64" y2="41.2"/>
    <line id="guideAB" class="cls-7" x1="381.23" y1="60.58" x2="334.37" y2="60.58"/>
    <line id="guideLReveal" class="cls-7" x1="15.22" y1="156.01" x2="62.68" y2="192.62"/>
    <polygon class="cls-10" points="66.58 194.7 127.07 119.66 230.58 77.92 357.85 59.68 476.46 79.24 576.99 119.2 630.29 195.48 625.06 199.43 572.74 124.59 473.64 85.17 357.13 67.12 232.89 84.94 131.67 126.38 71.69 198.51 66.58 194.7"/>
    <g id="measureY">
      <g>
        <line class="cls-9 dimYs" x1="357.97" y1="63.7" x2="357.77" y2="89.53"/>
        <path class="dimY" d="M348.27,77.29c.46.3,1.08.17,1.38-.3l8.32-12.84,8.12,12.97c.29.47.91.61,1.38.32s.61-.92.32-1.38l-8.95-14.3c-.18-.29-.5-.47-.84-.47s-.66.17-.85.46l-9.18,14.16c-.11.17-.16.35-.16.54,0,.33.16.66.46.85h0Z"/>
      </g>
    </g>
    <g id="measureY-2">
      <g>
        <line class="cls-9 dimYs" x1="357.88" y1="37.84" x2="357.88" y2="9.39"/>
        <path class="dimY" d="M367.48,24.17c-.46-.3-1.08-.16-1.38.31l-8.22,12.9-8.22-12.9c-.3-.47-.92-.61-1.38-.31-.46.3-.6.92-.31,1.38l9.06,14.23c.18.29.5.46.84.46s.66-.18.84-.46l9.06-14.23c.11-.17.16-.35.16-.54,0-.33-.16-.65-.46-.85h.01Z"/>
      </g>
    </g>
    <g id="measureDG">
      <g>
        <line class="cls-9 dimDGs" x1="359.96" y1="52.34" x2="636.48" y2="188.3"/>
        <path class="dimDG" d="M367.99,66.98c.47-.29.62-.9.33-1.38l-7.95-13.07,15.21-1.68c.55-.06.95-.55.89-1.1s-.56-.94-1.11-.89l-16.77,1.85c-.34.04-.64.25-.79.55-.15.31-.13.67.04.96l8.77,14.41c.1.17.25.3.41.38.3.15.66.14.96-.04h.01Z"/>
        <path class="dimDG" d="M619.98,190.89c-.06-.55.34-1.04.89-1.1l15.21-1.68-7.95-13.07c-.29-.47-.14-1.09.33-1.38.47-.29,1.09-.13,1.38.33l8.77,14.41c.18.29.19.65.04.96-.15.31-.45.51-.79.55l-16.77,1.85c-.2.02-.39-.02-.55-.1-.3-.15-.51-.44-.55-.79v.02Z"/>
      </g>
    </g>
    <g id="measureCG">
      <g>
        <line class="cls-9 dimCGs" x1="230.5" y1="67.41" x2="636.33" y2="187.68"/>
        <path class="dimCG" d="M240.87,80.5c.42-.36.46-.99.1-1.41l-10.04-11.55,14.71-4.21c.53-.15.84-.71.69-1.24-.15-.53-.71-.84-1.24-.69l-16.22,4.65c-.33.09-.59.35-.68.68-.1.33-.02.68.2.94l11.07,12.73c.13.15.29.25.47.3.32.09.67.03.94-.2h0Z"/>
        <path class="dimCG" d="M620.5,193c-.15-.53.16-1.08.69-1.24l14.71-4.21-10.04-11.55c-.36-.42-.32-1.05.1-1.41s1.05-.31,1.41.1l11.07,12.73c.22.26.3.61.2.94s-.36.58-.68.68l-16.22,4.65c-.19.05-.38.05-.56,0-.32-.09-.58-.34-.68-.68h0Z"/>
      </g>
    </g>
    <g id="measureBG">
      <g>
        <line class="cls-9 dimBGs" x1="117.8" y1="107.01" x2="635.66" y2="188.38"/>
        <path class="dimBG" d="M129.81,118.62c.37-.41.32-1.05-.09-1.41l-11.47-10.12,14.02-6.12c.51-.22.74-.81.52-1.32s-.82-.74-1.32-.52l-15.46,6.74c-.31.14-.54.42-.59.76s.07.68.33.91l12.65,11.16c.15.13.32.21.51.24.33.05.67-.06.91-.33h-.01Z"/>
        <path class="dimBG" d="M620.66,195.74c-.22-.51.01-1.1.52-1.32l14.02-6.12-11.47-10.12c-.42-.37-.46-1-.09-1.41s1-.45,1.41-.09l12.65,11.16c.26.23.38.57.33.91s-.28.62-.59.76l-15.46,6.74c-.18.08-.37.1-.55.07-.33-.05-.62-.26-.76-.59h-.01Z"/>
      </g>
    </g>
    <g id="measureAG">
      <g>
        <line class="cls-9 dimAGs" x1="63.35" y1="190.48" x2="636.8" y2="188.75"/>
        <path class="dimAG" d="M77.04,200.04c.3-.47.15-1.08-.31-1.38l-12.93-8.18,12.88-8.26c.47-.3.6-.92.3-1.38-.3-.46-.92-.6-1.38-.3l-14.2,9.11c-.29.18-.46.5-.46.85s.18.66.47.84l14.26,9.02c.17.1.35.16.54.15.33,0,.65-.17.84-.47h-.01Z"/>
        <path class="dimAG" d="M623.16,198.39c-.3-.46-.16-1.08.3-1.38l12.88-8.26-12.93-8.18c-.47-.3-.61-.91-.31-1.38s.92-.6,1.38-.31l14.26,9.02c.29.18.46.5.47.84,0,.34-.17.66-.46.85l-14.2,9.11c-.17.11-.35.16-.54.16-.33,0-.65-.16-.85-.46h0Z"/>
      </g>
    </g>
    <g id="measureAF">
      <g>
        <line class="cls-9 dimAFs" x1="60.81" y1="187" x2="580.87" y2="110.51"/>
        <path class="dimAF" d="M75.73,194.51c.23-.5,0-1.1-.51-1.32l-13.96-6.25,11.57-10.01c.42-.36.47-.99.1-1.41-.36-.42-1-.46-1.41-.1l-12.76,11.04c-.26.22-.38.56-.34.9.05.34.27.63.58.77l15.4,6.9c.18.08.37.1.55.08.33-.05.62-.26.77-.58v-.02Z"/>
        <path class="dimAF" d="M568.74,122c-.36-.42-.31-1.05.1-1.41l11.57-10.01-13.96-6.25c-.51-.23-.73-.82-.51-1.32.23-.5.82-.73,1.32-.5l15.4,6.9c.31.14.53.43.58.77s-.08.68-.34.9l-12.76,11.04c-.15.13-.33.21-.51.23-.33.05-.67-.07-.9-.33v-.02Z"/>
      </g>
    </g>
    <g id="measureAE">
      <g>
        <line class="cls-9 dimAEs" x1="56.17" y1="188.05" x2="477.11" y2="69.22"/>
        <path class="dimAE" d="M71.93,193.57c.16-.53-.14-1.09-.67-1.25l-14.65-4.4,10.19-11.42c.37-.41.33-1.05-.08-1.41-.41-.37-1.05-.33-1.41.08l-11.23,12.59c-.23.25-.31.61-.22.94s.35.59.67.69l16.16,4.86c.19.06.38.05.56,0,.32-.09.59-.34.69-.68h0Z"/>
        <path class="dimAE" d="M466.57,82.17c-.41-.37-.45-1-.08-1.41l10.19-11.42-14.65-4.4c-.53-.16-.83-.72-.67-1.25.16-.53.72-.83,1.25-.67l16.16,4.86c.33.1.58.36.67.69.09.33.01.68-.22.94l-11.23,12.59c-.13.15-.3.25-.47.3-.32.09-.67.02-.94-.22h-.01Z"/>
      </g>
    </g>
    <g id="measureAD">
      <g>
        <line class="cls-9 dimADs" x1="55.63" y1="188" x2="355.62" y2="51.27"/>
        <path class="dimAD" d="M72.05,191.07c.08-.55-.31-1.05-.85-1.13l-15.15-2.13,8.33-12.83c.3-.46.17-1.08-.29-1.38s-1.09-.16-1.39.29l-9.19,14.15c-.19.29-.21.65-.07.96s.43.53.77.58l16.71,2.35c.2.03.39,0,.55-.08.3-.14.53-.42.58-.77h0Z"/>
        <path class="dimAD" d="M347.16,65.68c-.46-.3-.59-.92-.29-1.38l8.33-12.83-15.15-2.13c-.55-.08-.93-.58-.85-1.13s.59-.93,1.13-.85l16.71,2.35c.34.05.63.26.77.58.14.31.11.67-.07.96l-9.19,14.15c-.11.17-.26.29-.42.36-.3.14-.66.12-.96-.07h0Z"/>
      </g>
    </g>
    <g id="measureAC">
      <g>
        <line class="cls-9 dimACs" x1="58.86" y1="188.69" x2="228.63" y2="68.28"/>
        <path class="dimAC" d="M75.57,188.62c-.03-.55-.5-.98-1.05-.95l-15.28.76,5.77-14.17c.21-.51-.04-1.1-.55-1.3-.51-.21-1.1.04-1.31.55l-6.36,15.62c-.13.32-.09.68.11.96.2.28.53.44.87.42l16.85-.84c.2,0,.38-.08.53-.18.27-.19.44-.51.42-.87h0Z"/>
        <path class="dimAC" d="M223.04,84.02c-.51-.21-.76-.79-.55-1.3l5.77-14.17-15.28.76c-.55.03-1.02-.4-1.05-.95-.03-.55.4-1.02.95-1.05l16.85-.84c.34-.02.67.14.87.42s.24.64.11.96l-6.36,15.62c-.08.18-.2.33-.35.44-.27.19-.63.24-.96.11h0Z"/>
      </g>
    </g>
    <g id="measureEG">
      <g>
        <line class="cls-9 dimEGs" x1="480.16" y1="68.7" x2="637.7" y2="187.26"/>
        <path class="dimEG" d="M620.99,187.19c-.02.36.15.68.42.87.15.1.33.17.53.18l16.85.84c.34.02.67-.14.87-.42.2-.28.24-.64.11-.96l-6.36-15.62c-.21-.51-.8-.76-1.31-.55-.51.2-.76.79-.55,1.3l5.77,14.17-15.28-.76c-.55-.03-1.02.4-1.05.95h0Z"/>
        <path class="dimEG" d="M486.83,85.57c-.33.13-.69.08-.96-.11-.15-.11-.27-.26-.35-.44l-6.36-15.62c-.13-.32-.09-.68.11-.96s.53-.44.87-.42l16.85.84c.55.03.98.5.95,1.05-.03.55-.5.98-1.05.95l-15.28-.76,5.77,14.17c.21.51-.04,1.09-.55,1.3h0Z"/>
      </g>
    </g>
    <g id="measureFG">
      <g>
        <line class="cls-9 dimFGs" x1="603.15" y1="86.06" x2="666.79" y2="165.17"/>
        <path class="dimFG" d="M604.24,102.73c.55-.06.94-.56.88-1.11l-1.68-15.21,14.49,4.9c.52.18,1.09-.1,1.27-.63.18-.52-.11-1.09-.63-1.27l-15.98-5.41c-.32-.11-.68-.05-.95.17-.27.21-.4.55-.37.89l1.86,16.77c.02.2.1.37.21.52.21.26.54.41.89.37h0Z"/>
        <path class="dimFG" d="M650.74,160.54c.18-.52.75-.8,1.27-.63l14.49,4.9-1.68-15.21c-.06-.55.33-1.05.88-1.11s1.04.34,1.11.89l1.86,16.77c.04.34-.1.68-.37.89s-.62.28-.95.17l-15.98-5.41c-.19-.06-.34-.18-.46-.32-.21-.26-.28-.61-.17-.95h0Z"/>
      </g>
    </g>
    <g id="measureEF">
      <g>
        <line class="cls-9 dimEFs" x1="493.1" y1="39.02" x2="597.91" y2="82.64"/>
        <path class="dimEF" d="M502.04,53.14c.45-.32.56-.94.25-1.39l-8.76-12.55,15.07-2.63c.55-.09.91-.61.82-1.16-.1-.54-.62-.91-1.16-.81l-16.62,2.9c-.34.06-.62.29-.75.6s-.09.68.1.96l9.65,13.83c.11.16.27.28.44.35.3.13.67.1.96-.1Z"/>
        <path class="dimEF" d="M581.6,86.25c-.1-.54.27-1.06.82-1.16l15.07-2.63-8.76-12.55c-.32-.45-.21-1.08.25-1.39.45-.32,1.08-.2,1.39.25l9.65,13.83c.19.28.23.64.1.96s-.42.54-.75.6l-16.62,2.9c-.2.03-.39,0-.56-.06-.3-.13-.54-.4-.6-.75h0Z"/>
      </g>
    </g>
    <g id="measureDE">
      <g>
        <line class="cls-9 dimDEs" x1="360.18" y1="18.14" x2="488.51" y2="37.73"/>
        <path class="dimDE" d="M372.25,29.69c.36-.41.32-1.05-.1-1.41l-11.52-10.07,14-6.18c.51-.22.74-.81.51-1.32-.22-.5-.82-.73-1.32-.51l-15.43,6.81c-.31.14-.53.43-.58.76-.05.34.07.68.33.9l12.7,11.11c.15.13.33.21.51.24.33.05.67-.06.91-.33h-.01Z"/>
        <path class="dimDE" d="M473.55,45.16c-.22-.5,0-1.1.51-1.32l14-6.18-11.52-10.07c-.42-.36-.46-1-.1-1.41s1-.45,1.41-.09l12.7,11.11c.26.22.38.57.33.9-.05.34-.27.63-.58.76l-15.43,6.81c-.18.08-.37.1-.55.07-.33-.05-.62-.26-.77-.59h0Z"/>
      </g>
    </g>
    <g id="measureCD">
      <g>
        <line class="cls-9 dimCDs" x1="222.88" y1="36.25" x2="357.17" y2="17.87"/>
        <path class="dimCD" d="M237.72,43.9c.23-.5,0-1.1-.49-1.33l-13.9-6.39,11.67-9.89c.42-.36.48-.99.12-1.41s-.99-.47-1.41-.12l-12.87,10.91c-.26.22-.39.56-.34.9s.26.63.57.77l15.33,7.05c.18.08.37.11.55.08.33-.04.63-.25.78-.57h-.01Z"/>
        <path class="dimCD" d="M344.93,29.23c-.36-.42-.3-1.05.12-1.41l11.67-9.89-13.9-6.39c-.5-.23-.72-.83-.49-1.33s.83-.72,1.33-.49l15.33,7.05c.31.14.53.43.57.77.05.34-.08.68-.34.9l-12.87,10.91c-.15.13-.33.2-.51.23-.33.04-.67-.07-.9-.34h-.01Z"/>
      </g>
    </g>
    <g id="measureBC">
      <g>
        <line class="cls-9 dimBCs" x1="105.62" y1="85.31" x2="218.28" y2="37.51"/>
        <path class="dimBC" d="M121.95,88.8c.09-.54-.28-1.06-.82-1.15l-15.09-2.53,8.67-12.61c.31-.46.2-1.08-.26-1.39-.45-.31-1.08-.19-1.39.26l-9.56,13.9c-.19.28-.23.64-.1.96s.42.54.76.6l16.64,2.79c.2.03.39,0,.56-.07.3-.13.54-.41.6-.76h0Z"/>
        <path class="dimBC" d="M209.44,51.69c-.45-.31-.57-.94-.26-1.39l8.67-12.61-15.09-2.53c-.55-.09-.92-.61-.82-1.15.09-.54.61-.91,1.15-.82l16.64,2.79c.34.06.62.28.76.6.13.31.1.68-.1.96l-9.56,13.9c-.11.16-.26.28-.43.35-.3.13-.67.1-.96-.1h0Z"/>
      </g>
    </g>
    <g id="measureAB">
      <g>
        <line class="cls-9 dimABs" x1="32.72" y1="164.46" x2="100.55" y2="88.87"/>
        <path class="dimAB" d="M49,160.69c-.15-.53-.7-.84-1.23-.69l-14.74,4.12,2.5-15.09c.09-.55-.28-1.06-.82-1.15s-1.06.28-1.15.82l-2.76,16.64c-.06.34.06.68.32.91s.61.31.94.22l16.25-4.54c.19-.05.35-.16.48-.3.22-.25.31-.6.22-.94h0Z"/>
        <path class="dimAB" d="M98.56,105.45c-.54-.09-.91-.61-.82-1.15l2.5-15.09-14.74,4.12c-.53.15-1.09-.16-1.23-.69-.15-.53.17-1.09.7-1.23l16.25-4.54c.33-.09.68,0,.94.22s.37.57.32.91l-2.76,16.64c-.03.2-.12.37-.24.5-.22.25-.56.38-.91.32h0Z"/>
      </g>
    </g>
    <line id="guideLReveal-2" class="cls-7" x1="493.89" y1="32.15" x2="478.42" y2="73.34"/>
    <line id="guideLReveal-3" class="cls-7" x1="358.2" y1="15.44" x2="357.8" y2="64.97"/>
    <line id="guideLReveal-4" class="cls-7" x1="219.59" y1="28.9" x2="229.55" y2="72.7"/>
    <line id="guideLReveal-5" class="cls-7" x1="96.81" y1="76.88" x2="123.74" y2="115.13"/>
    <text class="cls-4" transform="translate(8.86 156.26) rotate(-53.01)"><tspan x="0" y="0">A</tspan></text>
    <text class="cls-3" transform="translate(94.59 73.58) rotate(-27.35)"><tspan x="0" y="0">B</tspan></text>
    <text class="cls-3" transform="translate(214.13 27.1) rotate(-12.8)"><tspan x="0" y="0">C</tspan></text>
    <text class="cls-3" transform="translate(353.96 10.3)"><tspan x="0" y="0">D</tspan></text>
    <text class="cls-3" transform="translate(493.52 27.83) rotate(21.66)"><tspan x="0" y="0">E</tspan></text>
    <text class="cls-3" transform="translate(608 75.99) rotate(32)"><tspan x="0" y="0">F</tspan></text>
    <text class="cls-3" transform="translate(677.72 154.64) rotate(53.01)"><tspan x="0" y="0">G</tspan></text>
    <text class="cls-4 dimAB" transform="translate(60.08 126.06) rotate(-48.1)"><tspan x="0" y="0">A-B</tspan></text>
    <text class="cls-3 dimBC" transform="translate(153.81 58.71) rotate(-22.21)"><tspan x="0" y="0">B-C</tspan></text>
    <text class="cls-3 dimCD" transform="translate(281.31 21.75) rotate(-7.79)"><tspan x="0" y="0">C-D</tspan></text>
    <text class="cls-5 dimDE" transform="translate(415.26 20.91) rotate(8.68)"><tspan x="0" y="0">D-E</tspan></text>
    <text class="cls-3 dimDF" transform="translate(545.44 54.83) rotate(22.59)"><tspan x="0" y="0">E-F</tspan></text>
    <text class="cls-3 dimFG" transform="translate(634.27 115.45) rotate(51.19)"><tspan x="0" y="0">F-G</tspan></text>
    <text class="cls-4 dimAC" transform="translate(167.55 107.81) rotate(-35.35)"><tspan x="0" y="0">A-C</tspan></text>
    <text class="cls-4 dimAD" transform="translate(209.15 111.35) rotate(-24.84)"><tspan x="0" y="0">A-D</tspan></text>
    <text class="cls-4 dimAE" transform="translate(281.68 119.6) rotate(-15.76)"><tspan x="0" y="0">A-E</tspan></text>
    <text class="cls-4 dimAF" transform="translate(239.26 157.29) rotate(-8.59)"><tspan x="0" y="0">A-F</tspan></text>
    <text class="cls-4 dimAG" transform="translate(322.95 184.34)"><tspan x="0" y="0">A-G</tspan></text>
    <text class="cls-3 dimBG" transform="translate(305.89 133.46) rotate(8.27)"><tspan x="0" y="0">B-G</tspan></text>
    <text class="cls-3 dimCG" transform="translate(397.23 113.68) rotate(16.02)"><tspan x="0" y="0">C-G</tspan></text>
    <text class="cls-5 dimDG" transform="translate(473.21 102.39) rotate(25.86)"><tspan x="0" y="0">D-G</tspan></text>
    <text class="cls-3 dimEG" transform="translate(522.54 95.76) rotate(37.44)"><tspan x="0" y="0">E-G</tspan></text>
    <text class="cls-1 dimY" transform="translate(354.33 98.03)"><tspan x="0" y="0">Y</tspan></text>
      
      <line id="guideLReveal-6" class="cls-7" x1="604.38" y1="79.66" x2="579.76" y2="114.62"/>
      <line id="guideLReveal-7" class="cls-7" x1="675.15" y1="161.69" x2="636.78" y2="190.6"/>
    </svg>