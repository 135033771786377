<!-- <img src="../images/cws-logo.svg" width="200" height="200"> -->

<svg  viewBox="0 0 200 170"  fill="#eb562b">
  <rect id="monitor" width="200" height="140" rx="5" ry="5" />
  <g id="stand">
    <line id="stndUp" x1="100" x2="100" y1="140" y2="170" stroke-width="8" stroke="#eb562b" />
    <line id="stnBase" x1="50" x2="150" y1="166" y2="166" stroke-linecap="round" stroke-width="8" stroke="#eb562b" />
  </g>
    <g id="brackets">
      <text class="brackets" id="bracketLeft" font-size="72" x="60" y="90" fill="black">&#123;</text>
      <text id="i" class="brackets" font-size="72" x="92" y="95" fill="black">i</text>
      <text class="brackets" id="bracketRight" font-size="72" x="114" y="90" fill="black">&#125;</text>
    </g>
</svg>
