<div class="backgroundDark">

  <div class="container pt-3 elegant-color text-white">
    <div class="row justify-content-center">
      <div class="media-container-column col-lg-8 text-center">
        <div class="">
          <h3 class="align-center mbr-light display-5">Register now and be the first to know </h3>
        </div>
      </div>
    </div>
    <form [formGroup]="userEmails" (ngSubmit)="SubmitForm()">
      <div class="row justify-content-center">

        <div class="media-container-column col-lg-8">
          <div class="input-group mb-3">
            <input type="email" class="form-control" mdbInput name="email" id="formEmail" formControlName="email"
              placeholder="Email">
            <div class="input-group-append">
              <button [disabled]="!userEmails.valid" class="input-group-btn" type="submit"
                class="btn text-light stylish-color-dark btn-form">Sign Me Up!</button>
            </div>
          </div>
        </div>

      </div>
    </form>
  </div>
</div>

<app-modal-popup [showPopup]="contactSent" [title]="'Subscribed'"
  [descriptions]="[{text : 'Thank you - We will be in touch shortly'}]" [btnCancelText]="'Cancel'"
  (cancelClicked)="cancelClicked()"></app-modal-popup>
<app-modal-popup [showPopup]="contactFailed" [title]="'Error Subscribing'"
  [descriptions]="[{text : 'Please try again, if the problem persists please send your enquiry directly to subscribe@vaccinescience.co.uk'}]"
  [btnCancelText]="'Cancel'" (cancelClicked)="cancelError()"></app-modal-popup>