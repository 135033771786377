import { Component, OnDestroy, OnInit } from '@angular/core';
import { SubscriptionMessage } from '@aws-amplify/datastore';
import { Subscription } from 'rxjs';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Bendit - Curves';
  IsRegistering = false;

  sub: Subscription;
  constructor(private auth: AuthService) {
  }

  ngOnInit(): void {
    this.sub = this.auth.isRegistering$.subscribe( (state)=>{
     this.IsRegistering = state;
    })

  }
  
  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
