<div
  class="container-fluid"
  [ngClass]="size == 'wide' ? 'py-5' : ''"
  [class.dark]="theme == 'dark'"
  [class.light]="theme == 'light'"
>
  <div class="mb-3 p-2 p-md-5">
    <ng-content id="content"></ng-content>
  </div>
</div>
