<div class="row mt-3 pb-0 mb-0">
    <div class="col-8">
        <h3 class="text-center">Choose which customer you would like to impersonate</h3>
    </div>
    <div class="col-3">
        <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>Search</mat-label>
            <input (keyup)="SetFilter()" matInput placeholder="Search" [(ngModel)]="search" />
        </mat-form-field>
    </div>

    <div class="col-1">
        <i (click)="RefreshData()" matTooltip="Refresh the data" class="pt-3 fa-lg fas fa-sync-alt text-success"></i>
    </div>
</div>

<table class="table table-striped w-100 cws-table" mat-table mat-sort [dataSource]="mcr">

    <ng-container matColumnDef="companyName">
        <th mat-sort-header *matHeaderCellDef>Company Name.</th>
        <td class="align-middle" mat-cell *matCellDef="let element">
            {{ element.companyName }}
        </td>
    </ng-container>

    <ng-container matColumnDef="firstName">
        <th mat-sort-header *matHeaderCellDef>First Name</th>
        <td class="align-middle" mat-cell *matCellDef="let element">
            {{ element.firstName }}
        </td>
    </ng-container>

    <ng-container matColumnDef="lastName">
        <th mat-sort-header *matHeaderCellDef>Last Name</th>
        <td class="align-middle" mat-cell *matCellDef="let element">
            {{ element.lastName }}
        </td>
    </ng-container>

    <ng-container matColumnDef="email">
        <th mat-sort-header *matHeaderCellDef>Email</th>
        <td class="align-middle" mat-cell *matCellDef="let element">
            {{ element.email }}
        </td>
    </ng-container>

    <ng-container matColumnDef="accountNumber">
        <th mat-sort-header *matHeaderCellDef>Account No.</th>
        <td class="align-middle" mat-cell *matCellDef="let element">
            {{ element.accountNumber }}
        </td>
    </ng-container>

    <ng-container matColumnDef="select">
        <th *matHeaderCellDef>
            select
        </th>
        <td class="align-middle" mat-cell *matCellDef="let element">
            <button type="button" matTooltip="Select this customer" (click)="SelectCustomer(element)"
                class="cursor btn-sm btn-primary position-relative">
                select
            </button>
        </td>
    </ng-container>

    <ng-container matColumnDef="orders">
        <th *matHeaderCellDef>
            select
        </th>
        <td class="align-middle" mat-cell *matCellDef="let element">
            <button type="button" matTooltip="Select this customer and go straight to their orders"
                (click)="SelectCustomerOrders(element)" class="cursor btn-sm btn-success position-relative">
                orders
            </button>
        </td>
    </ng-container>

    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
</table>

<div class="row">
    <div class="col-6">
        <mat-paginator #paginator [pageSize]="20" [pageSizeOptions]="[10, 20, 50, 100, 999]" showFirstLastButtons>
        </mat-paginator>
    </div>
</div>
