import { Component, OnInit } from "@angular/core";
import { IFeature } from "src/app/models/IFeature";
import { DataService } from "src/app/services/data.service";

@Component({
  selector: "app-tracks",
  templateUrl: "./tracks.component.html",
  styleUrls: ["./tracks.component.scss"],
})
export class TracksComponent implements OnInit {
  featuresTypes: IFeature[] = [];
  constructor(private dataservice: DataService) {
    dataservice.GetFeatures().subscribe(
      (data) => {
        this.featuresTypes = data.filter((w) => w.set === 4);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  ngOnInit(): void {}
}
