import { Component, OnInit, Input } from '@angular/core';
import { btnTypeEnum } from 'src/app/enums/enums';

@Component({
  selector: 'app-banner-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  constructor() { }

  @Input()
  title: string;

  @Input()
  message: string;

  @Input() contactEmail: string;
  @Input() contactTel: string;
  @Input() contactAddr: string;

  ngOnInit(): void {
  }
}
