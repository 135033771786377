<section
  class="wow c-blue-dark-dk-bk fadeInUp"
  data-wow-delay="0.2s"
  id="footer"
>
  <div class="container-fluid c-blue-dark-dk-bk text-white">
    <div class="container text-white">
      <div class="row">
        <div class="col-sm-6 col-md-3 col-lg-3">
          <div class="media-wrap">
            <div class="m-4">
              <img
                class="cws-logo"
                src="/assets/images/BendIt_Logo_Retro_rev.svg"
                alt="bend it logo"
              />
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-md-3 col-lg-3">
          <h4 class="pt-2">Address</h4>
          <p class="text-white">
            Bendit Ltd<br />Europa House<br />Greenfield Road<br />Colwyn Bay<br />Conwy<br />LL29
            8EP
          </p>
        </div>
        <div class="col-sm-12 col-md-3 col-lg-3">
          <h4 class="pt-2">Contacts</h4>
          <p>
            <i class="fas envelope text-white"></i>&nbsp;&nbsp;<a
              href="mailto:info@bendit.uk"
              >info@bendit.uk</a
            ><br />
          </p>
          <p>
            <i class="fas phone text-white"></i>&nbsp;&nbsp;<a
              href="tel:07736610980"
              >01492 536674</a
            >
          </p>
          <br />
          <h4 class="pt-2">Links</h4>
          <p class="mbr-text">
            <i class="fab facebook text-white"></i>&nbsp;&nbsp;<a
              href="https://www.facebook.com/CustomWebSolutionsLtd"
              >facebook</a
            ><br />
          </p>
        </div>

        <div class="col-sm-12 col-md-3 col-lg-3">
          <h4 class="pt-2">Policies</h4>
          <p>
            <i class="fas ban text-white"></i>&nbsp;&nbsp;
            <a [routerLink]="'privacy'">Privacy</a><br />
          </p>

          <p>
            <i class="fas file-signature text-white"></i>&nbsp;&nbsp;
            <a [routerLink]="'terms'">Terms & Conditions</a><br />
          </p>
        </div>
      </div>
      <div class="footer-lower">
        <div class="media-container-row">
          <div class="col-sm-12">
            <hr />
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6 copyright">
            <p class="mbr-text text-white ml-3">
              © Copyright {{ currentYear }} Bend It Ltd - All Rights Reserved
            </p>
          </div>
          <div class="col-sm-6">
            <p class="text-white float-right mr-3">
              Website created by
              <a href="https://customwebsolutions.ltd" target="_blank"
                >Custom Web Solutions Ltd</a
              >
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
