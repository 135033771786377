<div class="fluid-container text-center p-3">
  <div class="p-4 elegant-color">
    <h1 class="c-blue">Frequently Asked Questions</h1>
    <label class="p-2 text-white">Search our FAQ's:</label>
    <input type="text" [(ngModel)]="search" >
  </div>
<div>

</div>
  <div class="faq-category" *ngFor="let category of faqs">
    <div class="row">
      <div class="col-2">
        <div class="text-center c-blue">
          <h3>{{category.category}}</h3>
          <div class="m-2">
            <i [class]="category.icon"></i>
          </div>
        </div>
      </div>
      <div class="col-10">

        <div *ngFor="let faq of category.faqItems | FilterSearchFieldsPipe:search:['question','answer','additional']">
          <div class="faq-item">
           <app-faq-item [faqItem]="faq"></app-faq-item>
          </div>
        </div>

      </div>
    </div>

  </div>

</div>
