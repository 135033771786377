<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 678.03 154.22">
  <defs>
    <style>
      .cls-1 {
        stroke: #fff;
        stroke-width: 7px;
      }

      .cls-1, .cls-2, .cls-3 {
        fill: none;
      }

      .cls-1, .cls-2, .cls-3, .cls-4 {
        stroke-miterlimit: 10;
      }

      .cls-5 {
        letter-spacing: 0em;
      }

      .cls-2 {
        stroke: #ef3f2b;
      }

      .cls-3 {

        stroke-dasharray: 0 0 8 2;
        stroke-width: 2px;
      }

      .cls-6 {
        fill: #010101;
      }

      .cls-6, .cls-8 {
        stroke-width: 0px;
      }

      .cls-7 {
        font-family: MyriadPro-Regular, 'Myriad Pro';
        font-size: 12px;
      }

      .cls-9 {
        letter-spacing: 0em;
      }

      .cls-10 {
        letter-spacing: .01em;
      }

      .cls-8 {
        fill: #87331b;
      }

      .cls-4 {
        fill: #f5eb16;
        stroke: #2e3690;
      }

      .cls-11 {
        letter-spacing: .01em;
      }

      .cls-12 {
        letter-spacing: 0em;
      }
    </style>
  </defs>
  <polygon class="cls-4" points="89.7 121.06 85.3 114.38 189.55 36.71 191.06 35.97 191.39 35.88 489.84 35.72 606.65 115.35 602.35 122.09 488.8 44 488.16 43.72 193.94 43.47 193.52 43.67 89.7 121.06"/>
  <line id="guideLReveal" class="cls-2" x1="88" y1="95.72" x2="89" y2="136.72"/>
  <g id="revealL">
    <rect class="cls-8" x="602" y="109.72" width="72" height="12"/>
    <path class="cls-6" d="M673.5,110.22v11h-71v-11h71M674.5,109.22h-73v13h73v-13h0Z"/>
  </g>
  <path class="cls-1" d="M15.5,150.72h72.42c9.79,0,19.32-3.16,27.18-9l79.44-59.1c7.77-5.78,17.19-8.9,26.87-8.9h243.24c8.71,0,17.23,2.53,24.53,7.29l95.53,62.26c7.36,4.86,15.99,7.45,24.81,7.45h63.98"/>
  <line id="guideLReveal-2" data-name="guideLReveal" class="cls-2" x1="183" y1="20.72" x2="208" y2="70.72"/>
  <line id="guideLReveal-3" data-name="guideLReveal" class="cls-2" x1="494.11" y1="21.18" x2="480" y2="70.72"/>
  <line id="guideRReveal" class="cls-2" x1="602" y1="94.72" x2="602" y2="138.72"/>
  <g id="revealL-2" data-name="revealL">
    <rect class="cls-8" x="16" y="107.72" width="72" height="12"/>
    <path class="cls-6" d="M87.5,108.22v11H16.5v-11h71M88.5,107.22H15.5v13h73v-13h0Z"/>
  </g>
  <line id="guideLReveal-4" data-name="guideLReveal" class="cls-2" x1="15" y1="94.72" x2="15" y2="142.22"/>
  <line id="guideRReveal-2" data-name="guideRReveal" class="cls-2" x1="675" y1="95.72" x2="674.8" y2="144.84"/>
  <g id="measureE-F">
    <g>
      <line class="cls-3 dimEFs" x1="604.9" y1="136.19" x2="672.1" y2="135.36"/>
      <path class="dimEF" d="M618.69,145.62c.29-.47.14-1.09-.33-1.38l-13-8.06,12.8-8.38c.46-.3.59-.92.29-1.38-.3-.46-.93-.59-1.39-.29l-14.12,9.24c-.28.19-.46.51-.45.85s.18.66.47.84l14.34,8.89c.17.1.36.15.54.15.33,0,.65-.17.84-.47Z"/>
      <path class="dimEF" d="M658.55,145.13c-.3-.46-.17-1.08.29-1.38l12.8-8.38-13-8.06c-.47-.29-.62-.91-.33-1.38.29-.47.91-.61,1.38-.32l14.34,8.89c.29.18.47.5.47.84s-.17.66-.45.85l-14.12,9.24c-.17.11-.35.16-.54.16-.33,0-.66-.16-.85-.45Z"/>
    </g>
  </g>
  <g id="measureC-E">
    <g>
      <line class="cls-3 dimCEs" x1="599.14" y1="134.74" x2="202.44" y2="54.73"/>
      <path class="dimCE" d="M587.64,122.63c-.38.4-.37,1.03.03,1.41l11.02,10.61-14.27,5.51c-.52.2-.77.78-.58,1.29.2.51.78.77,1.29.57l15.74-6.07c.32-.12.55-.4.62-.74s-.04-.68-.29-.92l-12.16-11.7c-.14-.14-.32-.22-.5-.26-.32-.07-.67.03-.92.29Z"/>
      <path class="dimCE" d="M217.73,48.02c.2.51-.06,1.09-.58,1.29l-14.27,5.51,11.02,10.61c.4.38.41,1.02.03,1.41-.38.4-1.02.41-1.42.03l-12.16-11.7c-.25-.24-.35-.58-.29-.92s.3-.61.62-.74l15.74-6.07c.19-.07.38-.08.56-.05.32.07.61.29.74.62Z"/>
    </g>
  </g>
  <g id="measureB-E">
    <g>
      <line class="cls-3 dimBEs" x1="90.9" y1="134.22" x2="599.1" y2="133.23"/>
      <path class="dimBE" d="M104.59,143.79c.3-.47.16-1.08-.31-1.38l-12.92-8.19,12.89-8.24c.47-.3.6-.92.31-1.38-.3-.46-.92-.6-1.38-.3l-14.21,9.09c-.29.18-.46.5-.46.84s.18.66.46.84l14.25,9.03c.17.11.35.16.54.16.33,0,.65-.17.84-.46Z"/>
      <path class="dimBE" d="M585.44,142.85c-.3-.46-.16-1.08.31-1.38l12.89-8.24-12.92-8.19c-.47-.3-.61-.91-.31-1.38.3-.47.92-.6,1.38-.31l14.25,9.03c.29.18.46.5.46.84s-.17.66-.46.84l-14.21,9.09c-.17.11-.35.16-.54.16-.33,0-.65-.16-.85-.46Z"/>
    </g>
  </g>
  <g id="measureB-D">
    <g>
      <line class="cls-3 dimBDs" x1="90.86" y1="133.74" x2="482.14" y2="54.7"/>
      <path class="dimBD" d="M106.16,140.45c.2-.51-.06-1.09-.58-1.29l-14.28-5.5,11.02-10.61c.4-.38.41-1.02.03-1.41-.38-.4-1.02-.41-1.42-.03l-12.15,11.7c-.24.24-.35.58-.29.92s.3.61.62.74l15.74,6.07c.19.07.38.08.56.05.32-.07.61-.29.74-.62Z"/>
      <path class="dimBD" d="M470.64,66.81c-.38-.4-.37-1.03.03-1.41l11.02-10.61-14.28-5.5c-.52-.2-.77-.78-.58-1.29.2-.51.78-.77,1.29-.57l15.74,6.07c.32.12.55.4.62.74s-.04.68-.29.92l-12.15,11.7c-.14.14-.32.22-.5.26-.32.07-.67-.03-.92-.29Z"/>
    </g>
  </g>
  <g id="measureD-E">
    <g>
      <line class="cls-3 dimDEs" x1="600.03" y1="135.34" x2="486.47" y2="55.6"/>
      <path class="dimDE" d="M594.36,119.63c-.51.21-.75.8-.54,1.31l5.84,14.14-15.28-.69c-.55-.03-1.02.4-1.05.95-.02.55.41,1.02.96,1.05l16.85.76c.34.01.67-.14.86-.42s.24-.64.11-.96l-6.44-15.59c-.08-.18-.2-.33-.35-.44-.27-.19-.63-.24-.96-.11Z"/>
      <path class="dimDE" d="M503.17,55.6c-.02.55-.49.98-1.05.95l-15.28-.69,5.84,14.14c.21.51-.03,1.1-.54,1.31-.51.21-1.1-.04-1.31-.54l-6.44-15.59c-.13-.32-.09-.68.11-.96s.52-.44.86-.42l16.85.76c.2.01.38.08.53.18.27.19.44.51.43.86Z"/>
    </g>
  </g>
  <g id="measureC-D">
    <g>
      <line class="cls-3 dimCDs" x1="203.9" y1="55.21" x2="482.1" y2="54.23"/>
      <path class="dimCD" d="M217.61,64.76c.3-.47.15-1.08-.31-1.38l-12.93-8.17,12.88-8.26c.47-.3.6-.92.3-1.38-.3-.46-.92-.6-1.38-.3l-14.2,9.11c-.29.18-.46.5-.46.85,0,.34.18.66.47.84l14.26,9.01c.17.1.35.15.54.15.33,0,.65-.17.84-.47Z"/>
      <path class="dimCD" d="M468.46,63.88c-.3-.46-.16-1.08.3-1.38l12.88-8.26-12.93-8.17c-.47-.3-.61-.91-.31-1.38.3-.47.92-.6,1.38-.31l14.26,9.01c.29.18.46.5.47.84s-.17.66-.46.85l-14.2,9.11c-.17.11-.35.16-.54.16-.33,0-.65-.16-.85-.46Z"/>
    </g>
  </g>
  <g id="measureB-C">
    <g>
      <line class="cls-3 dimBCs" x1="91.44" y1="133.8" x2="198.56" y2="55.64"/>
      <path class="dimBC" d="M108.14,133.5c-.03-.55-.51-.97-1.06-.93l-15.27.97,5.58-14.25c.2-.51-.05-1.1-.56-1.3-.51-.2-1.1.06-1.3.57l-6.15,15.71c-.12.32-.08.68.12.95s.53.43.87.41l16.84-1.07c.2-.01.38-.08.53-.19.27-.19.43-.52.41-.87Z"/>
      <path class="dimBC" d="M193.17,71.45c-.51-.2-.77-.78-.56-1.3l5.58-14.25-15.27.97c-.55.04-1.03-.38-1.06-.93-.03-.55.39-1.03.94-1.06l16.84-1.07c.34-.02.67.13.87.41s.25.64.12.95l-6.15,15.71c-.07.18-.19.33-.34.44-.27.19-.63.25-.96.12Z"/>
    </g>
  </g>
  <g id="measureA-B">
    <g>
      <line class="cls-3 dimABs" x1="87.1" y1="134.22" x2="17.9" y2="134.22"/>
      <path class="dimAB" d="M73.43,124.62c-.3.46-.16,1.08.31,1.38l12.9,8.22-12.9,8.22c-.47.3-.61.92-.31,1.38.3.46.92.6,1.38.31l14.23-9.06c.29-.18.46-.5.46-.84s-.18-.66-.46-.84l-14.23-9.06c-.17-.11-.35-.16-.54-.16-.33,0-.65.16-.85.46Z"/>
      <path class="dimAB" d="M31.57,124.62c.3.46.16,1.08-.31,1.38l-12.9,8.22,12.9,8.22c.47.3.61.92.31,1.38-.3.46-.92.6-1.38.31l-14.23-9.06c-.29-.18-.46-.5-.46-.84s.18-.66.46-.84l14.23-9.06c.17-.11.35-.16.54-.16.33,0,.65.16.85.46Z"/>
    </g>
  </g>
  <text class="cls-7 dimAB" transform="translate(43.54 144.86)">A-B</text>
  <text class="cls-7 dimBC" transform="translate(147.83 107.17) rotate(-36.12)">B-C</text>
  <text class="cls-7 dimCD" transform="translate(297.09 65.75)">C-D</text>
  <text class="cls-7 dimDE" transform="translate(524.66 94.98) rotate(35.07)">D-E</text>
  <text class="cls-7 dimEF" transform="translate(631.09 145.75)">E-F</text>
  <text class="cls-7 dimBD" transform="translate(247.65 99.29) rotate(-11.42)">B-D</text>
  <text class="cls-7 dimBE" transform="translate(337.36 146.24)">B-E</text>
  <text class="cls-7 dimCE" transform="translate(418.23 95.1) rotate(11.4)">C-E</text>
  <text class="cls-7" transform="translate(11.09 90.75)">A</text>
  <text class="cls-7" transform="translate(84.09 93.75)">B</text>
  <text class="cls-7" transform="translate(178.02 20.98) rotate(-26.56)">C</text>
  <text class="cls-7" transform="translate(490.45 19.01) rotate(11.4)">D</text>
  <text class="cls-7" transform="translate(598.09 93.75)">E</text>
  <text class="cls-7" transform="translate(672.19 93.75)">F</text>
  <text class="cls-7 dimY" transform="translate(341 10.04)">Y</text>
  <line id="guideY" class="cls-2" x1="367.69" y1="44.87" x2="320.83" y2="44.87"/>
  <line id="guideY" class="cls-2" x1="365.43" y1="69.33" x2="318.57" y2="69.33"/>
  <g id="measureY">
    <g>
      <line class="dimYs" x1="344.46" y1="72.34" x2="344.26" y2="98.18"/>
      <path class="dimY" d="M334.76,85.94c.46.3,1.08.17,1.38-.3l8.32-12.84,8.12,12.97c.29.47.91.61,1.38.32.47-.29.61-.92.32-1.38l-8.95-14.3c-.18-.29-.5-.47-.84-.47-.34,0-.66.17-.85.46l-9.18,14.16c-.11.17-.16.35-.16.54,0,.33.16.66.46.85Z"/>
    </g>
  </g>
  <g id="measureY-2" data-name="measureY">
    <g>
      <line class="cls-3 dimYs" x1="344.26" y1="41.43" x2="344.26" y2="12.97"/>
      <path class="dimY" d="M353.86,27.76c-.46-.3-1.08-.16-1.38.31l-8.22,12.9-8.22-12.9c-.3-.47-.92-.61-1.38-.31-.46.3-.6.92-.31,1.38l9.06,14.23c.18.29.5.46.84.46s.66-.18.84-.46l9.06-14.23c.11-.17.16-.35.16-.54,0-.33-.16-.65-.46-.85Z"/>
    </g>
  </g>
</svg>