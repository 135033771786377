<div class="container page-content pt-5">
  <div class="row">
    <div class="col">
      <amplify-authenticator [signUpAttributes]="[
      'address']"
       [hideSignUp]="hideSignUp">
        <ng-template amplifySlot="sign-in-header">
          <h3 class="amplify-heading"
          style="padding: var(--amplify-space-xl) 0 0 var(--amplify-space-xl)">
          <p class="text-center">Please login to your retail account</p>
          </h3>
        </ng-template>
      </amplify-authenticator>
    </div>
  </div>
</div>

