import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { APIService, Customer, CustomerStatus, ModelCustomerFilterInput } from 'src/app/services/API.service';
import { API_CWSService } from 'src/app/services/API_CWS.service';
import { AuthService } from 'src/app/services/auth.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-change-customer',
  templateUrl: './change-customer.component.html',
  styleUrls: ['./change-customer.component.scss']
})
export class ChangeCustomerComponent implements OnInit {

  customers: Customer[] = [];
  customer: Customer;

  nextToken: string = undefined;
  loading: boolean = false;

  constructor(private authService: AuthService, private cwsApi: API_CWSService, 
    private toastrService: ToastrService, private storage: StorageService, 
    private router: Router) { }

  ngOnInit(): void {
    this.GetCustomers();
  }
  
  RefreshData() {
    this.customers = [];
    this.GetData();
  }

  GetCustomers() {
    let customers = this.storage.CacheGet("Customers");
    if (customers) {
             this.customers = customers as Customer[];
      } else {
        this.GetData();
      }
  }

  GetData() {
    this.loading = true;
    const filter: ModelCustomerFilterInput = { customerStatus: { eq: CustomerStatus.confirmed } };

        this.cwsApi.CustomersByWebsiteCWS(this.authService.website.id, undefined, filter, undefined, this.nextToken).then(
          data => {
            // this.website = data as undefined | Website;
            this.customers = [...this.customers, ...data.items as undefined | Customer[]];
            if (data.nextToken) {
              this.nextToken = data.nextToken;
              this.GetData();
            } else {
              this.nextToken = undefined;
              this.loading = false;

              this.customers = this.customers.sort((a, b) => {
                if (a.companyName === null) return 1;
                if (b.companyName === null) return -1;
                return a.companyName.localeCompare(b.companyName);
              });
              this.storage.CacheSet("Customers", this.customers);
            }
            // console.log("Items", data);
            //this.FilterItems();
          },
          err => {
            console.log("Error", err);
            this.loading = false;
      //     this.messageService.NotifyMessage("Getting Websites", "Error retreiving " + this.itemType + " " + err, MessagePriorityEnum.error, 8000);
          }
        );
  }

  SelectCustomer(customer: Customer) {
    this.customer = customer;
    this.authService.customer = customer;
    this.authService.ChangeCustomer(customer);

    this.toastrService.success('Customer changed');
  }

  SelectCustomerOrders(customer: Customer) {
    this.SelectCustomer(customer);
    this.router.navigate(["/orders"]); 
    }
}
