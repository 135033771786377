import { Component, OnInit } from '@angular/core';
import { IContact, Contact } from '../../models/IContact';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { SubscriptionService } from 'src/app/services/subscription.service';

@Component({
  selector: 'app-email-signup',
  templateUrl: './email-signup.component.html',
  styleUrls: ['./email-signup.component.scss']
})
export class EmailSignupComponent implements OnInit {

  contact: IContact;
  contactSent = false;
  contactFailed = false;
  userEmails: FormGroup;

  constructor(private fb: FormBuilder, private subscriptionService: SubscriptionService) {

    this.userEmails = this.createFormGroup();
  }

  ngOnInit(): void {
    this.contact = new Contact();
  }

  createFormGroup() {
    return this.fb.group({
      email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      client: ['BIdhwe543p', [Validators.required]]
    });
  }

  formSetValue() {
    if (this.contact !== undefined) {
      this.userEmails.reset({
        email: '',
        client: 'BIdhwe543p'
      });
    }
  }

  SubmitForm() {
    // this.contactSent = true;

    if (this.userEmails.valid) {
      this.contact = Object.assign({}, this.userEmails.value);

      this.subscriptionService.Subscribe(this.contact)
        .subscribe(
          response => {
            this.contactSent = true;
          },
          error => {
            this.contactFailed = true;
            console.error('Error: ' + error.message);
          });
    }
  }

  cancelError() {
    this.contactFailed = false;
  }
  cancelClicked() {
    this.resetNewItemToOriginal();
  }

  resetNewItemToOriginal() {
    // set the temp to the orginal
    this.formSetValue();
    this.contactSent = false;
    this.contactFailed = false;
  }
}

