import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Tag } from 'src/app/services/API.service';

@Component({
  selector: 'app-tag-link',
  templateUrl: './tag-link.component.html',
  styleUrls: ['./tag-link.component.scss']
})
export class TagLinkComponent implements OnInit {

  @Input() isActive = false;

  @Input() tag: Tag;

  @Output() tagPicked = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  tagClicked(tag: Tag) {
    //this.isActive = !this.isActive;
    this.tagPicked.emit(tag);
  }

}
