import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Curve, RailColour, System } from 'src/app/models/orders';
import { OrderItem, Product } from 'src/app/services/API.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-extra-item-edit',
  templateUrl: './extra-item-edit.component.html',
  styleUrls: ['./extra-item-edit.component.scss']
})
export class ExtraItemEditComponent implements OnInit {

  @Input() orderItem: OrderItem;
  @Input() product: Product;
  @Input() orderID: string;
  @Input() viewOnly: boolean;
  @Output() AddExtraClicked = new EventEmitter<OrderItem>();

  quantity = 1;

  constructor(private authService: AuthService) { }

  ngOnInit(): void {
  }

  addExtra(qty: number) {

    if (this.orderItem === undefined) {
      this.orderItem = {} as OrderItem;
      this.orderItem.id = undefined;
    }

    this.orderItem.name = this.product.name;
    this.orderItem.customerInputs = this.BuildUserInputs();
    this.orderItem.quantity = qty;
    this.orderItem.orderID = this.orderID;
    this.orderItem.productID = this.product.id;
    this.orderItem.offerID = 'test';
    this.orderItem.price = this.product.price * qty;
 //   this.orderItem.price -= Math.round(this.orderItem.price * this.orderItem.order.customer.standardItemsDiscount) / 100;
    const discountRate = this.authService.customer.standardItemsDiscount === null ? 0 : this.authService.customer.standardItemsDiscount;
    this.orderItem.discount = Math.round(this.orderItem.price * discountRate) / 100;
    this.orderItem.fullPrice = this.orderItem.price;
    this.orderItem.price -= this.orderItem.discount;
    this.orderItem.price = Math.round(this.orderItem.price * 100) / 100;
    this.AddExtraClicked.emit(this.orderItem);

  }

  setNumber(qty: number) {
    this.quantity = qty;
  }
  BuildUserInputs() : string {
    let result = {
       id: '',
      shape: undefined,
       location: '',
      // dimensionA: 0,
      // dimensionB: 0,
      // dimensionC: 0,
      // dimensionD: 0,
      // height: 0,
      system: System[this.product.supplier.name],
      // louvreWidth: 0,
      // sizeFrom: '',
      // controls: '',
      // draw: '',
       railColour: RailColour[this.product.colour],
      // reveal: false,
      // in2halves: false,
      // wand: false,
      // motorised: false,
      // imageURL: ''
    } as Curve;

    return JSON.stringify(result);

  }
}
