<div class="backgroundMed">
  <div class="container pt-3">
    <div class="row justify-content-center text-white">
      <div class="media-container-column col-lg-8 text-center">
        <div class="ml-3 pl-3">
          <h2 class="align-center pb-3">Please get in touch</h2>
          <p class="align-center mbr-light pb-3 text-white">
            Let us know if you are interested in buying or selling our curved
            headrails or curtain tracks.
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row justify-content-center">
      <div class="media-container-column col-lg-8">
        <form [formGroup]="contactForm" (ngSubmit)="SubmitForm()">
          <div class="backgroundLight p-3 m-3">
            <div class="row">
              <div class="col-md-4">
                <div class="md-form my-0 px-2">
                  <mat-form-field class="full-width">
                    <input
                      type="text"
                      matInput
                      required
                      placeholder="your full name would be nice"
                      name="name"
                      formControlName="name"
                    />
                  </mat-form-field>
                </div>
              </div>
              <div class="col-md-4">
                <div class="md-form my-0 px-2">
                  <mat-form-field class="full-width">
                    <input
                      type="email"
                      matInput
                      required
                      placeholder="enter your email address"
                      name="email"
                      formControlName="email"
                    />
                  </mat-form-field>
                </div>
              </div>
              <div class="col-md-4">
                <div class="md-form my-0 px-2">
                  <mat-form-field class="full-width">
                    <input
                      type="tel"
                      matInput
                      required
                      placeholder="enter your phone number"
                      name="phone"
                      formControlName="phone"
                    />
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>

          <div class="backgroundLight p-3 m-3">
            <div class="row">
              <p>I am?</p>
              <mat-radio-group class="py-2" aria-label="Select an option">
                <mat-radio-button
                  (change)="radioSelected = $event.value"
                  class="col-md-4"
                  value="retailer"
                  >a retailer looking for a supplier</mat-radio-button
                >
                <mat-radio-button
                  (change)="radioSelected = $event.value"
                  class="col-md-4"
                  value="customer"
                  >a customer looking for a retailer</mat-radio-button
                >
                <mat-radio-button
                  (change)="radioSelected = $event.value"
                  class="col-md-4"
                  value="other"
                  >other / not sure</mat-radio-button
                >
              </mat-radio-group>
            </div>
          </div>

          <div class="backgroundLight p-3 m-3">
            <div class="row">
              <p>Type of products you are interested in?</p>
              <mat-radio-group class="py-2" aria-label="Select an option">
                <mat-radio-button
                  (change)="productSelected = $event.value"
                  class="col-12 col-md-4"
                  value="curves"
                  [checked]="productType === 'curves'"
                  >Curved headrails</mat-radio-button
                >
                <mat-radio-button
                  (change)="productSelected = $event.value"
                  class="col-12 col-md-4"
                  value="tracks"
                  [checked]="productType === 'tracks'"
                  >Curtain Tracks</mat-radio-button
                >

                <mat-radio-button
                  (change)="productSelected = $event.value"
                  class="col-12 col-md-4"
                  value="both"
                  >Both</mat-radio-button
                >
              </mat-radio-group>
            </div>
          </div>

          <div class="backgroundLight p-3 m-3">
            <div class="row row-sm-offset">
              <div class="col-sm-12">
                <div class="md-form mt-0 mb-1">
                  <label
                    for="forDesc"
                    title="As much information as you can would be good"
                    >Please give a brief outline of your requirements</label
                  >
                  <textarea
                    type="text"
                    class="md-textarea form-control"
                    matInput
                    rows="7"
                    id="forDesc"
                    formControlName="desc"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>

          <div class="px-3 pb-3">
            <span class="input-group-btn"
              ><button
                type="submit"
                (click)="SubmitForm()"
                class="btn stylish-color-dark btn-form"
                [disabled]="contactForm.invalid || !!loading"
              >
                Send
                <i
                  *ngIf="!!loading"
                  class="fas fa-circle-notch fa-spin"
                ></i></button
            ></span>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<!-- add a row - radio buttons retailer or customer. if retailer checkbox for curtain tracks and or headrails join thisinfo onto the description or concat it-->

<app-modal-popup
  [showPopup]="contactSent"
  [title]="'Contact Form Sent'"
  [descriptions]="[{ text: 'Thank you - We will be in touch shortly' }]"
  [btnCancelText]="'Cancel'"
  (cancelClicked)="cancelClicked()"
></app-modal-popup>
<app-modal-popup
  [showPopup]="contactFailed"
  [title]="'Error sending email form'"
  [descriptions]="[
    {
      text: 'Please try again, if the problem persists please send your enquiry directly to charley@customwebsolutions.ltd'
    }
  ]"
  [btnCancelText]="'Cancel'"
  (cancelClicked)="cancelError()"
></app-modal-popup>
