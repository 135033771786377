import { Injectable } from '@angular/core';
import { Cache } from 'aws-amplify'

@Injectable({
  providedIn: 'root'
})
export class StorageService {

constructor() { }

CacheSet(key: string, value: any) {
   // Cache.setItem(key, value, {expires:600000});
   try {
    Cache.setItem(key, value);
  } catch (error) {
    console.error('Error setting item:', error);
  }
}

CacheGet(key: string) {
  //  return Cache.getItem(key);

  try {
      let item = Cache.getItem(key);
      return item;
  } catch (error) {
    console.error('Error getting item:', error);
  } finally {
  }
}

  // LOCAL STORAGE
  setItem<T>(key: string, value: T): void {
    const serializedValue = JSON.stringify(value);
    localStorage.setItem(key, serializedValue);
    
  }

  getItem<T>(key: string): T | null {
    const serializedValue = localStorage.getItem(key);
    if (serializedValue) {
      return JSON.parse(serializedValue) as T;
    }
    return null;
  }

  removeItem(key: string): void {
    localStorage.removeItem(key);
  }
  
    clear(): void {
      localStorage.clear();
    }

    // SESSION STORAGE
    setSessionItem<T>(key: string, value: T): void {
      const serializedValue = JSON.stringify(value);
      sessionStorage.setItem(key, serializedValue);
      
    }
  
    getSessionItem<T>(key: string): T | null {
      const serializedValue = sessionStorage.getItem(key);
      if (serializedValue) {
        return JSON.parse(serializedValue) as T;
      }
      return null;
    }
  
    removeSessionItem(key: string): void {
      sessionStorage.removeItem(key);
    }
}

export interface Options {
    expires: number;
}

