<div *ngIf="showPopup" class="overlay">
  <span class="helper"></span>
  <div [@anim]="animation"
    class="container-fluid dereck-height m-0 p-0"
    [class]="PanelCSS">
    <div (click)="BtnCancelClicked()" class="popupCloseButton">&times;</div>
    <h2 class="pt-1">{{ title }}</h2>
    <div *ngFor="let description of descriptions"
      class="pb-0"
      [innerHtml]="description.text"
    ></div>
    <div class="w-100 pb-1">
      <ng-content id="content"></ng-content>
    </div>

    <div class="row">
      <div *ngIf="btnShowAction" class="col">
        <button
          mdbBtn
          color="success"
          class="btn btn-success waves-effect my-3"
          (click)="BtnActionClicked()"
        >
          {{ btnActionText }}
        </button>
      </div>
      <div *ngIf="btnShowCancel" class="col">
        <button
          mdbBtn
          color="warning"
          outline="true"
          class="btn btn-warning waves-effect my-3"
          mdbWavesEffect
          (click)="BtnCancelClicked()"
        >
          {{ btnCancelText }}
        </button>
      </div>
    </div>
  </div>
</div>
