// export const Order_Init_State: BendItOrder = {
//   curves: [],
//   orderNo: '',
//   orderRef: '',
//   orderNotes: ''
// }

import { bool } from "aws-sdk/clients/signer";
import { OrderItem } from "../services/API.service";

// export interface BendItOrder {
//   orderNo: string;
//   orderRef: string;
//   orderNotes: string;
//   curves: Curve[];
// }



export interface CurtainTrack {
  id: string;
  shape: string;
  railType: RailType;
  dimensionAB: number;
  dimensionBC: number;
  dimensionCD: number;
  dimensionDE: number;
  dimensionEF: number;
  dimensionAC: number;
  dimensionAD: number;
  dimensionAE: number;
  dimensionAF: number;
  dimensionAG: number;
  dimensionBF: number;
  dimensionCF: number;
  dimensionDF: number;
  dimensionY: number;
  dimensionBD: number;
  dimensionBG: number;
  dimensionCG: number;
  dimensionDG: number;
  dimensionEG: number;
  dimensionCE: number;
  dimensionBE: number;
  dimensionFG: number;
  motor: Motor;
  motorLocation: MotorLocation;
  opening: Opening;
  brackets: Brackets;
  location: string;
  itemNumber: number;
  // IrismoCharger: number;
  // MoveliteWFCharger: number;
  // Adapter90Degree: boolean;
  // Situo1Pure: number;
  // Situo5Pure: number;
  // Telis16Pure: number;
  // SmooveMech: number;
  // Smoove4: number;
  // Smoove1Pure: number;
  // Smoove1Black: number;
  // Smoove1Sliver: number;
  // Smoove1Pureopenclose: number;
  // Smoove1Blackopenclose: number;
  // SmooveframePure: number;
  // SmooveframeBlack: number;
  // SmooveframeSilverMatt: number;
  // TahomaSwitch: number;
  // Connectivitykit: number;
}

export interface CurtainShapeModel {
  id: string;
  shape: CurtainShape;
  name: string;
  control: string;
  image: string;
  basePrice: number;
  meterPrice: number;
  bendPrice: number;
  measurements: string[];
  meterageMeasurements: string[];
  requiredMeasurements: string[];
  motors: MotorModel[];
}

export interface MotorModel {
  motor: string;
  price: number;
}

export interface BracketModel {
  bracket: string;
  price: number;
}

export interface ExtrasModel {
  // id: string;
  name: string;
  control: string;
  price: number;
}

export enum CurtainShape {
  "Straight",
  "L Shaped",
  "Curved",
  "U Shaped",
  "Angular",
  "Angular 3",
  "Angular 4",
  "Angular 5",
  "Angular 6"
}

export enum RailType {
  "Standard",
  "60mm Ripple",
  "80mm Ripple",
}

export enum Motor {
  "No Motor",
  "Glydea Ultra 60 WT",
  "Glydea Ultra 60 DCT",
  "Glydea Ultra 60 RTS",
  "Irismo 45",
  "Movelite 35 WT",
  "Movelite 35 DCT",
  "Movelite 35 RTS",
  "Movelite 35RTS WF"
}

export enum MotorLocation {
  "Left",
  "Right"
}

export enum Opening {
  "Side",
  "Centre"
}

export enum Brackets {
  "Swivel",
  "One touch",
  "Wall + Swivel",
  "Wall +one touch",
  "Double wall Bkt +Swivel",
  "Double wall Bkt + one touch"
}



export interface Curve {
  id: string;
  shape: Shape;
  location: string;
  dimensionA: number;
  dimensionB: number;
  dimensionC: number;
  dimensionD: number;
  height: number;
  system: System;
  louvreWidth: LouvreWidth;
  sizeFrom: SizesFrom;
  controls: Control;
  draw: Draw;
  railColour: RailColour;
  reveal: YesNo;
  in2halves: YesNo;
  wand: YesNo;
  motorised: YesNo;
  imageURL: string;
  itemNumber: number;
}

export enum Shape {
  "Bow Bay",
  "Splay Bay",
  "Hockey Stick Left",
  "Hockey Stick Right",
}

export enum System {
  "Vogue",
  "Benthin",
}

export enum SizesFrom {
  "Recess",
  "Blind",
}

export enum LouvreWidth {
  "89mm",
  "127mm",
  "Allusions (103mm)",
}

export enum Control {
  "Left",
  "Right",
}

export enum Draw {
  "Left",
  "Split",
  "Right",
}

export enum RailColour {
  "White",
  "Brown",
  "Black",
  "Champagne",
  "Silver",
  "Anthracite",
}

export enum YesNo {
  "Yes",
  "No",
}



export class TableRow {
  tableId: string;
  product: string;
  description: string;
  type: string;
  shape: ColumnItem;
  railType: ColumnItem;
  location: ColumnItem;
  dimensionA: ColumnItem;
  dimensionB: ColumnItem;
  dimensionC: ColumnItem;
  dimensionD: ColumnItem;
  measurements: ColumnItem;
  system: ColumnItem;
  colour: ColumnItem;
  quantity: number;
  price: number;
  orderStatus: string;
  orderItem: OrderItem;
  discount: number;
  fullPrice: number;
  itemNumber: number;
  isEditable: bool;
}

export class ColumnItem {
  label: string;
  value: string;
  tooltip: string;
}

