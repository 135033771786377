<mdb-card class="card-deck wow flipInX" data-wow-delay="delay">
  <!--Card image-->
  <mdb-card-img [src]="src" alt="Card image cap" [class]="paddingCSSclass"></mdb-card-img>
  <!--Card content-->
  <mdb-card-body>

    <!--Title-->
    <mdb-card-title>
      <h4>{{cardTitle}}</h4>
    </mdb-card-title>

    <!--Text-->
    <mdb-card-text>{{cardText}}</mdb-card-text>

    <div (click)="onCardButtonClicked()" *ngIf="showButton" mdbBtn color="primary" mdbWavesEffect>{{buttonText}}</div>
  </mdb-card-body>
</mdb-card>
