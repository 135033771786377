<div class="row pb-3">
  <div [class]="selections.length === 9 ? 'col-flex' : 'col-6'" class="cursor" *ngFor="let selection of selections">
    <a autofocus (focus)="SetFocus(selection)" (mouseenter)="SetMouseEnter(selection)" (mouseleave)="SetMouseLeave()"  [attr.tabindex]="selected === selection ? -1 : 0" (click)="onSelectionChanged(selection)">
      <div class="m-0 p-1 selection" [ngClass]="GetSelectedStatus(selection)">
        <span *ngIf="showLabel">{{selection}}</span>
        <img class="img-fluid" [src]="'/assets/images/' + selection.image">
      </div>
    </a>
  </div>
</div>
