<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 670.71 192.35">
    <defs>
        <style>
            .cls-1 {
                letter-spacing: 0em;
            }

            .cls-2 {
                stroke: #ef3f2b;
            }

            .cls-2,
            .cls-3 {
                fill: none;
            }

            .cls-2,
            .cls-3,
            .cls-4 {
                stroke-miterlimit: 10;
            }

            .cls-3 {
                stroke-dasharray: 0 0 8 2;
                stroke-width: 2px;
            }

            .cls-5 {
                fill: #010101;
            }

            .cls-5,
            .cls-7,
            .cls-8 {
                stroke-width: 0px;
            }

            .cls-9 {
                letter-spacing: 0em;
            }

            .cls-6 {
                font-family: MyriadPro-Regular, 'Myriad Pro';
                font-size: 12px;
            }

            .cls-10 {
                letter-spacing: 0em;
            }

            .cls-11 {
                letter-spacing: .01em;
            }

            .cls-7 {
                fill: #fff;
            }

            .cls-8 {
                fill: #87331b;
            }

            .cls-4 {
                fill: #f5eb16;
                stroke: #2e3690;
            }

            .cls-12 {
                letter-spacing: .01em;
            }

            .cls-13 {
                letter-spacing: 0em;
            }
        </style>
    </defs>
    <g id="revealR">
        <rect class="cls-8" x="617.21" y="131.85" width="53" height="60" />
        <path class="cls-5" d="M669.71,132.35v59h-52v-59h52M670.71,131.35h-54v61h54v-61h0Z" />
    </g>
    <g id="revealL">
        <rect class="cls-8" x=".5" y="133.83" width="53" height="56" />
        <path class="cls-5" d="M53,134.32v55H1v-55h52M54,133.32H0v57h54v-57h0Z" />
    </g>
    <polygon class="cls-4"
        points="56.91 140.69 52.51 134.01 153.76 67.34 155.27 66.6 155.61 66.5 274.15 32.35 399.05 32.35 518.33 66.94 519.91 68 621.86 132.97 617.56 139.72 515.48 74.67 514.9 74.28 397.37 40.35 276.28 40.13 158.16 74.1 157.74 74.3 56.91 140.69" />
        <line id="guideA" class="cls-2" x1="361.04" y1="41.28" x2="314.18" y2="41.28" />
        <line id="guideAB" class="cls-2" x1="358.55" y1="61.73" x2="311.69" y2="61.73" />
        <line id="guideLReveal" class="cls-2" x1="39.44" y1="116.8" x2="65.58" y2="155.69" />
        <line id="guideRReveal" class="cls-2" x1="629.54" y1="120.86" x2="604.71" y2="160.6" />
        <path class="cls-7"
        d="M80.07,153.91h0c-1.14-1.66-.67-3.93,1.03-5l89.12-55.98c.12-.08.25-.15.38-.21l1.18-.55.31-.09s108.93-29.74,109.24-29.74h111.38c.47,0,109.97,29.98,109.97,29.98.34.09.67.24.97.43l.99.64,89.93,54.66c1.72,1.04,2.22,3.31,1.1,4.98h0c-1.05,1.57-3.15,2.04-4.77,1.06l-90.3-54.89-.53-.34-106.71-29.3-.58-.24h-110.98c-.32,0-.63.04-.94.13l-106.46,29.26-.38.18-89.18,56.01c-1.6,1.01-3.71.57-4.78-.98Z" />
        <g id="measureB">
            <g>
                <line class="cls-3 dimDFs" x1="397.9" y1="49.63" x2="610.77" y2="145.11" />
                <path class="dimDF" d="M406.45,63.98c.46-.3.59-.92.28-1.39l-8.41-12.78,15.14-2.22c.55-.08.93-.59.85-1.13-.08-.55-.59-.92-1.14-.85l-16.69,2.45c-.34.05-.63.27-.77.58-.14.31-.11.67.08.96l9.27,14.09c.11.16.26.29.43.36.3.14.66.12.96-.08Z" />
                <path class="dimDF" d="M594.37,148.28c-.08-.55.3-1.05.85-1.13l15.14-2.22-8.41-12.78c-.3-.46-.18-1.08.28-1.39.46-.3,1.08-.17,1.39.29l9.27,14.09c.19.28.22.65.08.96s-.43.53-.77.58l-16.69,2.45c-.2.03-.39,0-.55-.08-.3-.14-.53-.42-.58-.77Z" />
            </g>
        </g>
        <g id="measureB-2" data-name="measureB">
            <g>
                <line class="cls-3 dimCFs" x1="280.61" y1="50.7" x2="610.66" y2="145.43" />
                <path class="dimCF" d="M291.1,63.7c.41-.36.45-1,.08-1.41l-10.14-11.46,14.67-4.34c.53-.16.83-.71.68-1.24-.16-.53-.72-.83-1.24-.68l-16.18,4.79c-.33.1-.58.35-.68.68-.09.33-.01.68.21.94l11.18,12.64c.13.15.3.25.47.3.32.09.67.02.94-.21Z" />
                <path class="dimCF" d="M594.87,150.89c-.16-.53.15-1.09.68-1.24l14.67-4.34-10.14-11.46c-.37-.41-.33-1.05.08-1.41.41-.36,1.05-.32,1.41.09l11.18,12.64c.23.26.31.61.21.94s-.35.59-.68.68l-16.18,4.79c-.19.06-.38.05-.56,0-.32-.09-.58-.34-.69-.68Z" />
            </g>
        </g>
        <g id="measureB-3" data-name="measureB">
            <g>
                <line class="cls-3 dimBFs" x1="163.84" y1="81.27" x2="609.98" y2="146.14" />
                <path class="dimBF" d="M175.99,92.74c.36-.42.31-1.05-.11-1.41l-11.59-9.99,13.95-6.28c.5-.23.73-.82.5-1.32-.23-.5-.82-.73-1.32-.5l-15.38,6.92c-.31.14-.53.43-.58.77-.05.34.08.68.34.9l12.78,11.02c.15.13.33.21.51.23.33.05.67-.07.9-.34Z" />
                <path class="dimBF" d="M595.07,153.67c-.23-.5,0-1.1.5-1.32l13.95-6.28-11.59-9.99c-.42-.36-.47-.99-.11-1.41.36-.42,1-.46,1.41-.1l12.78,11.02c.26.22.39.56.34.9s-.27.63-.58.77l-15.38,6.92c-.18.08-.37.1-.55.08-.33-.05-.62-.26-.77-.58Z" />
            </g>
        </g>
        <g id="measureB-4" data-name="measureB">
            <g>
                <line class="cls-3 dimAEs" x1="63.98" y1="149.18" x2="507.82" y2="82.14" />
                <path class="dimAE" d="M78.93,156.63c.22-.5,0-1.1-.51-1.32l-13.99-6.2,11.53-10.05c.42-.36.46-1,.1-1.41-.36-.42-1-.46-1.41-.1l-12.72,11.09c-.26.22-.38.57-.33.9.05.34.27.63.58.77l15.42,6.84c.18.08.37.1.55.07.33-.05.62-.26.77-.58Z" />
                <path class="dimAE" d="M495.73,93.67c-.36-.42-.32-1.05.1-1.41l11.53-10.05-13.99-6.2c-.51-.22-.74-.82-.51-1.32.22-.5.82-.73,1.32-.51l15.42,6.84c.31.14.53.43.58.77s-.08.68-.33.9l-12.72,11.09c-.15.13-.33.21-.51.23-.33.05-.67-.06-.91-.33Z" />
        </g>
    </g>
    <g id="measureB-5" data-name="measureB">
        <g>
            <line class="cls-3 dimADs" x1="63.49" y1="149.14" x2="393.41" y2="49.34" />
            <path class="dimAD" d="M79.36,154.37c.15-.53-.16-1.08-.7-1.23l-14.73-4.13,9.97-11.6c.36-.42.31-1.05-.1-1.41-.42-.36-1.05-.31-1.41.11l-11,12.79c-.22.26-.3.61-.2.94s.36.58.69.67l16.24,4.55c.19.05.38.05.56,0,.32-.1.58-.35.68-.69Z" />
            <path class="dimAD" d="M383.1,62.48c-.42-.36-.47-.99-.1-1.41l9.97-11.6-14.73-4.13c-.53-.15-.84-.7-.7-1.23.15-.53.71-.84,1.23-.69l16.24,4.55c.33.09.59.35.69.67.1.33.02.68-.2.94l-11,12.79c-.13.15-.29.25-.47.31-.32.1-.67.03-.94-.2Z" />
        </g>
    </g>
    <g id="measureB-6" data-name="measureB">
        <g>
            <line class="cls-3 dimACs" x1="63.38" y1="148.83" x2="276.12" y2="51.04" />
            <path class="dimAC" d="M79.81,151.84c.08-.55-.31-1.05-.86-1.13l-15.16-2.08,8.29-12.86c.3-.46.17-1.09-.3-1.38-.46-.3-1.09-.16-1.38.3l-9.14,14.18c-.18.29-.21.65-.07.96s.44.53.77.57l16.71,2.29c.2.03.39,0,.55-.08.3-.14.53-.42.58-.77Z" />
            <path class="dimAC" d="M267.71,65.48c-.46-.3-.6-.92-.3-1.38l8.29-12.86-15.16-2.08c-.55-.07-.93-.58-.86-1.13.08-.55.59-.93,1.13-.86l16.71,2.29c.34.05.63.26.77.57.14.31.12.67-.07.96l-9.14,14.18c-.11.17-.26.29-.42.37-.3.14-.66.12-.96-.07Z" />
        </g>
    </g>
    <g id="measureB-7" data-name="measureB">
        <g>
            <line class="cls-3 dimEFs" x1="524.81" y1="58.16" x2="625.85" y2="122.11" />
            <path class="dimEF" d="M531.23,73.59c.5-.23.71-.83.48-1.33l-6.51-13.85,15.3-.04c.55,0,1-.45,1-1,0-.55-.46-1-1-1l-16.87.05c-.34,0-.66.18-.84.47s-.2.65-.06.96l7.18,15.27c.09.18.22.32.37.42.28.18.64.21.96.06Z" />
            <path class="dimEF" d="M609.16,122.91c0-.55.45-1,1-1l15.3-.04-6.51-13.85c-.24-.5-.02-1.1.48-1.33.5-.23,1.1-.01,1.33.48l7.18,15.27c.14.31.12.67-.06.96s-.5.46-.84.47l-16.87.05c-.2,0-.38-.06-.54-.16-.28-.18-.47-.49-.47-.84Z" />
        </g>
    </g>
    <g id="measureB-8" data-name="measureB">
        <g>
            <line class="cls-3 dimDEs" x1="403.74" y1="22.34" x2="520.47" y2="56.21" />
            <path class="dimDE" d="M414.2,35.37c.41-.36.45-1,.09-1.41l-10.1-11.49,14.68-4.3c.53-.16.84-.71.68-1.24-.16-.53-.72-.83-1.24-.68l-16.19,4.74c-.33.1-.58.35-.68.68-.1.33-.02.68.21.94l11.14,12.67c.13.15.3.25.47.3.32.09.67.02.94-.21Z" />
            <path class="dimDE" d="M504.67,61.62c-.16-.53.15-1.09.68-1.24l14.68-4.3-10.1-11.49c-.37-.42-.33-1.05.09-1.41.41-.36,1.05-.32,1.41.09l11.14,12.67c.22.26.3.61.21.94-.1.33-.35.59-.68.68l-16.19,4.74c-.19.05-.38.05-.56,0-.32-.09-.58-.34-.68-.68Z" />
        </g>
    </g>
    <g id="measureB-9" data-name="measureB">
        <g>
            <line class="cls-3 dimCDs" x1="274.06" y1="20.82" x2="399.03" y2="21.66" />
            <path class="dimCD" d="M287.67,30.51c.3-.46.17-1.08-.3-1.38l-12.85-8.3,12.96-8.13c.47-.29.61-.91.32-1.38-.29-.47-.92-.61-1.38-.32l-14.29,8.97c-.29.18-.47.5-.47.84,0,.34.17.66.46.85l14.17,9.16c.17.11.35.16.54.16.33,0,.66-.16.85-.46Z" />
            <path class="dimCD" d="M385.29,31.16c-.29-.47-.15-1.09.32-1.38l12.96-8.13-12.85-8.3c-.46-.3-.6-.92-.3-1.38.3-.46.92-.59,1.38-.3l14.17,9.16c.29.19.46.51.46.85,0,.34-.18.66-.47.84l-14.29,8.97c-.17.1-.35.15-.54.15-.33,0-.65-.17-.84-.47Z" />
        </g>
    </g>
    <g id="measureB-10" data-name="measureB">
        <g>
            <line class="cls-3 dimBCs" x1="151.11" y1="55.79" x2="269.35" y2="21.48" />
            <path class="dimBC" d="M166.92,61.2c.16-.53-.15-1.09-.68-1.24l-14.68-4.3,10.1-11.49c.37-.42.33-1.05-.09-1.41-.41-.36-1.05-.32-1.41.09l-11.14,12.67c-.22.26-.3.61-.21.94.1.33.35.59.68.68l16.19,4.74c.19.05.38.05.56,0,.32-.09.58-.34.68-.68Z" />
            <path class="dimBC" d="M258.9,34.51c-.41-.36-.45-1-.09-1.41l10.1-11.49-14.68-4.3c-.53-.16-.84-.71-.68-1.24.16-.53.72-.83,1.24-.68l16.19,4.74c.33.1.58.35.68.68.1.33.02.68-.21.94l-11.14,12.67c-.13.15-.3.25-.47.3-.32.09-.67.02-.94-.21Z" />
        </g>
    </g>
    <g id="measureB-11" data-name="measureB">
        <g>
            <line class="cls-3 dimABs" x1="46.78" y1="123.4" x2="146.79" y2="57.78" />
            <path class="dimAB" d="M63.47,123.93c0-.55-.46-.99-1.02-.99l-15.3.21,6.28-13.95c.23-.5,0-1.1-.5-1.32-.5-.23-1.1,0-1.32.5l-6.92,15.38c-.14.31-.11.67.08.96.19.29.51.46.85.45l16.87-.23c.2,0,.38-.06.53-.16.28-.18.46-.5.45-.85Z" />
            <path class="dimAB" d="M140.63,73.3c-.5-.23-.73-.82-.5-1.32l6.28-13.95-15.3.21c-.55,0-1.01-.43-1.02-.99,0-.55.44-1.01.99-1.01l16.87-.23c.34,0,.66.17.85.45s.22.65.08.96l-6.92,15.38c-.08.18-.21.32-.36.43-.28.18-.64.22-.96.08Z" />
        </g>
    </g>
    <line id="guideLReveal-2" data-name="guideLReveal" class="cls-2" x1="525.78" y1="50.94" x2="504.65" y2="92.76" />
    <line id="guideLReveal-3" data-name="guideLReveal" class="cls-2" x1="403" y1="14.05" x2="392.71" y2="62.35" />
    <line id="guideLReveal-4" data-name="guideLReveal" class="cls-2" x1="269.96" y1="12.33" x2="281.41" y2="62.7" />
    <line id="guideLReveal-5" data-name="guideLReveal" class="cls-2" x1="144.45" y1="48.04" x2="165.98" y2="89.66" />
    <g id="measureB-12" data-name="measureB">
        <g>
            <line class="cls-3 dimYs" x1="337.58" y1="64.75" x2="337.38" y2="90.59" />
            <path class="dimY" d="M327.88,78.35c.46.3,1.08.17,1.38-.3l8.32-12.84,8.12,12.97c.29.47.91.61,1.38.32.47-.29.61-.92.32-1.38l-8.95-14.3c-.18-.29-.5-.47-.84-.47-.34,0-.66.17-.85.46l-9.18,14.16c-.11.17-.16.35-.16.54,0,.33.16.66.46.85Z" />
        </g>
    </g>
    <g id="measureB">
        <g>
            <line class="cls-3 dimYs" x1="337.38" y1="37.84" x2="337.38" y2="9.38" />
            <path class="dimY" d="M346.98,24.17c-.46-.3-1.08-.16-1.38.31l-8.22,12.9-8.22-12.9c-.3-.47-.92-.61-1.38-.31-.46.3-.6.92-.31,1.38l9.06,14.23c.18.29.5.46.84.46s.66-.18.84-.46l9.06-14.23c.11-.17.16-.35.16-.54,0-.33-.16-.65-.46-.85Z" />
        </g>
    </g>
    <g id="measureB">
        <g>
            <line class="cls-3 dimAFs" x1="62.92" y1="149.39" x2="611.13" y2="146.49" />
            <path class="dimAF" d="M76.64,158.91c.29-.47.15-1.08-.32-1.38l-12.95-8.15,12.86-8.29c.47-.3.6-.92.3-1.38-.3-.46-.92-.59-1.38-.3l-14.18,9.14c-.29.19-.46.5-.46.85s.18.66.47.84l14.28,8.99c.17.1.35.15.54.15.33,0,.65-.17.84-.47Z" />
            <path class="dimAF" d="M597.51,156.16c-.3-.46-.16-1.08.3-1.38l12.86-8.29-12.95-8.15c-.47-.29-.61-.91-.32-1.38s.92-.6,1.38-.31l14.28,8.99c.29.18.47.5.47.84,0,.34-.17.66-.46.85l-14.18,9.14c-.17.11-.35.16-.54.16-.33,0-.66-.16-.85-.46Z" />
        </g>
    </g>
    
    <text class="cls-6" transform="translate(34.75 117.38) rotate(-33.91)">A</text>
    <text class="cls-6" transform="translate(140.63 47.86) rotate(-27.35)">B</text>
    <text class="cls-6" transform="translate(265.29 11.34) rotate(-12.8)">C</text>
    <text class="cls-6" transform="translate(400.17 11.96) rotate(12.02)">D</text>
    <text class="cls-6" transform="translate(524.23 47.24) rotate(26.81)">E</text>
    <text class="cls-6" transform="translate(628.57 116.54) rotate(32)">F</text>
    <text class="cls-6 dimAB" transform="translate(89.87 90.63) rotate(-33.27)">A-B</text>
    <text class="cls-6 dimBC" transform="translate(204.65 36.61) rotate(-16.18)">B-C</text>
    <text class="cls-6 dimCD" transform="translate(350.09 17.81)">C-D</text>
    <text class="cls-6 dimDE" transform="translate(455.52 33.35) rotate(16.18)">D-E</text>
    <text class="cls-6 dimEF" transform="translate(572.55 83.87) rotate(32.33)">E-F</text>
    <text class="cls-6 dimAC" transform="translate(131.65 113.55) rotate(-24.69)">A-C</text>
    <text class="cls-6 dimAD" transform="translate(263.38 85.52) rotate(-16.83)">A-D</text>
    <text class="cls-6 dimAE" transform="translate(359.11 101.17) rotate(-8.59)">A-E</text>
    <text class="cls-6 dimAF" transform="translate(330.7 145.18)">A-F</text>
    <text class="cls-6 dimBF" transform="translate(301.65 98.11) rotate(8.27)">B-F</text>
    <text class="cls-6 dimCF" transform="translate(400.49 82.13) rotate(16.02)">C-F</text>
    <text class="cls-6 dimDF" transform="translate(522.57 102.01) rotate(24.16)">D-F</text>
    <text class="cls-6 dimY" transform="translate(335.12 54.78)">Y</text>
</svg>