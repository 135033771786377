
<i *ngIf="price.info.length > 0" class="infoIcon indigo-text info-circle fas fa-lg" (click)="showPanel = !showPanel"></i>

<div *ngIf="showPanel" (click)="showPanel=false">
  <div class="overlay">

  </div>

  <div class="panel">
    <h3>{{price.item}}</h3>
    <div class="row text-center">
      <div class="col-6">
        <i class="fas balance-scale fa-3x red-text"></i><br>
        Traditional<br>
        £{{price.traditional}} {{price.hourlyOnly || price.monthlyOnly ? price.hourlyOnly  ? "(hourly)" : "(monthly)" : ""}}
      </div>

      <div class="col-6">
        <i class="fas chart-line fa-3x green-text"></i><br>
        Cashflow Workflow<br>
        £{{price.cashflow}} ({{price.hourlyOnly ? "hourly" : "monthly"}})
      </div>
    </div>
    <br>
    <p>
      {{price.info}}
    </p>
  </div>
</div>
