import { Component, Input, OnInit } from "@angular/core";
import { IContact, Contact } from "../../../models/IContact";
import { Validators, FormGroup, FormBuilder } from "@angular/forms";
import { DataService } from "src/app/services/data.service";
import { MatRadioChange } from "@angular/material/radio";
import { JsonPipe } from "@angular/common";

@Component({
  selector: "app-contact-form",
  templateUrl: "./contact-form.component.html",
  styleUrls: ["./contact-form.component.scss"],
})
export class ContactFormComponent implements OnInit {
  @Input() productType: "curves" | "tracks";
  contact: IContact;
  contactSent = false;
  contactFailed = false;
  contactForm: FormGroup;
  radioSelected;
  productSelected;
  loading: boolean = false;

  constructor(private fb: FormBuilder, private dataService: DataService) {
    this.contactForm = this.createFormGroup();
  }


  ngOnInit(): void {
    this.contact = new Contact();
  }

  createFormGroup() {
    return this.fb.group({
      name: ["", [Validators.required]],
      email: ["", [Validators.required]],
      phone: ["", [Validators.required]],
      desc: ["", [Validators.required]],
      client: ["BIdhwe543p", [Validators.required]],
    });
  }

  formSetValue() {
    if (this.contact !== undefined) {
      this.contactForm.reset({
        name: "",
        email: "",
        phone: "",
        desc: "",
        client: "BIdhwe543p",
      });
    }
  }

  SubmitForm() {
    // this.contactSent = true;
    this.loading = true;
    if (this.contactForm.valid) {
      this.contact = Object.assign({}, this.contactForm.value);
      this.contact.desc = `Message description: ${this.contactForm.get("desc").value} ${this.radioSelected ? `Customer Type: ${this.radioSelected}` : ""
    } ${this.productSelected ? `Product type: ${this.productSelected}` : ""}`
      // console.log("form sent", this.contactForm.value);
      this.dataService.SendContactForm(this.contact).then(
        (response) => {
          this.contactSent = true;
          this.loading = false;
        },
        (error) => {
          this.contactSent = true;
          console.error("Error: " + JSON.stringify(error));
          this.loading = false;
        }
      );
    }
  }
  cancelError() {
    this.contactFailed = false;
  }
  cancelClicked() {
    this.resetNewItemToOriginal();
  }

  resetNewItemToOriginal() {
    // set the temp to the orginal
    this.formSetValue();
    this.contactSent = false;
    this.contactFailed = false;
  }
}
