import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Product, Document } from 'src/app/services/API.service';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {

  @Input() product: Product;
  @Input() showFull: boolean = false;
  @Output() selectProduct = new EventEmitter<Product>();

  images: Document[] = [];

  constructor() { }

  ngOnInit(): void {
    this.images = this.product.documents.items;
  }
  
  onSelectProduct(product: Product) {
    this.selectProduct.emit(product);
  }
  
  onSelectDocument(document: Document) {
    // document not needed here, just select the product
    this.selectProduct.emit(this.product);
  }
}
