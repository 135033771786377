<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 571 188.1" height="100%">
  <defs>
    <style>
      .cls-1,
      .cls-5,
      .cls-7 {
        fill: none;
      }

      .cls-1 {
        stroke: #fff;
        stroke-width: 10px;
      }

      .cls-1,
      .cls-4,
      .cls-5,
      .cls-7 {
        stroke-miterlimit: 10;
      }

      .cls-2 {
        fill: #8b2d0f;
      }

      .cls-3,
      .cls-6 {
        isolation: isolate;
        font-family: MyriadPro-Bold, Myriad Pro;
        font-weight: 700;
      }

      .cls-3 {
        font-size: 12px;
      }

      .cls-4 {
        fill: #fdff00;
        stroke: #2e3192;
      }

      .cls-5 {
        stroke-width: 2px;
        stroke-dasharray: 8 2;
      }

      .cls-6 {
        font-size: 12px;
      }

      .cls-7 {
        stroke: #ff371f;
      }
    </style>
  </defs>

  <path id="curveReveal" class="cls-1 revealYes" d="M137,278l82.4-78.9a47.31,47.31,0,0,1,35.9-16.6H584" transform="translate(-67 -127.1)" />
  <path id="curveNoReveal" class="cls-1 revealNo" d="M155,261l65.9-64c7.2-8,21.2-15,32-15l331.1-.5" transform="translate(-67 -127.1)" />

  <g id="Reveal2" class="revealYes">
    <rect class="cls-2" x="517.5" y="28.4" width="53" height="53" />
    <path d="M637,156v52H585V156h52m1-1H584v54h54V155Z" transform="translate(-67 -127.1)" />
  </g>
  <g id="Reveal1" class="revealYes">
    <rect class="cls-2" x="0.5" y="134.6" width="53" height="53" />
    <path d="M120,262.2v52H68v-52h52m1-1H67v54h54v-54Z" transform="translate(-67 -127.1)" />
  </g>
  <g id="noReveal2" class="revealNo">
    <rect class="cls-2" x="517.5" y="20.4" width="53" height="10" />
    <path d="M637,148v9H585v-9h52m1-1H584v11h54V147Z" transform="translate(-67 -127.1)" />
  </g>
  <g id="noReveal1" class="revealNo">
    <rect class="cls-2" x="0.5" y="125.4" width="53" height="10" />
    <path d="M120,253v9H68v-9h52m1-1H67v11h54V252Z" transform="translate(-67 -127.1)" />
  </g>

  <polygon class="cls-4" points="53.8 135.2 48.1 129.6 153.3 22.4 516.9 22.4 516.9 30.4 156.6 30.4 53.8 135.2" />

  <g id="messureA">
    <line class="cls-5 dimAs" x1="160.4" y1="30.9" x2="513.6" y2="30.9" />
    <path class="dimA" d="M241.1,167.6a1,1,0,0,0-.3-1.4L227.9,158l12.9-8.2a1,1,0,0,0-1.1-1.7l-14.2,9.1a1,1,0,0,0-.5.8.89.89,0,0,0,.5.8l14.2,9.1a1.69,1.69,0,0,0,.5.2A1.05,1.05,0,0,0,241.1,167.6Z" transform="translate(-67 -127.1)" />
    <path class="dimA" d="M566.9,167.6a1,1,0,0,1,.3-1.4l12.9-8.2-12.9-8.2a1,1,0,1,1,1.1-1.7l14.2,9.1a1,1,0,0,1,.5.8.89.89,0,0,1-.5.8l-14.2,9.1a1.69,1.69,0,0,1-.5.2A1.05,1.05,0,0,1,566.9,167.6Z" transform="translate(-67 -127.1)" />
  </g>
  <text class="cls-3 dimA" transform="translate(280 45)">A - Front Width</text>

  <g id="measureB">
    <line class="cls-5 dimBs" x1="156.3" y1="32.6" x2="55.7" y2="133.2" />
    <path class="dimB" d="M206.8,162.6a1.1,1.1,0,0,0,1.2.8l15-3.4-3.3,14.9a1,1,0,1,0,2,.4l3.7-16.5a.81.81,0,0,0-.3-.9,1.61,1.61,0,0,0-.9-.3l-16.5,3.7a.55.55,0,0,0-.5.3A1.28,1.28,0,0,0,206.8,162.6Z" transform="translate(-67 -127.1)" />
    <path class="dimB" d="M125.6,243.8a1.1,1.1,0,0,1,.8,1.2L123,260l14.9-3.3a1,1,0,1,1,.4,2l-16.5,3.7a.81.81,0,0,1-.9-.3,1.61,1.61,0,0,1-.3-.9l3.7-16.5a.55.55,0,0,1,.3-.5A1.28,1.28,0,0,1,125.6,243.8Z" transform="translate(-67 -127.1)" />
  </g>
  <text class="cls-3 dimB" transform="translate(100 110) rotate(-45.84)">B - Return</text>

  <g id="measureC">
    <line class="cls-5 dimCs" x1="56.3" y1="134.4" x2="514.7" y2="31.4" />
    <path class="dimC" d="M138.8,267.8a1,1,0,0,0-.6-1.3l-14.4-5.2,10.8-10.8a1,1,0,0,0-1.4-1.4l-11.9,12a1.4,1.4,0,0,0-.3.9.86.86,0,0,0,.6.7l15.9,5.7a.64.64,0,0,0,.6,0A.73.73,0,0,0,138.8,267.8Z" transform="translate(-67 -127.1)" />
    <path class="dimC" d="M570.4,170.9a1,1,0,0,1,0-1.4l10.8-10.8-14.4-5.2a1,1,0,0,1,.7-1.9l15.9,5.7a.86.86,0,0,1,.6.7c0,.3,0,.7-.3.9l-11.9,12a2.19,2.19,0,0,1-.5.3A1.61,1.61,0,0,1,570.4,170.9Z" transform="translate(-67 -127.1)" />
  </g>
  <text class="cls-6 dimC" transform="translate(200 95) rotate(-12.72)">C - Width (Reveal)</text>

  <line id="GuideACNoReveal" class="cls-7" x1="31.7" y1="135.8" x2="107.5" y2="135.4" />
  <line id="guideAB" class="cls-7" x1="145.7" y1="6.9" x2="166.3" y2="48.9" />
  <line id="guideBC" class="cls-7" x1="517.4" x2="517.4" y2="46.8" />
  <line id="guideACReveal" class="cls-7" x1="38.5" y1="118.4" x2="70.5" y2="152.4" />
</svg>
