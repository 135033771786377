<div class="container">
  <div class="row">
    <div class="col-8">
      <!-- Steppers -->
      <div class="stepper-container position-relative">
        <mat-stepper linear #stepper (selectionChange)="SelectionChanged($event)" class="text-white">
          <mat-step [stepControl]="designForm" [editable]="true">
            <form [formGroup]="designForm">
              <!-- ORDER LINE DETAILS-->

              <ng-template matStepLabel>Shape & System</ng-template>

              <!-- Shape -->
              <div class="full-width">
                <mat-label>Shape</mat-label>
                <app-item-picker-image (gotFocus)="ControlEnter('sha')" [selections]="shapes" [selected]="shape"
                  [showLabel]="false" (selectedChanged)="SetSelectedShape($event)">
                </app-item-picker-image>
              </div>

              <!-- System Vogue / Benthin-->
              <mat-form-field class="full-width p-1">
                <mat-label>System</mat-label>
                <mat-select (focus)="ControlEnter('sys')" (mouseenter)="ControlEnter('sys')"
                  (mouseleave)="ControlReset()" formControlName="system">
                  <mat-option *ngFor="let item of systems" [value]="item.key">
                    {{ item.value }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <!-- Reveal Yes / No-->
              <mat-form-field class="full-width p-1">
                <mat-label>Reveal</mat-label>
                <mat-select (focus)="ControlEnter('rev')" (mouseenter)="ControlEnter('rev')"
                  (mouseleave)="ControlReset()" formControlName="reveal">
                  <mat-option *ngFor="let item of reveals" [value]="item.key">
                    {{ item.value }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <div class="row">
                <div class="col">
                  <button type="button" class="btn btn-success" (click)="ControlEnter('dimA', true, designForm.valid)"
                    matStepperNext>
                    Next
                  </button>
                </div>
              </div>
            </form>
          </mat-step>

          <mat-step [stepControl]="sizesForm" [editable]="true">
            <form [formGroup]="sizesForm">
              <ng-template matStepLabel>Sizes</ng-template>
              <div class="row">
                <div class="col-6">
                  <!-- Dimension A -->
                  <mat-form-field class="full-width">
                    <input type="number" (focus)="ControlEnter('dimA')" (mouseenter)="ControlEnter('dimA')"
                      (mouseleave)="ControlReset()" formControlName="dimensionA" matInput placeholder="Dimension A" />
                  </mat-form-field>
                </div>
                <div class="col-6">
                  <!-- Dimension B -->
                  <mat-form-field class="full-width">
                    <input type="number" (focus)="ControlEnter('dimB')" (mouseenter)="ControlEnter('dimB')"
                      (mouseleave)="ControlReset()" formControlName="dimensionB" matInput placeholder="Dimension B" />
                  </mat-form-field>
                  <div class="text-danger" *ngIf="sizesForm.errors?.bhalfa">
                    Dimension B must be half or less than half of Dimension A
                  </div>
                </div>

                <div class="col-6">
                  <!-- Dimension C -->
                  <mat-form-field *ngIf="showDimC" class="full-width">
                    <input type="number" (focus)="ControlEnter('dimC')" (mouseenter)="ControlEnter('dimC')"
                      (mouseleave)="ControlReset()" formControlName="dimensionC" matInput placeholder="Dimension C" />
                  </mat-form-field>
                </div>
                <div class="col-6">
                  <!-- Dimension D -->
                  <mat-form-field *ngIf="showDimD" class="full-width">
                    <input type="number" (focus)="ControlEnter('dimD')" (mouseenter)="ControlEnter('dimD')"
                      (mouseleave)="ControlReset()" formControlName="dimensionD" matInput placeholder="Dimension D" />
                  </mat-form-field>
                </div>

                <div class="col-6">
                  <!-- Height -->
                  <mat-form-field class="full-width">
                    <input type="number" (focus)="ControlEnter('hei')" (mouseenter)="ControlEnter('hei')"
                      (mouseleave)="ControlReset()" formControlName="height" matInput placeholder="Operating Height" />
                  </mat-form-field>
                </div>
                <div class="col-6">

                  <!-- Recess / Blind -->
                  <mat-form-field class="full-width">
                    <mat-label>Size from</mat-label>
                    <mat-select (focus)="ControlEnter('sizeFrom')" (mouseenter)="ControlEnter('sizeFrom')"
                      (mouseleave)="ControlReset()" formControlName="sizeFrom">
                      <mat-option *ngFor="let item of sizesFrom" [value]="item.key">
                        {{ item.value }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <button type="button" class="btn btn-warning" (click)="ControlEnter('rev')" matStepperPrevious>
                    Back
                  </button>
                </div>
                <div class="col">
                  <button type="button" class="btn btn-success" (click)="ControlEnter('lou', true)" matStepperNext>
                    Next
                  </button>
                </div>
              </div>
            </form>
          </mat-step>

          <mat-step [stepControl]="controlsForm" [editable]="true">
            <form [formGroup]="controlsForm" (ngSubmit)="SubmitForm()">
              <ng-template matStepLabel>Controls & Options</ng-template>

              <div class="row">
                <div class="col-6">
                  <!-- Louvre width 89mm / 127mm -->
                  <mat-form-field class="full-width">
                    <mat-label>Louvre width</mat-label>
                    <mat-select (focus)="ControlEnter('lou')" (mouseenter)="ControlEnter('lou')"
                      (mouseleave)="ControlReset()" formControlName="louvreWidth">
                      <mat-option *ngFor="let item of louvreWidths" [value]="item.key">
                        {{ item.value }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-6">

                  <!-- Controls Left / Right -->
                  <mat-form-field class="full-width">
                    <mat-label>Controls</mat-label>
                    <mat-select (focus)="ControlEnter('con')" (mouseenter)="ControlEnter('con')"
                      (mouseleave)="ControlReset()" [disabled]="isTrackoFrcedIn2Halves || isTrackIn2"
                      formControlName="controls">
                      <mat-option *ngFor="let item of controls" [value]="item.key">
                        {{ item.value }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="col-6">
                  <!-- Draw Oneway / Split -->
                  <mat-form-field class="full-width">
                    <mat-label>Draw</mat-label>
                    <mat-select (focus)="ControlEnter('draw')" (mouseenter)="ControlEnter('draw')"
                      (mouseleave)="ControlReset()" [disabled]="isTrackoFrcedIn2Halves || isTrackIn2"
                      formControlName="draw">
                      <mat-option *ngFor="let item of draws" [value]="item.key">
                        {{ item.value }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="col-6">
                  <!-- Colour White / Brown -->
                  <mat-form-field class="full-width">
                    <mat-label>Rail colour</mat-label>
                    <mat-select (focus)="ControlEnter('col')" (mouseenter)="ControlEnter('col')"
                      (mouseleave)="ControlReset()" [disabled]="!isVogue" formControlName="railColour">
                      <mat-option *ngFor="let item of railColours" [value]="item.key">
                        {{ item.value }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="col-6">
                  <!-- Track in 2 halves Yes / No -->
                  <mat-form-field class="full-width">
                    <mat-label>Track in 2 halves</mat-label>
                    <mat-select (focus)="ControlEnter('in2')" (mouseenter)="ControlEnter('in2')"
                      (mouseleave)="ControlReset()" [disabled]="isTrackoFrcedIn2Halves" formControlName="in2halves"
                      (selectionChange)="ToggleIn2Halves($event)">
                      <mat-option *ngFor="let item of in2halves" [value]="item.key">
                        {{ item.value }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="col-6">
                  <!-- Wand (Vogue) Yes / No -->
                  <mat-form-field *ngIf="isVogue" class="full-width">
                    <mat-label>Wand</mat-label>
                    <mat-select (focus)="ControlEnter('wand')" [disabled]="!suitableForWand"
                      (mouseenter)="ControlEnter('wand')" (mouseleave)="ControlReset()" [disable]="!isVogue"
                      formControlName="wand">
                      <mat-option *ngFor="let item of wands" [value]="item.key">
                        {{ item.value }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="col-6">
                  <!-- Location -->
                  <mat-form-field class="full-width p-1">
                    <input autofocus type="text" (focus)="ControlEnter('loc')" (mouseenter)="ControlEnter('loc')"
                      (mouseleave)="ControlReset()" formControlName="location" matInput
                      placeholder="Reference/Location" />
                  </mat-form-field>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <button type="button" class="btn btn-warning" (click)="ControlEnter('wand', true)" matStepperPrevious>
                    Back
                  </button>
                </div>
                <div class="col" *ngIf="!viewOnly">
                  <input type="submit" class="btn btn-success"
                    value="{{IsAddingNew ? 'Add this headrail' : 'update this headrail'}}" />
                </div>
              </div>

            </form>
          </mat-step>
        </mat-stepper>
      </div>
    </div>
    <div class="col-4">
      <!-- Info panel -->
      <div class="info-panel">
        <div [ngSwitch]="controlWithFocus">
          <div *ngSwitchCase="'loc'">
            <h2>Reference</h2>
            <b>Room location</b>
            <p>Lounge, Bed1, Dining etc</p>
            <h3>- OR -</h3>
            <b>Customer reference</b>
            <p>Smith B12345</p>
            <p>Useful if putting multiple customers on one Bend It Order</p>
          </div>

          <div *ngSwitchCase="'sha'">
            <h2 class="mt-0 mb-0">Shape</h2>
            <div [ngSwitch]="selectedShape">
              <div *ngSwitchCase="'Bow Bay'">
                <h3 class="mb-0">Bow Bay</h3>
                <p>Multi segment bay of 4 or more windows</p>
                <p>Headrail made with a natural curve</p>
                <img class="img-fluid" [src]="'./assets/images/curved-rail-7.jpg'" />
              </div>
              <div *ngSwitchCase="'Splay Bay'">
                <h3 class="mb-0">Splay Bay</h3>
                <p>3 peice bay with smaller returns at around 45 degrees</p>
                <p>Headrail made with 2 bends</p>
                <img class="img-fluid" [src]="'./assets/images/splay-bay-window.jpg'" />
              </div>
              <div *ngSwitchCase="'Hockey Stick Left'">
                <h3 class="mb-0">Hockey Stick Left</h3>
                <p>
                  Generally a longer front section with smaller return on the
                  left
                </p>
                <p>Headrail has only one bend</p>
                <img class="img-fluid" [src]="'./assets/images/hockey-stick-left-window.jpg'" />
              </div>
              <div *ngSwitchCase="'Hockey Stick Right'">
                <h3 class="mb-0">Hockey Stick Right</h3>
                <p>
                  Generally a longer front section with smaller return on the
                  right
                </p>
                <p>Headrail has only one bend</p>
                <img class="img-fluid" [src]="'./assets/images/hockey-stick-right-window.jpg'" />
              </div>
            </div>
          </div>

          <div *ngSwitchCase="'sys'">
            <h2 class="mt-0 mb-0">System</h2>
            <div *ngIf="designForm.get('system').value == 'Vogue'">
              <h3 class="mb-0">Vogue</h3>
              <img class="img-fluid" [src]="'./assets/images/vogue.jpg'" />
              <p>Louvolite Vogue system - 5 colours</p>
            </div>

            <div *ngIf="designForm.get('system').value == 'Benthin'">
              <h3 class="mb-0">Benthin</h3>
              <img class="img-fluid" [src]="'./assets/images/benthin.jpg'" />
              <p>Benthin system available in white only</p>
              <p>Much Smoother operation</p>
            </div>
          </div>

          <div *ngSwitchCase="'sizeFrom'">
            <h2 class="mt-0 mb-0">Size From</h2>
            <p>Select Recess or Blind size</p>
            <div *ngIf="sizesForm.get('sizeFrom').value == 'Recess'">
              <h3 class="mb-0">Recess Size</h3>
              <!-- <img class="img-fluid" [src]="'./assets/images/vogue.jpg'" /> -->
              <p>Recess size means that the sizes you supply are tight into the edges of the recess and all corners from
                point to point.</p>
              <p>We will make a small allowance, normmally 10mm for the track to comfortably fit within the recess</p>
            </div>

            <div *ngIf="sizesForm.get('sizeFrom').value == 'Blind'">
              <h3 class="mb-0">Blind Size</h3>
              <!-- <img class="img-fluid" [src]="'./assets/images/benthin.jpg'" /> -->
              <p>Blind size means that you have made any neccessary allowances for the blind to fit within the recess.
              </p>
              <p>We will NOT make any further allowances to the sizes you supply.</p>
            </div>
          </div>

          <div *ngSwitchCase="'rev'">
            <!-- possibly put a switch here for each reveal type -->
            <h2>Reveal</h2>
            <h3 class="mb-0">With Reveal</h3>
            <!-- <img class="img-fluid" [src]="'./assets/images/reveal.jpg'" /> -->
            <p>
              Most situations will have a reveal, we'll make the curve just
              short of the overall width and the blind will protrude beyond the
              baseline.
            </p>
            <h3 class="mb-0">Without Reveal</h3>
            <!-- <img class="img-fluid" [src]="'./assets/images/no-reveal.jpg'" /> -->
            <p>
              With no reveal the headrail will be slightly shorter and not
              protrude beyond the baseline, this will create a bigger gap
              between blind and window at the far edges!
            </p>
          </div>

          <div *ngSwitchCase="'dimA'">
            <h2>Dimension A</h2>
            <b class="pb-2 d-inline-block">MM's Only</b>
            <div [ngSwitch]="selectedShape">
              <div *ngSwitchCase="'Bow Bay'">
                <p>
                  The width from where the edge of the windows meet the reveals
                </p>
                <p>
                  Measure between the back edge of the reveal to the
                  corresponding place on the other side
                </p>
              </div>
              <div *ngSwitchCase="'Splay Bay'">
                <p>The width of the front window.</p>
                <p>Measure tight into the corners</p>
              </div>
              <div *ngSwitchCase="'Hockey Stick Left'">
                <p>The width of the main fron window.</p>
                <p>
                  Measure tight into the corner of the adjoining window and the
                  reveal on the right hand side
                </p>
              </div>
              <div *ngSwitchCase="'Hockey Stick Right'">
                <p>The width of the main fron window.</p>
                <p>
                  Measure tight into the corner of the adjoining window and the
                  reveal on the left hand side
                </p>
              </div>
            </div>
          </div>

          <div *ngSwitchCase="'dimB'">
            <h2>Dimension B</h2>
            <b class="pb-2 d-inline-block">MM's Only</b>
            <div [ngSwitch]="selectedShape">
              <div *ngSwitchCase="'Bow Bay'">
                <p>
                  The depth of the bay needs to be half or less than half of the
                  width, this is so that the depth is not more than the radius
                  of the curve
                </p>
                <p>
                  Measure from the front face of the bay to the line across the
                  back of the bay from the edges of the reveal
                </p>
              </div>
              <div *ngSwitchCase="'Splay Bay'">
                <p>Left hand side return</p>
                <p>
                  Measure from the reveal to the corner where it joins the front
                  window
                </p>
              </div>
              <div *ngSwitchCase="'Hockey Stick Left'">
                <p>Left hand side return</p>
                <p>
                  Measure from the reveal to the corner where it joins the front
                  window
                </p>
              </div>
              <div *ngSwitchCase="'Hockey Stick Right'">
                <p>Right hand side return</p>
                <p>
                  Measure from the reveal to the corner where it joins the front
                  window
                </p>
              </div>
            </div>
          </div>

          <div *ngSwitchCase="'dimC'">
            <h2>Dimension C</h2>
            <b class="pb-2 d-inline-block">MM's Only</b>
            <div [ngSwitch]="selectedShape">
              <div *ngSwitchCase="'Splay Bay'">
                <p>Right hand side return</p>
                <p>
                  Measure from the reveal to the corner where it joins the front
                  window
                </p>
              </div>
              <div *ngSwitchCase="'Hockey Stick Left'">
                <p>Diagonal mesaurement</p>
                <p>
                  Measure from where the window meets the reveal on both sides
                </p>
              </div>
              <div *ngSwitchCase="'Hockey Stick Right'">
                <p>Diagonal mesaurement</p>
                <p>
                  Measure from where the window meets the reveal on both sides
                </p>
              </div>
            </div>
          </div>

          <div *ngSwitchCase="'dimD'">
            <h2>Dimension D</h2>
            <b class="pb-2 d-inline-block">MM's Only</b>
            <p>Reveal to reveal mesurement across the back of the bay</p>
            <p>Measure from where the window meets the reveal on both sides</p>
          </div>

          <div *ngSwitchCase="'hei'">
            <h2>Operating Height</h2>
            <b>MM's Only</b>
            <p>This is the neasurement from the top of the window/door to the floor.</p>
            <p>For child-safety reasons we will now make sure the chain/cord are no lower than 1500mm from the floor.
            </p>
            <p>Therefore the cord/wand length will be this meaurement less 1500mm</p>
          </div>

          <div *ngSwitchCase="'lou'">
            <h2>Louvre / Slat width</h2>
            <h3>89mm (3.5")</h3>
            <p>
              This is the most popular choice and will give you more slats
              across the width of the blind
            </p>
            <br />
            <h3>127mm (5")</h3>
            <p>
              With less slats compared to 89mm you'll get slightly less gaps and
              more privacy
            </p>
          </div>

          <div *ngSwitchCase="'con'">
            <h2>Controls</h2>
            <p>Controls can be on the left or the right</p>
            <p>
              If the track is in 2 halves due to size restrictions or has been
              selected then the Left rail with have controls on the left and the
              right side will have controls on the right
            </p>
            <div *ngIf="selectedIn2halves === 'Yes'">
              <p class="text-danger">
                This headrail will be made in 2 halves and therefore the
                controls must be L R
              </p>
            </div>
          </div>

          <div *ngSwitchCase="'col'">
            <h2>Headrail Colour</h2>
            <div [ngSwitch]="selectedSystem">
              <div *ngSwitchCase="'Vogue'">
                <h3>Vogue - colour options</h3>
                <ul>
                  <li>White</li>
                  <li>Brown</li>
                  <li>Silver</li>
                  <li>Black</li>
                  <li>Champagne</li>
                  <li>Anthracite</li>
                </ul>
                <p>Benthin system is only available in white</p>
              </div>

              <div *ngSwitchCase="'Benthin'">
                <h3>Benthin - colour options</h3>
                <p>White is the only option for the Benthin system</p>
                <hr>
                <p>The Vogue system is available in these colours</p>
                <ul>
                  <li>White</li>
                  <li>Brown</li>
                  <li>Silver</li>
                  <li>Black</li>
                  <li>Champagne</li>
                  <li>Anthracite</li>
                </ul>
              </div>
            </div>
          </div>

          <div *ngSwitchCase="'draw'">
            <h2>Draw</h2>
            <p>
              The slats can be drawn to the left or the right and is independant
              of the control side i.e. you can have the controls on the right
              and draw to the left
            </p>
            <p>
              If the track is in 2 halves due to size restrictions or has been
              selected then the Left rail will draw to the left and the right
              rail will draw to the right
            </p>
            <div *ngIf="selectedIn2halves === 'Yes'">
              <p class="text-danger">
                This headrail will be made in 2 halves and there the draw
                selection must be L R
              </p>
            </div>
          </div>

          <div *ngSwitchCase="'in2'">
            <h2>Track in 2 halves</h2>
            <p>If the track is too big it will be made in 2 halves</p>
            <p>Vogue: max width overall is 5000mm</p>
            <p>Benthin: max width overall is 6000mm</p>
            <p>
              Under these sizes you can choose to have the headrail made in 2,
              for instance to make it more manageable
            </p>
            <p>
              Controls and Draw will be LL on the Left rail and RR on the Right
              rail
            </p>
            <div *ngIf="selectedIn2halves === 'Yes'">
              <p class="text-danger">
                This headrail will be made in 2 halves and there the Draw &
                Control selections must be L R
              </p>
            </div>
          </div>

          <div *ngSwitchCase="'wand'">
            <h2>Wand</h2>
            <p>Replaces cords and chain with a wand, simply rotate wand to turn slats, pull wand left and right to draw
              slats</p>
            <p><b>Note:</b></p>
            <p>Only available on Vogue system and shallow Bow Bays</p>
            <p>Only suitable for shallow Bow Bays with a depth no greater than 350mm</p>
            <p>Not available on Hockey Stick or Splay Bay shapes</p>
            <p>The best option for child safety</p>
          </div>

          <div *ngSwitchDefault>
            Start by picking a shape followed by system
            and reveal options
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- templates -->
  <!-- <div [ngSwitch]="shape">
    <div *ngSwitchCase="'Bow Bay'">
    </div>
    <div *ngSwitchCase="'Splay Bay'">
    </div>
    <div *ngSwitchCase="'Hockey Stick Left'">
    </div>
    <div *ngSwitchCase="'Hockey Stick Right'">
    </div>
  </div>

  <div [ngSwitch]="selectedSystem">
    <div *ngSwitchCase="'Vogue'">
    </div>

    <div *ngSwitchCase="'Benthin'">
    </div>
  </div> -->

  <div class="row mt-2 mb-2" *ngIf="shape">
    <div class="col-12">
      <!-- preview panel-->
      <div class="preview-panel">
        <div [ngSwitch]="shape.key">
          <div *ngSwitchCase="'Bow Bay'">
            <!-- <h3 class="mb-0">Bow Bay</h3> -->
            <app-curve-preview [shape]="selectedShape" [reveal]="selectedReveal" [measurement]="selectedMeasurement">
              <app-bow-bay></app-bow-bay>
            </app-curve-preview>
          </div>
          <div *ngSwitchCase="'Splay Bay'">
            <!-- <h3 class="mb-0">Splay Bay</h3> -->
            <app-curve-preview [shape]="selectedShape" [reveal]="selectedReveal" [measurement]="selectedMeasurement">
              <app-splay-bay></app-splay-bay>
            </app-curve-preview>
          </div>
          <div *ngSwitchCase="'Hockey Stick Left'">
            <!-- <h3 class="mb-0">Hockey Stick Left</h3> -->
            <app-curve-preview [shape]="selectedShape" [reveal]="selectedReveal" [measurement]="selectedMeasurement">
              <app-hockey-left></app-hockey-left>
            </app-curve-preview>
          </div>
          <div *ngSwitchCase="'Hockey Stick Right'">
            <!-- <h3 class="mb-0">Hockey Stick Right</h3> -->
            <app-curve-preview [shape]="selectedShape" [reveal]="selectedReveal" [measurement]="selectedMeasurement">
              <app-hockey-right></app-hockey-right>
            </app-curve-preview>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>