import { Component, Input, OnInit, Sanitizer, ViewChild } from '@angular/core';
import { CheckBoxItem } from '../../models/CheckBoxItem';
import { IGallery } from '../../models/IGallery';
import { GalleryService } from '../../services/gallery.service';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {

  // ALL / Initial options
 // @Input() categories: string[] = [];
  @Input() types: string[] = [];
  @Input() onlyPerfectFit: boolean;
  @Input() onlyIntu: boolean;

  // ALL Items as returned from initial call to GalleryService
  @Input() galleryIems: IGallery[] = [];
  @Input() filteredGalleryIems: IGallery[] = [];


  allCats: CheckBoxItem[] = [];
  catsVisible: boolean;
  allTypes: CheckBoxItem[] = [];
  typesVisible: boolean;
  systemSelection = 'Any';

  perfectFitVisible: boolean;
  intuVisible: boolean;

  showFullImage: boolean;
  selectedItem: IGallery;

  constructor(private gallerService: GalleryService) { }

  ngOnInit(): void {
    this.SerachGallery();
    this.ResetFilters();
    this.GetCategories();
    this.GetTypes();
    this.SetPerfectFitVisible();
    this.SetIntuVisible();
  }

  // Only set visible if some are Intu and some are not Intu i.e. there is a choice
  SetIntuVisible() {
    this.intuVisible = this.galleryIems.some(w => w.isIntu) && this.galleryIems.some(w => !w.isIntu);
  }

  SetPerfectFitVisible() {
    this.perfectFitVisible = this.galleryIems.some(w => w.isPerfectFit) && this.galleryIems.some(w => !w.isPerfectFit);
  }

  UpdateSystem(){
    this.onlyIntu = this.systemSelection === 'Intu';
    this.onlyPerfectFit = this.systemSelection === 'Perfect';
    this.UpdateGallery();
  }

  SerachGallery()
  {
    // tslint:disable-next-line: max-line-length
    this.galleryIems = this.gallerService.GetGallery(this.allCats.filter(w => w.isSelected).map(m => m.name), this.types, this.onlyPerfectFit, this.onlyIntu);
    this.filteredGalleryIems = this.galleryIems;
  }

  ResetFilters(): void {
    this.filteredGalleryIems = this.galleryIems;
  //  this.UpdateGallery();
  }

  filterGallery()
  {
    this.filteredGalleryIems = this.galleryIems.filter(w => w.isIntu);
  }


  GetCategories() {
    this.galleryIems.forEach(item => {
      item.category.forEach(cat => {
        if (!this.allCats.some(w => w.name === cat)) {
          this.allCats.push( {name: cat, isSelected: true});
        }
      });
    });

    this.catsVisible = this.allCats.length > 1;
  }

  GetTypes() {
    this.galleryIems.forEach(item => {
        if (!this.allTypes.some(w => w.name === item.type)) {
          this.allTypes.push( {name: item.type, isSelected: true});
        }
    });

    this.typesVisible = this.allTypes.length > 1;
  }

  UpdateTypeSelection($event: CheckBoxItem[]) {
      this.allTypes = $event;
      this.UpdateGallery();

  }

  UpdateCategorySelection($event: CheckBoxItem[]) {
      this.allCats = $event;
      this.UpdateGallery();
  }

  UpdateGallery() {
    this.filteredGalleryIems = this.galleryIems
      .filter(w => !this.onlyIntu || w.isIntu === this.onlyIntu)
      .filter(w => !this.onlyPerfectFit || w.isPerfectFit === this.onlyPerfectFit)
      .filter(w => this.allTypes.some(s => s.name === w.type && s.isSelected))
      .filter(w => this.allCats.some(s => s.isSelected && w.category.some(a => a === s.name)));
  }

  GalleryItemClicked($event: IGallery) {
    this.selectedItem = $event;
  }

  GalleryClosed() {
    this.selectedItem = undefined;
  }
}
