<div class="container">
  <div class="row">
    <div class="col-12">
      <!-- Steppers -->
      <div class="stepper-container position-relative">
        <mat-stepper linear #stepper (selectionChange)="SelectionChanged($event)" class="text-white">
          <mat-step [stepControl]="designForm" [editable]="true">
            <form [formGroup]="designForm">
              <!-- ORDER LINE DETAILS-->

              <ng-template matStepLabel>Shape</ng-template>

              <!-- Shape -->
              <div class="full-width">
                <mat-label>Shape</mat-label>
                <app-item-picker-image (gotFocus)="ShapeFocus('sha', $event)" 
                  [selections]="shapes" [selected]="shape" [showLabel]="false" (selectedChanged)="ShapeSelected($event)">
                </app-item-picker-image>
              </div>

            
              <div class="row">
                <div class="col">
                  <button type="button" class="btn btn-success" (click)="ControlEnter('dimAB', true, designForm.valid)"
                    matStepperNext>
                    Next
                  </button>
                </div>
              </div>
            </form>
          </mat-step>

          <mat-step [stepControl]="sizesForm" [editable]="true">
            <form [formGroup]="sizesForm">
              <ng-template matStepLabel>Sizes</ng-template>
              <div class="row">


                <div class="col-3 control90" *ngFor="let ctrl of shape?.measurements">
                    <mat-form-field>
                      <input type="number" (focus)="SizeFocus(ctrl)" 
                      formControlName="{{'dimension'+ctrl}}" matInput
                      placeholder="{{'Dimension '+ctrl}}" />
                    </mat-form-field>
                </div>

              </div>

              <div class="row">
                <div class="col">
                  <button type="button" class="btn btn-warning" (click)="ControlEnter('mot')"
                    matStepperPrevious>Back</button>
                </div>
                <div class="col">
                  <button type="button" class="btn btn-success" (click)="ControlEnter('mot', true, sizesForm.valid)"
                    matStepperNext>Next</button>
                </div>
              </div>

            </form>
          </mat-step>

          <mat-step [stepControl]="controlsForm" [editable]="true">
            <form [formGroup]="controlsForm" (ngSubmit)="SubmitForm()">
              <ng-template matStepLabel>Controls & Options</ng-template>

              <div class="row">

                <div class="col-6">
                  <!-- System -->
              <mat-form-field class="full-width p-1">
                <mat-label>Rail Type</mat-label>
                <mat-select (focus)="ControlEnter('rail')"  formControlName="railType">
                  <mat-option *ngFor="let item of railTypes" [value]="item.key">
                    {{ item.value }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
                </div>

              <div class="col-6">
                <!-- Brackets -->
                <mat-form-field class="full-width p-1">
                  <mat-label>Brackets</mat-label>
                  <mat-select (focus)="ControlEnter('bra')" formControlName="brackets">
                    <mat-option *ngFor="let item of brackets" [value]="item.key">
                      {{ item.value }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="col-6">
              <!-- Motor -->
              <mat-form-field class="full-width p-1">
                <mat-label>Motor</mat-label>
                <mat-select (focus)="ControlEnter('mot')"  formControlName="motor">
                  <mat-option *ngFor="let item of motors" [value]="item.key">
                    {{ item.value }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              </div>

              <div class="col-6">
              <!-- Motor Location-->
              <mat-form-field class="full-width p-1">
                <mat-label>Motor Location</mat-label>
                <mat-select (focus)="ControlEnter('motl')" formControlName="motorLocation">
                  <mat-option *ngFor="let item of motorLocations" [value]="item.key">
                    {{ item.value }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              </div>

                <div class="col-6">
                  <!-- Openings -->
                  <mat-form-field class="full-width">
                    <mat-label>Opening</mat-label>
                    <mat-select (focus)="ControlEnter('open')"  formControlName="opening">
                      <mat-option *ngFor="let item of openings" [value]="item.key">
                        {{ item.value }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="col-6">
                  <!-- Location -->
                  <mat-form-field class="full-width p-1">
                    <input autofocus type="text" (focus)="ControlEnter('loc')"  formControlName="location" matInput
                      placeholder="Reference/Location" />
                  </mat-form-field>
                </div>

                  <!-- <div class="col-6" *ngFor="let extra of extras">
                    <mat-form-field class="full-width p-1">
                      <input autofocus type="number" (focus)="ControlEnter(extra.control)"
                        (mouseenter)="ControlEnter('extra.control')" (mouseleave)="ControlReset()"
                        formControlName="{{extra.control}}" matInput placeholder="{{extra.name}}" />
                    </mat-form-field>
                  </div> -->
              </div>

              <div class="row">
                <div class="col">
                  <button type="button" class="btn btn-warning" (click)="ControlEnter('wand', true)" matStepperPrevious>
                    Back
                  </button>
                </div>
                <div class="col" *ngIf="!viewOnly">
                  <input type="submit" class="btn btn-success"
                    value="{{IsAddingNew ? 'Add this Curtain Track' : 'update this Curtain Track'}}" />
                </div>
              </div>

            </form>
          </mat-step>
        </mat-stepper>
      </div>
    </div>
    

  <div class="row mt-2 mb-2" *ngIf="shape">
    <div class="col-12">
      <!-- preview panel-->
      <div class="preview-panel">

        <app-curve-preview [shape]="shape.control" [measurements]="shape.measurements" [measurement]="controlWithFocus">
          <div [ngSwitch]="shape.control">
            <div *ngSwitchCase="'Straight'">
              <app-curtain-straight></app-curtain-straight>
            </div>
            <div *ngSwitchCase="'Curved'">
              <app-curtain-curved></app-curtain-curved>
            </div>

            <div *ngSwitchCase="'LShaped'">
              <app-curtain-l-shaped></app-curtain-l-shaped>
            </div>

            <div *ngSwitchCase="'UShaped'">
              <app-curtain-u-shaped></app-curtain-u-shaped>
            </div>

            <div *ngSwitchCase="'Angular'">
              <app-curtain-angular></app-curtain-angular>
            </div>

            <div *ngSwitchCase="'Angular3'">
              <app-curtain-angular-three-sided></app-curtain-angular-three-sided>
            </div>

            <div *ngSwitchCase="'Angular4'">
              <app-curtain-angular-four-sided></app-curtain-angular-four-sided>
            </div>

            <div *ngSwitchCase="'Angular5'">
              <app-curtain-angular-five-sided></app-curtain-angular-five-sided>
            </div>

            <div *ngSwitchCase="'Angular6'">
              <app-curtain-angular-six-sided></app-curtain-angular-six-sided>
            </div>


          </div>
        </app-curve-preview>
       
      </div>
    </div>
  </div>
</div>