import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Contact, IContact } from "src/app/models/IContact";
import { SubscriptionService } from "src/app/services/subscription.service";
import { saveAs } from "file-saver";

@Component({
  selector: "app-document-download",
  templateUrl: "./document-download.component.html",
  styleUrls: ["./document-download.component.scss"],
})
export class DocumentDownloadComponent implements OnInit {
  files = [];

  constructor() {}

  ngOnInit(): void {
    this.files = [
      {
        displayName: "Angular V2 Rail Order Form",
        document: "6 Angular V2 Rail Order Form.pdf",
      },
      {
        displayName: "Angular Rail Order Form",
        document: "5 Angular Rail Order Form.pdf",
      },
      {
        displayName: "U Shaped Rail Order Form",
        document: "4 U Shaped Rail Order Form.pdf",
      },
      {
        displayName: "Continuous Curved Rail Order Form",
        document: "3 Continuous Curved Rail Order Form.pdf",
      },
      {
        displayName: "L Shaped Rail Order Form",
        document: "2 L Shaped Rail Order Form 2.pdf",
      },
      {
        displayName: "Straight Rail Order Form",
        document: "1 Straight Rail Order Form 1.pdf",
      },
    ];
  }

  downloadFile(file) {
    saveAs.saveAs("https://public-pdf-documents.s3.eu-west-2.amazonaws.com/" + file.document, file.displayName, {type: "application/pdf"});
  }
}
