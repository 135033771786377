import { Component, OnInit, ViewChild } from "@angular/core";
import { GoogleMap } from "@angular/google-maps";
import { Retailer } from "src/app/models/retailers";
import { DataService } from "src/app/services/data.service";

@Component({
  selector: "app-retailers",
  templateUrl: "./retailers.component.html",
  styleUrls: ["./retailers.component.scss"],
})
export class RetailersComponent implements OnInit {
  zoom = 12;
  retailers: Retailer[];
  center: google.maps.LatLngLiteral;
  options: google.maps.MapOptions = {
    mapTypeId: "hybrid",
    zoomControl: true,
    scrollwheel: true,
    disableDoubleClickZoom: false,
    maxZoom: 15,
    minZoom: 8,
  };

  bounds = new google.maps.LatLngBounds();
  markers = [];
  infoWindowContent = [];
  //  map = new google.maps.Map(document.getElementById('locations-near-you-map'), mapOptions);
  @ViewChild(GoogleMap, { static: false }) map: GoogleMap;
  // map.setTilt(45);
  constructor(private dataService: DataService) {}

  ngOnInit(): void {
    navigator.geolocation.getCurrentPosition(
      (position: GeolocationPosition) => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
      }
    );

    this.dataService.GetRetailers().subscribe((data) => {
      this.retailers = data;
    });
  }
}
