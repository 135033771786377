<div class="c-blue-dark-lt-bk">
  <div class="container">
    <div class="row">
      <div class="col text-center text-white p-3">
        <h1>Opening Times</h1>
      </div>
    </div>

    <div class="row pb-5">
      <div class="bk-white col-xs-12 col-sm-12 col-md-8 rounded z-depth-2">
        <table class="table table-striped">
          <thead>
            <tr>
              <th></th>
              <th>Open</th>
              <th>Closed</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let day of openingtimes"
              [class]="weekday === day.id ? openStateClass : ''"
            >
              <td>{{ day.day }}</td>
              <td>{{ day.open }}</td>
              <td>{{ day.closed }}</td>
              <td>
                <span>{{
                  weekday === day.id
                    ? isOpen
                      ? isClosingSoon
                        ? "CLOSING SOON"
                        : "OPEN"
                      : "CLOSED"
                    : ""
                }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="col-xs-12 col-sm-12 col-md-4">
        <div class="m-5">
          <div *ngIf="isOpen && !isClosingSoon">
            <img src="./assets/images/Open.svg" alt="open" />
          </div>
          <div *ngIf="isOpen && isClosingSoon">
            <img src="./assets/images/ClosingSoon.svg" alt="closing soon" />
          </div>
          <div *ngIf="!isOpen">
            <img src="./assets/images/Closed.svg" alt="closed" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
