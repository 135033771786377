import {AbstractControl, FormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';

export function createBhalfAValidator(): ValidatorFn {
  return (c: FormGroup): ValidationErrors | null => {

   const a = c.get('dimensionA').value as number;
   const b = c.get('dimensionB').value as number;

   if (!a || !b) { return null; }

   return b <= (a / 2) ? null : { bhalfa: true};
    // if valid, return null,
    // if invalid, return an error object (any arbitrary name), like, return { invalidEndDate: true }
  };
}
