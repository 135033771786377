import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Customer } from 'src/app/services/API.service';

@Component({
  selector: 'app-customer-table',
  templateUrl: './customer-table.component.html',
  styleUrls: ['./customer-table.component.scss']
})
export class CustomerTableComponent implements OnInit, OnChanges {

  @Input() customers: Customer[] = [];
  @Input() loading: boolean = false;
  filteredCustomers: Customer[] = [];
  search: string = "";
  Statuses: string[] = [];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @Output() selectCustomer = new EventEmitter<Customer>();
  @Output() selectCustomerOrders = new EventEmitter<Customer>();
  @Output() refreshData = new EventEmitter();

  displayedColumns: string[] = [
    "accountNumber",
    "companyName",
    "firstName",
    "lastName",
    "email",
    "select",
    "orders"
  ];
  // <th>Username</th>
  // <th>First namne</th>
  // <th>Last Name</th>
  // <th>Phone</th>
  // <th>Email</th>
  // <th>Send email invite to customer"></th>
  mcr: any;
  constructor() { }
  
  ngOnInit(): void {
    this.FilterResults();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(!this.loading) {
      this.FilterResults();
    }
  }
  
SelectCustomer(customer: Customer) {
  this.selectCustomer.emit(customer);
}

SelectCustomerOrders(customer: Customer) {
  this.selectCustomerOrders.emit(customer);    
}

RefreshData() {
  this.refreshData.emit();
}
SetFilter() {
  this.mcr = new MatTableDataSource<Customer>(this.filteredCustomers);
  this.mcr.paginator = this.paginator;

  this.mcr.filterPredicate = (data: Customer, filter: string) => {
    const searchData = JSON.stringify(data).toLowerCase();
    return (   
      (filter === "REFRESH" || searchData.includes(filter))
    );
  };

  this.refresh();
}

refresh() {
  if (
    this.search !== undefined &&
    this.search !== null &&
    this.search !== ""
  ) {
    this.mcr.filter = this.search.trim().toLowerCase();
  } else {
    this.mcr.filter = "REFRESH";
  }
}

toAccount() {
  throw new Error('Method not implemented.');
}

FilterResults() {
  if(!this.loading) {
    if (this.customers) {
      this.filteredCustomers = this.customers.sort((a,b) =>
        {
          if (a.companyName === null) return 1;
                if (b.companyName === null) return -1;
                return a.companyName.localeCompare(b.companyName);
        }
      );
    }

    if (this.customers) {
        this.SetFilter();
      }
    }
  }
}
