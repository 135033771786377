import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  Motor,
  MotorLocation,
  Opening,
  Brackets,
  RailType,
  CurtainTrack,
  CurtainShape,
  CurtainShapeModel,
  ExtrasModel,
  MotorModel,
  BracketModel,
} from "src/app/models/orders";
import { FormBuilder, Validators } from "@angular/forms";
import { Constants, KVP, KVPI } from "src/app/enums/constants";
import { OrderItem, Product } from "src/app/services/API.service";
import { StepperSelectionEvent } from "@angular/cdk/stepper/stepper";
import { createBhalfAValidator } from "../../../validators/createBhalfAValidator";
import { AuthService } from "src/app/services/auth.service";

@Component({
  selector: 'app-curtaintrack-edit',
  templateUrl: './curtaintrack-edit.component.html',
  styleUrls: ['./curtaintrack-edit.component.scss']
})
export class CurtaintrackEditComponent implements OnInit {

  curve: CurtainTrack;

  shape: CurtainShapeModel;
  shapes: CurtainShapeModel[] = Constants.curtain_shapes as CurtainShapeModel[];

  // NOT NEEDED - DELETE
  extras: ExtrasModel[] = [{
    name: "Irismo Charger",
    control: "IrismoCharger",
    price: 43.16
  },
  {
    name: "Movelite WF Charger",
    control: "MoveliteWFCharger",
    price: 11.42
  },
  {
    name: "Adapter 90 Degree",
    control: "Adapter90Degree",
    price: 3.08
  },
  {
    name: "Situo 1 Pure",
    control: "Situo1Pure",
    price: 22.24
  },
  {
    name: "Situo 5 Pure",
    control: "Situo5Pure",
    price: 29.34
  },
  {
    name: "Telis 16 Pure",
    control: "Telis16Pure",
    price: 93.67
  },
  {
    name: "Smoove Mech",
    control: "SmooveMech",
    price: 24.43
  },
  {
    name: "Smoove 4",
    control: "Smoove4",
    price: 34.15
  },
  {
    name: "Smoove 1 Pure",
    control: "Smoove1Pure",
    price: 30.1
  },
  {
    name: "Smoove 1 Black",
    control: "Smoove1Black",
    price: 30.1
  },
  {
    name: "Smoove 1 Sliver",
    control: "Smoove1Sliver",
    price: 36.44
  },
  {
    name: "Smoove 1 Pure Open Close",
    control: "Smoove1Pureopenclose",
    price: 30.1
  },
  {
    name: "Smoove 1 Black Open Close",
    control: "Smoove1Blackopenclose",
    price: 30.1
  },
  {
    name: "Smoove Frame Pure",
    control: "SmooveframePure",
    price: 2.37
  },
  {
    name: "Smoove Frame Black",
    control: "SmooveframeBlack",
    price: 7.29
  },
  {
    name: "Smoove Frame Silver Matt",
    control: "SmooveframeSilverMatt",
    price: 7.29
  },
  {
    name: "Tahoma Switch",
    control: "TahomaSwitch",
    price: 104.12
  },
  {
    name: "Connectivity kit",
    control: "Connectivitykit",
    price: 36.55
  }
  ]

  @Input() orderItem: OrderItem;
  @Input() itemNumber: number;
  @Input() orderID: string;
  @Input() viewOnly: boolean;
  @Input() product: Product;
  @Output() AddHeadrailClicked = new EventEmitter<OrderItem>();

  IsAddingNew = false;
  // isVogue = true;
  // isTrackoFrcedIn2Halves = false;
  // isTrackIn2 = false;

  price = 0;
  discount = 0;
  fullPrice = 0;

  minMMs = 1500;
  maxWidthBeforeSplitting = 5000;

  showDimAB = false;
  showDimBC = false;
  showDimCD = false;
  showDimDE = false;
  showDimEF = false;
  showDimAC = false;
  showDimAD = false;
  showDimAE = false;
  showDimAF = false;
  showDimBF = false;
  showDimCF = false;
  showDimDF = false;
  showDimY = false;
  showDimBD = false;
  showDimCE = false;
  showDimBE = false;
  showDimAG = false;
  showDimBG = false;
  showDimCG = false;
  showDimDG = false;
  showDimEG = false;
  showDimFG = false;

  controlWithFocus = "";
  savedControlWithFocus = "";
  selectedMeasurement = "";

  get selectedShape() {
    return this.designForm.get("shape").value;
  }

  get selectedRailType() {
    return this.controlsForm.get("railTypes").value;
  }

  get selectedMotor() {
    return this.designForm.get("motor").value;
  }
  get selectedMotorLocation() {
    return this.controlsForm.get("motorLocation").value;
  }

  get selectedOpening() {
    return this.controlsForm.get("openings").value;
  }

  get selectedBrackets() {
    return this.designForm.get("brackets").value;
  }

  // DDLS
  railTypes: KVP[] = this.convertEnumToKVP(RailType);
  motors: KVP[] = this.convertEnumToKVP(Motor);
  motorLocations: KVP[] = this.convertEnumToKVP(MotorLocation);
  openings: KVP[] = this.convertEnumToKVP(Opening);
  brackets: KVP[] = this.convertEnumToKVP(Brackets);

  designForm = this.fb.group({
    id: undefined,
    shape: [undefined, Validators.required],
    // Order Item elements
    oid: undefined,
    quantity: 1,
    price: this.price,
    productID: "",
    orderID: "",
    offerID: "",
    name: "",
  });

  sizesForm = this.fb.group({
    dimensionAB: ["", [Validators.required]],
    dimensionBC: ["", [Validators.required]],
    dimensionCD: ["", [Validators.required]],
    dimensionDE: ["", [Validators.required]],
    dimensionEF: ["", [Validators.required]],
    dimensionAC: ["", [Validators.required]],
    dimensionAD: ["", [Validators.required]],
    dimensionAE: ["", [Validators.required]],
    dimensionAF: ["", [Validators.required]],
    dimensionBF: ["", [Validators.required]],
    dimensionCF: ["", [Validators.required]],
    dimensionDF: ["", [Validators.required]],
    dimensionY: ["", [Validators.required]],
    dimensionBD: ["", [Validators.required]],
    dimensionCE: ["", [Validators.required]],
    dimensionBE: ["", [Validators.required]],
    dimensionAG: ["", [Validators.required]],
    dimensionBG: ["", [Validators.required]],
    dimensionCG: ["", [Validators.required]],
    dimensionDG: ["", [Validators.required]],
    dimensionEG: ["", [Validators.required]],
    dimensionFG: ["", [Validators.required]]
  });

  controlsForm = this.fb.group({
    railType: ["", Validators.required],
    motor: [0, Validators.required],
    motorLocation: [0, Validators.required],
    opening: ["", [Validators.required]],
    brackets: ["", [Validators.required]],
    location: ["", [Validators.required]]
  });

  constructor(private fb: FormBuilder, private authService: AuthService) { }

  convertEnumToKVP(myenum): KVP[] {
    let kvps: KVP[] = [];
    for (const kvpKey in myenum) {
      if (!isNaN(Number(kvpKey))) {
        kvps.push({ key: myenum[kvpKey], value: myenum[kvpKey] } as KVP);
      }
    }
    return kvps;
  }

  ngOnInit(): void {
    if (this.orderItem !== undefined) {
      this.curve = JSON.parse(this.orderItem.customerInputs) as CurtainTrack;
    }
    this.resetNewItemToOriginal();

    if (!!this.viewOnly) {
      this.designForm.disable();
      this.sizesForm.disable();
      this.controlsForm.disable();
    }

    if (this.product !== undefined) {
      // the selected product is available to pre-select the options for shap and system
      this.SetShapeFromProductId(this.product.id);
    }
  }

  SetShapeFromProductId(id: string) {
    const newShape = this.shapes.find(s => s.id === id);
    this.ShapeSelected(newShape);
  }

  ShapeEnter(newShape: CurtainShapeModel) {
    this.ShapeSelected(newShape);
  }

  ShapeSelected(newShape: CurtainShapeModel) {
    this.shape = newShape;
    this.designForm.patchValue({ shape: newShape.name });
    this.designForm.updateValueAndValidity();
  }

  ShapeFocus(newFocus: string, newShape: CurtainShapeModel) {
    this.controlWithFocus = newFocus;
    this.SetShapeFromProductId(newShape.id);
  }

  ControlEnter(newFocus: string, checkFormValid: boolean = false, isFormValid: boolean = false) {
    if (checkFormValid && !isFormValid) {
      return;
    }

    this.controlWithFocus = 'dim' + newFocus;
  }

  SizeFocus(control: string) {
    this.controlWithFocus = 'dim' + control;
  }


  ShapeReset() {
    // this.shape = this.selectedShape;
    this.shape = this.shapes.find(s => s.name === this.selectedShape);
  }

  resetNewItemToOriginal() {
    // set the temp to the orginal
    this.formSetValue();
  }

  formSetValue() {
    if (this.orderItem !== undefined) {
      this.IsAddingNew = false;
      this.designForm.reset({
        id: this.curve.id,
        shape: this.curve.shape,
        // Order Item elements
        oid: this.orderItem.id,
        quantity: this.orderItem.quantity,
        productID: this.orderItem.productID,
        orderID: this.orderItem.orderID,
        offerID: this.orderItem.offerID,
        price: this.orderItem.price,
        name: this.orderItem.name
      });
      this.ShapeEnter(this.shapes.find(w => w.name === this.curve.shape.toString()));
      this.sizesForm.reset({
        dimensionAB: this.curve.dimensionAB,
        dimensionBC: this.curve.dimensionBC,
        dimensionCD: this.curve.dimensionCD,
        dimensionDE: this.curve.dimensionDE,
        dimensionEF: this.curve.dimensionEF,
        dimensionAC: this.curve.dimensionAC,
        dimensionAD: this.curve.dimensionAD,
        dimensionAE: this.curve.dimensionAE,
        dimensionAF: this.curve.dimensionAF,
        dimensionBF: this.curve.dimensionBF,
        dimensionCF: this.curve.dimensionCF,
        dimensionDF: this.curve.dimensionDF,
        dimensionY: this.curve.dimensionY,
        dimensionBD: this.curve.dimensionY,
        dimensionCE: this.curve.dimensionY,
        dimensionBE: this.curve.dimensionY,
        dimensionAG: this.curve.dimensionAG,
        dimensionBG: this.curve.dimensionBG,
        dimensionCG: this.curve.dimensionCG,
        dimensionDG: this.curve.dimensionDG,
        dimensionEG: this.curve.dimensionEG,
        dimensionFG: this.curve.dimensionFG,
      });

      this.controlsForm.reset({
        railType: this.curve.railType,
        motor: this.curve.motor,
        motorLocation: this.curve.motorLocation,
        opening: this.curve.opening,
        brackets: this.curve.brackets,
        location: this.curve.location
      });
    } else {
      this.IsAddingNew = true;
      // set defaults
      this.designForm.reset({
        id: undefined,
        shape: "",

        // Order Item elements
        oid: undefined,
        quantity: 1,
        price: this.price,
        productID: "",
        orderID: this.orderID,
        offerID: "test",
        name: "not used",
      });

      this.sizesForm.reset({
        dimensionAB: undefined,
        dimensionBC: undefined,
        dimensionCD: undefined,
        dimensionDE: undefined,
        dimensionEF: undefined,
        dimensionAC: undefined,
        dimensionAD: undefined,
        dimensionAE: undefined,
        dimensionAF: undefined,
        dimensionBF: undefined,
        dimensionCF: undefined,
        dimensionDF: undefined,
        dimensionY: undefined,
        dimensionBD: undefined,
        dimensionCE: undefined,
        dimensionBE: undefined

      });

      this.controlsForm.reset({
        railType: "",
        motor: "",
        motorLocation: "",
        opening: undefined,
        brackets: undefined,
        location: undefined
      });
    }
  }

  SubmitForm() {
    if (
      this.designForm.valid &&
      this.sizesForm.valid &&
      this.controlsForm.valid
    ) {
      const orderItem = Object.assign(
        {},
        this.designForm.value,
        this.sizesForm.value,
        this.controlsForm.value
      );
      this.curve = Object.assign({}, orderItem);
      this.curve.itemNumber = this.itemNumber;

      this.CalculatePrice();
      if (this.orderItem === undefined) {
        this.orderItem = {} as OrderItem;
      }
      // localStorage.setItem('defaultSystem', this.curve.system.toString());
      // this.orderItem.name = this.curve..location;
      this.orderItem.id = orderItem.oid;
      this.orderItem.name = orderItem.name;
      this.orderItem.customerInputs = JSON.stringify(this.curve);
      this.orderItem.quantity = orderItem.quantity;
      this.orderItem.orderID = orderItem.orderID;
      this.orderItem.productID = this.shape.id;
      this.orderItem.offerID = orderItem.offerID;
      this.orderItem.price = this.price;
      this.orderItem.discount = this.discount;
      this.orderItem.fullPrice = this.fullPrice;
      this.AddHeadrailClicked.emit(this.orderItem);
    }
  }

  CalculatePrice() {

    const metres = this.GetMeterage();
    this.price = this.GetBasePrice();
    const pricePerMeter = this.GetPricePerMeter();
    this.price += pricePerMeter * metres;
    this.price += (metres > 5 && this.curve.shape.toString() === "Curved") ? this.shape.bendPrice * 2 : this.shape.bendPrice;
    this.price += Constants.motors_NEW.find(x => x.motor === this.curve.motor.toString()).price;
    this.price += this.GetBracketPrice(metres);
    this.price += this.GetControlPrice();
    const discountRate = this.authService.customer.madeToMeasureDiscount === null ? 0 : this.authService.customer.madeToMeasureDiscount;
    this.discount = Math.round(this.price * discountRate) / 100;
    this.fullPrice = Math.round(this.price * 100) / 100;
    this.price -= this.discount;
    this.price = Math.round(this.price * 100) / 100;
  }

  GetBasePrice(): number {
    return this.shape.basePrice + (this.curve.railType.toString() === "Standard" ? 0 : this.curve.railType.toString() === "60mm Ripple" ? -2.68 : 0.72);
  }

  GetPricePerMeter() {
    return this.shape.meterPrice + (this.curve.railType.toString() === "Standard" ? 0 : this.curve.railType.toString() === "60mm Ripple" ? 6.71 : 13.44);
  }

  GetControlPrice() {
    return this.curve.opening.toString() === "Centre" ? 15 : 0;
  }

  GetBracketPrice(metres: number) {
    const PricePerBracket = Constants.brackets_NEW.find(x => x.bracket === this.curve.brackets.toString()).price;

    const qty = Math.ceil(metres * 1000 / 400);

    return PricePerBracket * qty;
  }

  GetMeterage(): number {

    let mms = 0;

    this.shape.meterageMeasurements.forEach(m => {
      mms += this.curve['dimension' + m];
    });

    return mms <= this.minMMs ? 1.5 : mms / 1000;
  }

  SelectionChanged(step: StepperSelectionEvent) {
    // console.log("Selection Changed");
    if (step.selectedIndex === 1 && step.previouslySelectedIndex === 0) {
      this.DesignDone();
    } else if (step.selectedIndex === 2 && step.previouslySelectedIndex === 1) {
      this.SizesDone();
    }
  }

  DesignDone() {
    const orderItem = Object.assign({}, this.designForm.value) as CurtainTrack;

    this.sizesForm.updateValueAndValidity();

    // reset all the dimension controls
    this.SetControl("AB", false);
    this.SetControl("BC", false);
    this.SetControl("CD", false);
    this.SetControl("DE", false);
    this.SetControl("EF", false);
    this.SetControl("AC", false);
    this.SetControl("AD", false);
    this.SetControl("AE", false);
    this.SetControl("AF", false);
    this.SetControl("BF", false);
    this.SetControl("CF", false);
    this.SetControl("DF", false);
    this.SetControl("Y", false);
    this.SetControl("BD", false);
    this.SetControl("CE", false);
    this.SetControl("BE", false);
    this.SetControl("AG", false);
    this.SetControl("BG", false);
    this.SetControl("CG", false);
    this.SetControl("DG", false);
    this.SetControl("EG", false);
    this.SetControl("FG", false);


    this.shape.measurements.forEach(m => {
      this.SetControl(m);
    });
  }

  SetControl(control: string, value: boolean = true) {
    if (value && this.shape.requiredMeasurements.includes(control)) {
      this.sizesForm.controls[`dimension${control}`].setValidators(Validators.required);
    }
    else {
      this.sizesForm.controls[`dimension${control}`].removeValidators(Validators.required);//.clearValidators();
    }

    this.sizesForm.controls[`dimension${control}`].updateValueAndValidity();

    this[`showDim${control}`] = value;
  }

  SizesDone() {
    const orderItem = Object.assign(
      {},
      this.designForm.value,
      this.sizesForm.value
    ) as CurtainTrack;
    // curently not being used
  }

  CheckSizes(): boolean {
    const orderItem = Object.assign(
      {},
      this.designForm.value,
      this.sizesForm.value
    ) as CurtainTrack;

    const success = true; // orderItem.dimensionB <= orderItem.dimensionA / 2;

    return success;
  }
}

