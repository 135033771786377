import { Component, OnInit } from '@angular/core';
import { IFeature } from 'src/app/models/IFeature';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-curves',
  templateUrl: './curves.component.html',
  styleUrls: ['./curves.component.scss']
})
export class CurvesComponent implements OnInit {
  featuresTypes: IFeature[] = [];
  constructor(private dataservice: DataService,) {
    dataservice.GetFeatures().subscribe((data) => {
      // this.features1 = data.filter(w => w.set === 1);
      // this.featuresWhyUs = data.filter(w => w.set === 2);
      this.featuresTypes = data.filter(w => w.set === 3);
    },
    (err) => {
      console.log(err);
    });
   }

  ngOnInit(): void {
  }

}
