/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cloud_logic_custom": [
        {
            "name": "EmailCustomerInvite",
            "endpoint": "https://txzo9q3942.execute-api.eu-west-2.amazonaws.com/prod",
            "region": "eu-west-2"
        },
        {
            "name": "NextOrderNumber",
            "endpoint": "https://420ie835g0.execute-api.eu-west-2.amazonaws.com/prod",
            "region": "eu-west-2"
        },
        {
            "name": "SesTemplateEmailer",
            "endpoint": "https://8557i8nbs9.execute-api.eu-west-2.amazonaws.com/prod",
            "region": "eu-west-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://yy27abmebbbk3mn4v4awnzrsk4.appsync-api.eu-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "eu-west-2:20e60418-315f-4386-9938-a840e0cbbd90",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_1JPqWvbvq",
    "aws_user_pools_web_client_id": "7q2m6p8jekke7bvt1ethkuosq8",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "cwsstorage161611-prod",
    "aws_user_files_s3_bucket_region": "eu-west-2",
    "aws_dynamodb_all_tables_region": "eu-west-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "NextOrderNumber-prod",
            "region": "eu-west-2"
        }
    ]
};


export default awsmobile;
