import { BracketModel, CurtainShape, MotorModel } from "../models/orders";

export class Constants {

  public static ipPattern = '(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)';
  public static emailPattern = '';
  public static numberPattern = '^[0-9]*$';
  public static byteBoolPattern = '^[0-1]*$';
  public static portPattern = '^([0-9]{1,4}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$';
  // Possibly this one ^[1-9]{1}$|^[0-9]{2,4}$|^[0-9]{3,4}$|^[1-5]{1}[0-9]{1}[0-9]{1}[0-9]{1}[0-9]{1}$|^[1-6]{1}[0-4]{1}[0-9]{1}[0-9]{1}[0-9]{1}$|^[1-6]{1}[0-5]{1}[0-4]{1}[0-9]{1}[0-9]{1}$|^[1-6]{1}[0-5]{1}[0-5]{1}[0-3]{1}[0-5]{1}$

  public static curveShapes: KVPI[] = [
    { key: 'Bow Bay', value: 'Bow Bay', image: 'vertical/vertical-bow-bay-icon.png' },
    { key: 'Splay Bay', value: 'Splay Bay', image: 'vertical/vertical-splay-bay-icon.png' },
    { key: 'Hockey Stick Left', value: 'Hockey Stick Left', image: 'vertical/vertical-hockey-left-icon.png' },
    { key: 'Hockey Stick Right', value: 'Hockey Stick Right', image: 'vertical/vertical-hockey-right-icon.png' },

  ];

  public static curtain_shapes = [{
    id: "03707d14-ad4f-442f-9aa0-e151be7ae1fc",
    shape: CurtainShape.Straight,
    name: "Straight",
    control: "Straight",
    image: "curtain/curtain-straight-icon.png",
    basePrice: 74.28,
    meterPrice: 36.56,
    bendPrice: 0,
    measurements: ["AB"],
    meterageMeasurements: ["AB"],
    requiredMeasurements: ["AB"],
    // motors: this.motors
  },
  {
    id: "e7552462-ccbe-475b-89b8-9616af1fe55b",
    shape: CurtainShape["L Shaped"],
    name: "L Shaped",
    control: "LShaped",
    image: "curtain/curtain-l-shaped-icon.png",
    basePrice: 74.28,
    meterPrice: 36.56,
    bendPrice: 98,
    measurements: ["AB", "BC"],
    meterageMeasurements: ["AB", "BC"],
    requiredMeasurements: ["AB", "BC"],
    // motors: this.motors
  },
  {
    id: "cbc50d1e-f2ab-40f1-a893-bd1d271fa8d4",
    shape: CurtainShape.Curved,
    name: "Curved",
    control: "Curved",
    image: "curtain/curtain-curved-icon.png",
    basePrice: 74.28,
    meterPrice: 36.56,
    bendPrice: 164,
    measurements: ["AB", "Y"],
    meterageMeasurements: ["AB", "Y"],
    requiredMeasurements: ["AB", "Y"],
    //  motors: this.motors
  },
  {
    id: "446d9498-25e9-4ed7-a29c-b061adc6fa71",
    shape: CurtainShape["U Shaped"],
    name: "U Shaped",
    control: "UShaped",
    image: "curtain/curtain-u-shaped-icon.png",
    basePrice: 74.28,
    meterPrice: 36.56,
    bendPrice: 196,
    measurements: ["AB", "BC", "CD"],
    meterageMeasurements: ["AB", "BC", "CD"],
    requiredMeasurements: ["AB", "BC", "CD"],
    
    //  motors: this.motors
  },
  {
    id: "c10d5ea6-494d-4780-8786-76c386fe5fb4",
    shape: CurtainShape.Angular,
    name: "Angular",
    control: "Angular",
    image: "curtain/curtain-angular-icon.png",
    basePrice: 74.28,
    meterPrice: 36.56,
    bendPrice: 392,
    measurements: ["AB", "BC", "CD", "DE", "EF", "BD", "CE", "BE", "Y"],
    meterageMeasurements: ["AB", "BC", "CD", "DE", "EF"],
    requiredMeasurements: ["AB", "BC", "CD", "DE", "EF", "Y"],
    //  motors: this.motors
  },
  {
    id: "45116e42-6b5a-420d-a81e-ec317c7f3470",
    shape: CurtainShape["Angular 3"],
    name: "Angular 3",
    control: "Angular3",
    image: "curtain/curtain-angular-3-sided-icon.png",
    basePrice: 74.28,
    meterPrice: 36.56,
    bendPrice: 196,
    measurements: ["AB", "BC", "CD", "AC", "AD", "BD", "Y"],
    meterageMeasurements: ["AB", "BC", "CD"],
    requiredMeasurements: ["AB", "BC", "CD", "Y"],
    // motors: this.motors
  },
  {
    id: "2378f4d2-8a0f-4748-b052-807c48af6441",
    shape: CurtainShape["Angular 4"],
    name: "Angular 4",
    control: "Angular4",
    image: "curtain/curtain-angular-4-sided-icon.png",
    basePrice: 74.28,
    meterPrice: 36.56,
    bendPrice: 294,
    measurements: ["AB", "BC", "CD", "DE", "AC", "AD", "AE", "BE", "CE", "Y"],
    meterageMeasurements: ["AB", "BC", "CD", "DE", "EF"],
    requiredMeasurements: ["AB", "BC", "CD", "DE", "EF", "Y"],
    // motors: this.motors
  },
  {
    id: "27610f68-13ef-494e-95a4-c2e0cb17967e",
    shape: CurtainShape["Angular 5"],
    name: "Angular 5",
    control: "Angular5",
    image: "curtain/curtain-angular-5-sided-icon.png",
    basePrice: 74.28,
    meterPrice: 36.56,
    bendPrice: 392,
    measurements: ["AB", "BC", "CD", "DE", "EF", "AC", "AD", "AE", "AF", "BF", "CF", "DF", "Y"],
    meterageMeasurements: ["AB", "BC", "CD", "DE", "EF"],
    requiredMeasurements: ["AB", "BC", "CD", "DE", "EF", "Y"],
    // motors: this.motors
  },
  {
    id: "63a25791-235d-4fcc-9f19-262608e189e1",
    shape: CurtainShape["Angular 6"],
    name: "Angular 6",
    control: "Angular6",
    image: "curtain/curtain-angular-6-sided-icon.png",
    basePrice: 74.28,
    meterPrice: 36.56,
    bendPrice: 490,
    measurements: ["AB", "BC", "CD", "DE", "EF","FG", "AC", "AD", "AE", "AF", "BF", "CF", "DF","AG", "BG","CG","DG","EG", "Y"],
    meterageMeasurements: ["AB", "BC", "CD", "DE", "EF", "FG"],
    requiredMeasurements: ["AB", "BC", "CD", "DE", "EF", "FG", "Y"],
    // motors: this.motors
  }];

  public static brackets_NEW: BracketModel[] = [
    { 
      bracket: "Swivel",
      price: 0.64
    }, {
      bracket: "One touch",
      price: 1.38
    }, {
      bracket: "Wall + Swivel",
      price: 3.51
    }, {
      bracket: "Wall +one touch",
      price: 4.25
    }, {
      bracket: "Double wall Bkt +Swivel",
      price: 4.20
    }, {
      bracket: "Double wall Bkt + one touch",
      price: 4.94
    }
  ];
  
  public static motors_NEW: MotorModel[] = [
    {
      motor: "No Motor",
      price: 0
    },
    {
      motor: "Glydea Ultra 60 WT",
      price: 215
    },
    {
      motor: "Glydea Ultra 60 DCT",
      price: 209
    },
    {
      motor: "Glydea Ultra 60 RTS",
      price: 246
    },  
    {
      motor: "Irismo 45",
      price: 252
    },
    {
      motor: "Movelite 35 WT",
      price: 82
    },
    {
      motor: "Movelite 35 DCT",
      price: 76
    },
    {
      motor: "Movelite 35 RTS",
      price: 86
    },
    {
      motor: "Movelite 35RTS WF",
      price: 109
    }
];


}

export interface KVP {
  key: number | string;
  value: string;
}

export interface KVPI {
  key: number | string;
  value: string;
  image: string;
}
