<div *ngIf="qvc" class="container qvc">

  <div class="row">
    <div class="col-sm-4 offset-sm-4 text-center">
        <div class="title warn-cws-dk">{{qvc.title}}</div>
        <div class="subtitle">{{qvc.subtitle}}</div>
     </div>
  </div>


  <div class="row">
    <div class="col-sm-4">
      <div class="row">
        <div class="col-9 wow slideInDown">
          <div class="qvc-title text-right warn-cws-lt">{{qvc.tl.title}}</div>
          <div class="qvc-subtitle text-right">{{qvc.tl.subtitle}}</div>
          <div class="qvc-description text-right">{{qvc.tl.description}}</div>
        </div>
        <div class="col-3 text-center">
          <br>
          <div class="qvc-icon wow rotateIn"><i class="fa-2x c-blue-lt" [class]="qvc.tl.icon"></i></div>
        </div>
      </div>

      <br>

      <div class="row">
        <div class="col-9 wow slideInLeft">
          <div class="qvc-title text-right warn-cws-lt">{{qvc.bl.title}}</div>
          <div class="qvc-subtitle text-right">{{qvc.bl.subtitle}}</div>
          <div class="qvc-description text-right">{{qvc.bl.description}}</div>
        </div>
        <div class="col-3 text-center">
          <br>
          <div class="qvc-icon wow rotateIn"><i class="fa-2x c-blue-lt" [class]="qvc.bl.icon"></i></div>
        </div>
      </div>

    </div>

    <div class="col-sm-4 text-center">
      <div><img class="img-fluid wow fadeIn" [src]="qvc.mainImage" /> </div>
    </div>

    <div class="col-sm-4">

      <div class="row">

        <div class="col-3 text-center">
          <br>
          <div class="qvc-icon wow rotateIn"><i class="fa-2x c-blue-lt" [class]="qvc.tr.icon"></i></div>
        </div>

        <div class="col-9  wow slideInRight">
          <div class="qvc-title  warn-cws-lt">{{qvc.tr.title}}</div>
          <div class="qvc-subtitle">{{qvc.tr.subtitle}}</div>
          <div class="qvc-description">{{qvc.tr.description}}</div>
        </div>

      </div>

      <br>

      <div class="row">

        <div class="col-3 text-center">
          <br>
          <div class="qvc-icon wow rotateIn"><i class="fa-2x c-blue-lt" [class]="qvc.br.icon"></i></div>
        </div>

        <div class="col-9  wow slideInUp">
          <div class="qvc-title warn-cws-lt">{{qvc.br.title}}</div>
          <div class="qvc-subtitle">{{qvc.br.subtitle}}</div>
          <div class="qvc-description">{{qvc.br.description}}</div>
        </div>

      </div>
    </div>
  </div>
</div>
