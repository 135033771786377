import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bow-bay',
  templateUrl: './bow-bay.component.html',
  styleUrls: ['./bow-bay.component.scss']
})
export class BowBayComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
