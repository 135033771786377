  <form (keydown.enter)="$event.preventDefault()" [formGroup]="editForm" (ngSubmit)="OnFormSubmit()">
    <div class="container">
      <div class="row">
        <div class="col">
          <button (click)="cancelClicked(true)"  class="btn btn-sm btn-warning" type="button">
            <i class="fas fa-sign-out-alt fa-rotate-180"></i>
            <span> Back to orders</span>
          </button>
        </div>
        <div *ngIf="!viewingCurves" class="col">
          <h2>Add a New Order</h2>
        </div>
      </div>
      
      <div *ngIf="!viewingCurves">
        <div class="row">
          <div class="col-6">
            <!-- ORDER DETAILS -->
            <h3>Enter order details</h3>
          </div>
        </div>
        
        
        <div class="row">
          <!-- Order Ref. -->
          <div class="col-md-4">
            <mat-form-field class="w-100">
              <input type="text" formControlName="customerReference" required matInput placeholder="Customer reference" />
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <div class="alert alert-primary d-flex align-items-center" role="alert">
              <i class="fas fa-info-circle"></i>&nbsp;
              <span>Usually your customers Surname followed by your order number</span>
            </div>
          </div>
        </div>
        
        <div class="row">
          <!-- Order Notes -->
          <div class="col-md-4">
            <mat-form-field class="w-100">
              <input type="text" formControlName="orderNotes" matInput placeholder="Your Order Notes" />
            </mat-form-field>
          </div>
          
          <div class="col-md-6">
            <div class="alert alert-primary d-flex align-items-center" role="alert">
              <i class="fas fa-info-circle"></i>&nbsp;
              <span>Enter any order notes for this customer, you can edit this later</span>
            </div>
          </div>
        </div>
        <div class="row">
          
          <div class="col-6 d-flex justify-content-start">
            <input *ngIf="!isAddingNewOrder && !viewingCurves" [disabled]="!editForm.valid" type="submit"
            class="btn btn-success" value="Update Order" />
            <button *ngIf="isAddingNewOrder && !viewingCurves" [disabled]="!editForm.valid" type="submit"
            class="btn btn-success" value="Create Quote">
            Create Order<i class="fas fa-circle-notch fa-spin mx-1" *ngIf="loading"></i>
          </button>
          <input type="button" (click)="cancelClicked(true)" class="btn btn-warning mx-2" [disabled]="loading"
          value="Cancel" />
        </div>
      </div>
      <div class="row pt-4">
        <div class="col-md-12">
          <div>
            <i class="fas fa-info-circle"></i>&nbsp;
            <b>After creating the order it will be in a 'Quote' status you can add any tracks in the nexts step before
              placing the order with Bend It</b>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    
  </form>
  
  <div *ngIf="viewingCurves">
    
    <table class="table table-striped w-100">
      <thead>
        <tr>
          <td><span><b>Order No.:</b><br />{{ order.orderNo }}</span></td>
          <td><span><b>Reference:</b> <br />{{ order.customerReference }}</span></td>
          <td><span><b>Date:</b><br />{{ order.dateOrdered | date }}</span></td>
          <td><span><b>Status:</b><br />{{ order.orderStatus }}</span></td>
          <td><span *ngIf="viewingCurves"><b>Notes:</b><br />{{ order.orderNotes }}</span></td>
        </tr>
      </thead>
    </table>
    
    <!-- ORDER LINES -->
    <!-- Table of order lines -->
    <div class="row">
      <div class="col-md-12">
        <div *ngIf="viewingCurves && orderLines.length > 0" class="row">
          <div class="col">
            <app-order-item-table (editClicked)="editClicked($event)" (deleteClicked)="deleteClicked($event)"
            (viewClicked)="viewClicked($event)" [orderItems]="orderLines"></app-order-item-table>
          </div>
        </div>
      </div>
    </div>
    
    <div class="row">
      <div class="ps-4 col-3">
        <div *ngIf="orderLines.length === 0" class="row">
          <div class="alert alert-primary d-flex align-items-center" role="alert">
            &nbsp;<i class="fas fa-arrow-down"></i>&nbsp;&nbsp;
            <i class="fas fa-arrow-down"></i>&nbsp;&nbsp;
            <i class="fas fa-arrow-down"></i>&nbsp;&nbsp;
            <span class="ps-3">Add the first item to this quote</span>
          </div>
        </div>
      </div>
    </div>
    
    <table  class="table table-striped w-100">
      <thead>
        <tr>
          <th class="fixedM no-borders"><input *ngIf="order.orderStatus == 'quote'" type="button" (click)="addItem = true" class="btn btn-outline-success" value="Add Item" /></th>
          <th class="no-borders"><input *ngIf="order.orderStatus == 'quote' && orderLines.length > 0" type="button" (click)="confirmOrder = true" class="btn btn-success" value="Place Order" /></th>
          <th *ngIf="order.orderItems.items.length > 0" class="fixedM">Items Total</th>
          <th *ngIf="order.orderItems.items.length > 0" class="fixedSM text-end"><span>{{ order.price | currency : "GBP" }}</span></th>
          <th class="fixedL"></th>
        </tr>
        
        <tr *ngIf="customerOrderDiscount > 0 && order.orderItems.items.length > 0">
          <td class="fixedM no-borders"></td>
          <td class="no-borders"></td>
          <td class="fixedM">Discount</td>
          <td class="fixedSM text-end"><span>{{ order.discount| currency : "GBP" }}</span></td>
          <td class="fixedL">({{customerOrderDiscount}}%)</td>
        </tr>
        
        <tr *ngIf="customerOrderDiscount > 0 && order.orderItems.items.length > 0">
          <th class="fixedM no-borders"></th>
          <th class="no-borders"></th>
          <th class="fixedM">Sub Total</th>
          <th class="fixedSM text-end"><span>{{ order.price - order.discount| currency : "GBP" }}</span></th>
          <th class="fixedL"></th>
        </tr>
        
        <tr *ngIf="order.orderItems.items.length > 0 && order.orderItems.items.length > 0">
          <td class="fixedM no-borders"></td>
          <td class="no-borders"></td>
          <td class="fixedM">V.A.T.</td>
          <td class="fixedSM text-end"><span>{{(order.tax)| currency : "GBP" }}</span></td>
          <td class="fixedL">(20%)</td>
        </tr>
        
        <tr *ngIf="order.orderItems.items.length > 0">
          <th class="fixedM no-borders"></th>
          <th class="no-borders"></th>
          <th class="fixedM">Total</th>
          <th class="fixedSM text-end"><span>{{ order.orderTotal | currency : "GBP" }}</span></th>
          <th class="fixedL"></th>
        </tr>
      </thead>
    </table>
    
    <app-modal-popup *ngIf="confirmOrder" [showPopup]="confirmOrder" [title]="'Confirm Order'" [btnCancelText]="'Cancel'"
    (cancelClicked)="cancelClicked()" [btnActionText]="'Confirm Order'" (actionClicked)="placeOrder()">
    <app-order-details [order]="order"></app-order-details>
  </app-modal-popup>
</div>

<app-modal-popup *ngIf="addingCurve" [animation]="'fadeIn'" [showPopup]="addingCurve" 
[title]="viewOnly ? 'VIEW VERTICAL HEADRAIL'  : orderItem === undefined ? 'ADD VERTICAL HEADRAIL' : 'EDIT VERTICAL HEADRAIL'"
(cancelClicked)="addingCurve = false">
<app-headrail-edit [itemNumber]="itemNumber" [orderItem]="orderItem" [product]="productSelected" [orderID]="order.id" (AddHeadrailClicked)="addCurve($event)"
[viewOnly]="viewOnly">
</app-headrail-edit>
</app-modal-popup>

<app-modal-popup *ngIf="addingCurtainTrack" [animation]="'fadeIn'" [showPopup]="addingCurtainTrack"
[title]="viewOnly ? 'VIEW CURTAIN TRACK' : orderItem === undefined ? 'ADD CURTAIN TRACK' : 'EDIT CURTAIN TRACK'" (cancelClicked)="addingCurtainTrack = false">
<app-curtaintrack-edit [itemNumber]="itemNumber" [orderItem]="orderItem" [product]="productSelected" [orderID]="order.id" (AddHeadrailClicked)="addCurve($event)"
[viewOnly]="viewOnly">
</app-curtaintrack-edit>
</app-modal-popup>

<app-modal-popup *ngIf="addingExtraItem" [animation]="'fadeIn'" [showPopup]="addingExtraItem"
[title]="viewOnly ? 'VIEW EXTRAS' : orderItem === undefined ? 'ADD EXTRAS' : 'EDIT EXTRA'" (cancelClicked)="addingExtraItem = false">
<app-extra-item-edit [orderItem]="orderItem" [product]="productSelected" [orderID]="order.id" (AddExtraClicked)="addExtra($event)"
[viewOnly]="viewOnly">
</app-extra-item-edit>
</app-modal-popup>

<app-modal-popup [animation]="'fadeIn'" [showPopup]="addItem" (cancelClicked)="addItem = false">
  <app-product-picker (productSelected)="ProductSelected($event)" (trackSelected)="TrackSelected($event)"></app-product-picker>
</app-modal-popup>