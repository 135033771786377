import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {

  @Input()
  src: string;

  @Input()
  paddingCSSclass: string;

  @Input()
  cardTitle: string;

  @Input()
  cardText: string;

  @Input()
  buttonText: string;
  showButton: boolean;

  @Output() cardButtonClicked = new EventEmitter<string>();

  @Input()
  delay: number;

  constructor() {
   }

  ngOnInit(): void {
    this.showButton = this.buttonText !== undefined && this.buttonText.length > 0;
  }

  onCardButtonClicked() {
    this.cardButtonClicked.emit();
  }
}
