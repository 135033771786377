import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { FAQ } from 'src/app/models/faq';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  faqs: FAQ[];

  search: string;

  constructor(private dataService: DataService) {
    dataService.GetFAQs().subscribe((data) => {
      this.faqs = data;
    });

   }

  ngOnInit(): void {
  }

}
