<div class="p-5">

  <div class="container">

    <div [class]="getReverseClass()">
      <div class="row">
        <div class="col-sm-12 col-md-9 align-self-center">
            <p><strong>{{headline}}</strong></p>
            <i><p class="pre-wrap" [innerHTML]="quote"></p></i>
            <p class="pt-4 mb-2 display-7"><b>{{name}}</b></p>
            <p>{{title}}</p>
        </div>

        <div class="col-sm-12 col-md-3">
          <div class="p-3">
            <img class="img-fluid" [src]="safeSrc" [alt]="getAltText()" [title]="getTitleText()">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
