import { Component, Inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-curve-preview',
  templateUrl: './curve-preview.component.html',
  styleUrls: ['./curve-preview.component.scss']
})
export class CurvePreviewComponent implements OnInit, OnChanges {

  @Input() shape: string;
  @Input() reveal: string;
  @Input() measurement: string;


  @Input() measurements: string[] = ['A','B','C','D'];
  reveals: string[] = ['revealYes','revealNo'];

  constructor(@Inject(DOCUMENT) private document: Document) {

  }

  ngOnChanges(changes: SimpleChanges): void {
    setTimeout(() => {
      this.SetColoursForPreviewDimensions();
    }, 10);
    this.SetRevealOption();
  }

  ngOnInit(): void {
    // this.SetColoursForPreviewDimensions();
    // this.SetRevealOption();
  }

  SetColoursForPreviewDimensions() {
  
    if(this.measurements?.length > 0) {
      // go through each measurement selector and set the colours for those elements
      this.measurements.forEach( (meas) => {
        this.SetMeasurementColour('dim' + meas);
      });
    }
  }

  SetMeasurementColour(measurement: string) {

    // the measurement passed in is used as the class selector in the SVG
    let selector = '.' + measurement;

    // Get elements with this measument selector
    const fills = this.document.querySelectorAll(selector);
    this.setAttributes(fills, measurement, 'fill');

    // claases in the SVG with an 's' suffix are lines etc that need the fill attribute set
    selector+= 's';
    const strokes = this.document.querySelectorAll(selector);
    this.setAttributes(strokes, measurement, 'stroke');
  }

  setAttributes(elements: NodeListOf<Element>, measurement: string, attribute: string) {
    elements.forEach((item) => {
      if (measurement === this.measurement) {
        item.setAttribute(attribute, '#222');
      } else {
        item.setAttribute(attribute, '#aaa');
      }
    });
  }

  SetRevealOption() {
    const noReveals = this.document.querySelectorAll('.revealNo');
    this.ShowHideReveals(noReveals, 'No');
    const yesReveals = this.document.querySelectorAll('.revealYes');
    this.ShowHideReveals(yesReveals, 'Yes');
  }

  ShowHideReveals(reveals: NodeListOf<Element>, match: string) {
    reveals.forEach((item) => {
      if(this.reveal === match) {
        item.setAttribute('display', 'block');
      } else {
        item.setAttribute('display', 'none');
      }
    });
  }

}
