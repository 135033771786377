<div *ngIf="HeadrailRows.length > 0">
  <span class="badge bg-success">Vertical Headrails</span>
  <table class="table table-striped w-100">
    <thead>
      <tr>
        <th>Type</th>
        <th>Loc./Ref.</th>
        <th>Shape</th>
        <th>Dim A</th>
        <th>Dim B</th>
        <th>Dim C</th>
        <th>Dim D</th>
        <th>System</th>
        <th>Colour</th>
        <th>Qty</th>
        <th class="text-end">Price</th>
        <th>view</th>
        <th>edit</th>
        <th>del</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let row of HeadrailRows">
        <!-- <app-curve-item-view [orderItem]="item"></app-curve-item-view> -->
        <td class="fixedM">{{row.type}}</td>
        <td class="fixedM">{{row.location.value}}</td>
        <td>{{row.shape.value}}</td>
        <td>{{row.dimensionA.value}}</td>
        <td>{{row.dimensionB.value}}</td>
        <td>{{row.dimensionC.value}}</td>
        <td>{{row.dimensionD.value}}</td>
        <td class="fixedSM">{{row.system.value}}</td>
        <td class="fixedSM">{{row.colour.value}}</td>
        <td class="fixedXS">{{row.quantity}}</td>
        <td matTooltip="{{GetPriceDiscountTooltip(row)}}" class="fixedSM text-end">{{row.price | currency:'GBP'}}</td>
        <td class="fixedXS"><i (click)="view(row)" matTooltip="view this curved headrail"
            class="fas fa-eye text-success"></i></td>
        <td class="fixedXS">
          <i *ngIf="row.isEditable" (click)="edit(row)" matTooltip="edit this curved headrail"
            class="fas fa-edit text-success"></i>
          <i *ngIf="!row.isEditable"
            matTooltip="You can not edit this curved headrail because it has already been ordered"
            class="fas text-secondary fa-edit"></i>
        </td>
        <td class="fixedXS">
          <i *ngIf="row.isEditable" (click)="delete(row)" matTooltip="delete this curved headrail"
            class="cursor fas fa-trash-alt text-success"></i>
          <i *ngIf="!row.isEditable"
            matTooltip="You can not delete this curved headrail because it has already been ordered"
            class="fas text-secondary fa-trash-alt"></i>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<div *ngIf="CurtainRows.length > 0">
  <span class="badge bg-success">Curtain Tracks</span>
  <table class="table table-striped w-100">
    <thead>
      <tr>
        <th class="fixedM">Type</th>
        <th class="fixedM">Loc./Ref.</th>
        <th>Shape</th>
        <th>Rail Type</th>
        <th>Dim AB</th>
        <th>Dim BC</th>
        <th>Dim CD</th>
        <th>Dim Y</th>
        <th matTooltip="additional measurements"><i class="fa fa-info-circle"></i></th>
        <th>System</th>
        <th>Colour</th>
        <th>Qty</th>
        <th class="text-end">Price</th>
        <th>view</th>
        <th>edit</th>
        <th>del</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let row of CurtainRows">
        <!-- <app-curve-item-view [orderItem]="item"></app-curve-item-view> -->
        <td>{{row.type}}</td>
        <td>{{row.location.value}}</td>
        <td>{{row.shape.value}}</td>
        <td>{{row.railType.value}}</td>
        <td>{{row.dimensionA.value}}</td>
        <td>{{row.dimensionB.value}}</td>
        <td>{{row.dimensionC.value}}</td>
        <td>{{row.dimensionD.value}}</td>
        <td class="fixedXS"><span matTooltip="{{row.measurements.tooltip}}"><i class="fas fa-info-circle"></i></span>
        </td>
        <td class="fixedSM">{{row.system.value}}</td>
        <td class="fixedSM">{{row.colour.value}}</td>
        <td class="fixedXS">{{row.quantity}}</td>
        <td matTooltip="{{GetPriceDiscountTooltip(row)}}" class="fixedSM text-end">{{row.price | currency:'GBP'}}</td>
        <td class="fixedXS"><i (click)="view(row)" matTooltip="view this curved headrail"
            class="fas fa-eye text-success"></i></td>
        <td class="fixedXS">
          <i *ngIf="row.isEditable" (click)="edit(row)" matTooltip="edit this curved headrail"
            class="fas fa-edit text-success"></i>
          <i *ngIf="!row.isEditable"
            matTooltip="You can not edit this curved headrail because it has already been ordered"
            class="fas text-secondary fa-edit"></i>
        </td>
        <td class="fixedXS">
          <i *ngIf="row.isEditable" (click)="delete(row)" matTooltip="delete this curved headrail"
            class="cursor fas fa-trash-alt text-success"></i>
          <i *ngIf="!row.isEditable"
            matTooltip="You can not delete this curved headrail because it has already been ordered"
            class="fas text-secondary fa-trash-alt"></i>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<div *ngIf="ExtraRows.length > 0">


  <span class="mt-3 badge bg-success">Extras</span>
  <table class="table table-striped w-100">
    <thead>
      <tr>
        <th>Product</th>
        <th>Description</th>
        <th>System</th>
        <th>Colour</th>
        <th>Qty</th>
        <th class="text-end">Price</th>
        <th>view</th>
        <th>edit</th>
        <th>del</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let row of ExtraRows">
        <!-- <app-curve-item-view [orderItem]="item"></app-curve-item-view> -->
        <td class="fixedM">{{row.product}}</td>
        <td>{{row.description}}</td>
        <td class="fixedSM">{{row.system.value}}</td>
        <td class="fixedSM">{{row.colour.value}}</td>
        <td class="fixedXS">{{row.quantity}}</td>
        <td matTooltip="{{GetPriceDiscountTooltip(row)}}" class="fixedSM text-end">{{row.price | currency:'GBP'}}</td>
        <td class="fixedXS"><i (click)="view(row)" matTooltip="view this curved headrail"
            class="fas fa-eye text-success"></i></td>
        <td class="fixedXS">
          <i *ngIf="row.isEditable" (click)="edit(row)" matTooltip="edit this curved headrail"
            class="fas fa-edit text-success"></i>
          <i *ngIf="!row.isEditable"
            matTooltip="You can not edit this curved headrail because it has already been ordered"
            class="fas text-secondary fa-edit"></i>
        </td>
        <td class="fixedXS">
          <i *ngIf="row.isEditable" (click)="delete(row)" matTooltip="delete this curved headrail"
            class="cursor fas fa-trash-alt text-success text-success"></i>
          <i *ngIf="!row.isEditable"
            matTooltip="You can not delete this curved headrail because it has already been ordered"
            class="fas text-secondary fa-trash-alt"></i>
        </td>
      </tr>
    </tbody>
  </table>
</div>