import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import API, { graphqlOperation } from "@aws-amplify/api-graphql";
import { RestAPI } from "@aws-amplify/api-rest";

import { APIService, ClientStatus, CustomersByWebsiteQuery, GetWebsiteQuery, ModelCustomerFilterInput, ModelOrderFilterInput, ModelSortDirection, OrdersByCustomerQuery, TaxCode, WebsiteStatus } from './API.service'

@Injectable()
export class API_CWSService extends APIService {
  constructor(private http: HttpClient) {
    super();
  }

  async GetNextOrderNumber() {
    let options = {}; // { "params" : {"prefix": "BEN"}};
    return RestAPI.get("NextOrderNumber", "/next/BEN", options);
  }

  async GetBenditData(id: string): Promise<GetWebsiteBendit> {
    const statement = `query GetWebsite($id: ID!) {
      getWebsite(id: $id) {
        __typename
        id
        name
        url
        email
        urlRegister
        websiteStatus
        dateLive
        clientID
        products {
          __typename
          items {
            __typename
            id
            sku
            name
            title
            shortDescription
            description
            delivery
            taxCode
            tax
            price
            size
            colour
            weight
            productServiceName
            productNotes
            supplierID
            supplier {
              __typename
              id
              name
              createdAt
              updatedAt
            }
            documents {
              __typename
              items {
                __typename
                id
                title
                description
                documentType
                mimeType
                location
                productID
                product {
                  __typename
                  id
                  sku
                  name
                  title
                  shortDescription
                  description
                  delivery
                  taxCode
                  tax
                  price
                  size
                  colour
                  weight
                  productServiceName
                  productNotes
                  websiteID
                  offerID
                  supplierID
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            tags {
              __typename
              items {
                __typename
                id
                productID
                tagID
                tag {
                  __typename
                  id
                  name
                  area
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          nextToken
        }
        createdAt
        updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetWebsiteBendit>response.data.getWebsite;
  }



  async OrdersByCustomerWithItems(
    customerID: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelOrderFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<OrdersByCustomerQuery> {
    const statement = `query OrdersByCustomer($customerID: ID!, $sortDirection: ModelSortDirection, $filter: ModelOrderFilterInput, $limit: Int, $nextToken: String) {
      OrdersByCustomer(customerID: $customerID, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
        __typename
        items {
          __typename
          id
          orderNo
          customerReference
          itemsTotal
          discount
          offerDiscount
          tax
          price
          delivery
          orderTotal
          orderNotes
          dateOrdered
          datePaid
          dateDelivered
          orderStatus
          group
          customerID
          orderItems {
            __typename
            items {
              __typename
              id
              name
              fullPrice
              discount
              tax
              price
              quantity
              customerInputs
              orderItemNotes
              group
              orderID
            }
          }
          customer {
            __typename
            id
            email
            userName
            firstName
            lastName
            companyName
            phone
            phoneOther
            websiteID
            createdAt
            updatedAt
          }
          addressID
          address {
            __typename
            id
            addressType
            addr1
            addr2
            addr3
            county
            postCode
            Country
            driverInstructions
            notes
            group
            customerID
            createdAt
            updatedAt
          }
          offerID
          offer {
            __typename
            id
            websiteID
            dateValidFrom
            dateValidTo
            offerType
            offerStatus
            discountPercentage
            discountAmount
            createdAt
            updatedAt
          }
          orderItems {
            __typename
            items {
              __typename
              id
            }
          }
          invoiceNumber
          payments {
            __typename
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
    }`;
    const gqlAPIServiceArguments: any = {
      customerID
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <OrdersByCustomerQuery>response.data.OrdersByCustomer;
  }

  async CustomersByWebsiteCWS(
    websiteID: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelCustomerFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<CustomersByWebsiteQuery> {
    const statement = `query CustomersByWebsite($websiteID: ID!, $sortDirection: ModelSortDirection, $filter: ModelCustomerFilterInput, $limit: Int, $nextToken: String) {
        customersByWebsite(
          websiteID: $websiteID
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            email
            userName
            companyName
            firstName
            lastName
            phone
            phoneOther
            accountNumber
            customerStatus
            customerType
            madeToMeasureDiscount
            standardItemsDiscount
            deliveryDiscount
            orderDiscount
            customerTermsID
            customerTerms {
              __typename
              id
              terms
              days
              waitForPayment
              createdAt
              updatedAt
            }
            emailSubscribed
            websiteID
            
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      websiteID
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CustomersByWebsiteQuery>response.data.customersByWebsite;
  }
  
  
}

export type GetWebsiteBendit = {
  __typename: "Website";
  id: string;
  name: string;
  url?: string | null;
  email?: string | null;
  urlRegister?: string | null;
  websiteStatus?: WebsiteStatus | null;
  dateLive?: string | null;
  clientID: string;
  client?: {
    __typename: "Client";
    id: string;
    name: string;
    contact?: string | null;
    phone?: string | null;
    email?: string | null;
    clientStatus?: ClientStatus | null;
    websites?: {
      __typename: "ModelWebsiteConnection";
      nextToken?: string | null;
    } | null;
    users?: {
      __typename: "ModelUserConnection";
      nextToken?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  products?: {
    __typename: "ModelProductConnection";
    items: Array<{
      __typename: "Product";
      id: string;
      sku: string;
      name: string;
      title: string;
      shortDescription?: string | null;
      description?: string | null;
      delivery?: number | null;
      taxCode?: TaxCode | null;
      tax?: number | null;
      price?: number | null;
      size?: string | null;
      colour?: string | null;
      weight?: string | null;
      productServiceName?: string | null;
      productNotes?: string | null;
      websiteID: string;
      offerID: string;
      supplierID: string;
      tags?: {
        __typename: "ModelProductTagsConnection";
        items: Array<{
          __typename: "ProductTags";
          id: string;
          productID: string;
          tagID: string;
          createdAt: string;
          updatedAt: string;
        } | null>;
        nextToken?: string | null;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

