<div class="row pb-0 mb-0">
  <div class="col-5">
    <button class="btn btn-sm btn-warning" type="button" (click)="toAccount()">
      <i class="fas fa-sign-out-alt fa-rotate-180"></i>
      Back to Account
    </button>
  </div>
  <div class="col-3">
    <mat-form-field class="example-full-width" appearance="fill">
      <mat-label>Search</mat-label>
      <input (keyup)="SetFilter()" matInput placeholder="Search" [(ngModel)]="search" />
    </mat-form-field>
  </div>
  <div class="col-3">
    <mat-form-field appearance="fill" class="full-width">
      <mat-label>Status</mat-label>
      <mat-select [(value)]="Statuses" (selectionChange)="SetFilter()" multiple>
        <mat-option *ngFor="let item of statuses" [value]="item.key">
          {{ item.value }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-1">
    <i (click)="RefreshData()" matTooltip="Refresh the data" class="pt-3 fa-lg fas fa-sync-alt text-success"></i>
  </div>
</div>

<table class="table table-striped w-100" mat-table mat-sort [dataSource]="mcr">
  <ng-container matColumnDef="orderNo">
    <th mat-sort-header mat-header-cell *matHeaderCellDef>Ord. No.</th>
    <td class="align-middle" mat-cell *matCellDef="let element" (click)="ViewDetails(element)">
      <a (click)="ViewDetails(element)">{{ element.orderNo }}</a>
    </td>
  </ng-container>

  <ng-container matColumnDef="customerReference">
    <th mat-sort-header mat-header-cell *matHeaderCellDef>Cus. Ref.</th>
    <td class="align-middle" mat-cell *matCellDef="let element">
      {{ element.customerReference }}
    </td>
  </ng-container>

  <ng-container matColumnDef="orderNotes">
    <th mat-sort-header mat-header-cell *matHeaderCellDef>Order Notes.</th>
    <td class="align-middle" mat-cell *matCellDef="let element">
      {{ element.orderNotes }}
    </td>
  </ng-container>

  <ng-container matColumnDef="price">
    <th mat-sort-header mat-header-cell *matHeaderCellDef>Price</th>
    <td class="align-middle" mat-cell *matCellDef="let element">
      <span
        matTooltip="{{element.discount > 0 ? ('Full price:£' + element.price + ', Discount:£' + element.discount):''}}"
        *ngIf="element.price">{{ element.price -element.discount + element.delivery | currency : "GBP" }}</span>
      <span *ngIf="!element.price">£---.--</span>
    </td>
  </ng-container>

  <ng-container matColumnDef="dateOrdered">
    <th mat-sort-header mat-header-cell *matHeaderCellDef>Date Ordered</th>
    <td class="align-middle" mat-cell *matCellDef="let element">
      {{ element.dateOrdered | date }}
    </td>
  </ng-container>

  <ng-container matColumnDef="dateDelivered">
    <th mat-sort-header mat-header-cell *matHeaderCellDef>Date Delivered</th>
    <td class="align-middle" mat-cell *matCellDef="let element">
      <span *ngIf="element.dateDelivered">{{
        element.dateDelivered | date
        }}</span>
      <span *ngIf="!element.dateDelivered">--- --, ----</span>
    </td>
  </ng-container>

  <ng-container matColumnDef="datePaid">
    <th mat-sort-header mat-header-cell *matHeaderCellDef>Date Paid</th>
    <td class="align-middle" mat-cell *matCellDef="let element">
      <span *ngIf="element.datePaid">{{
        element.datePaid | date
        }}</span>
      <span *ngIf="!element.datePaid">--- --, ----</span>
    </td>
  </ng-container>

  <ng-container matColumnDef="edit">
    <th matTooltip="edit order / customer references" matTooltipPosition="left" mat-sort-header mat-header-cell
      *matHeaderCellDef>
      <i class="fas fa-edit"></i>
    </th>
    <td class="align-middle" mat-cell *matCellDef="let element">
      <i *ngIf="element.orderStatus === 'quote'" matTooltip="edit the order details" (click)="Edit(element)"
        class="cursor fas fa-edit text-success"></i>
      <i *ngIf="element.orderStatus !== 'quote'" matTooltip="You cannot edit this order because it has been ordered"
        class="text-secondary fas fa-edit"></i>
    </td>
  </ng-container>

  <ng-container matColumnDef="view">
    <th mat-header-cell *matHeaderCellDef>
      view/add <br />
      to order
    </th>
    <td class="align-middle" mat-cell *matCellDef="let element">
      <button type="button" matTooltip="Add / Edit items on this order" (click)="ViewDetails(element)"
        class="cursor btn-sm btn-primary position-relative">
        items
        <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">{{
          GetOrderLineTotal(element) }}</span>
      </button>
    </td>
  </ng-container>

  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef>status</th>
    <td class="align-middle" mat-cell *matCellDef="let element">
      <span *ngIf="
          element.orderStatus === 'quote' &&
          element.orderItems?.items?.length === 0
        " matTooltip="Order has no tracks added, please add an item">no items added</span>
      <span *ngIf="element.orderStatus !== 'quote'" matTooltip="Order has been placed">{{ element.orderStatus }}</span>
      <button *ngIf="
          element.orderStatus === 'quote' &&
          element.orderItems?.items?.length > 0
        " (click)="placeOrderConfirmed(element)"
        matTooltip="If you've finished adding items then please place your order" class="btn btn-sm btn-success">
        Place Order
      </button>
      <app-modal-popup *ngIf="ConfirmOrder" [showPopup]="ConfirmOrder" [title]="'Confirm Order'"
        [btnCancelText]="'Cancel'" (cancelClicked)="cancelClicked()" [btnActionText]="'Confirm Order'"
        (actionClicked)="actionClicked(orderToConfirm)">
        <app-order-details [order]="orderToConfirm"></app-order-details>
      </app-modal-popup>
    </td>
  </ng-container>

  <ng-container matColumnDef="delete">
    <th matTooltip="Delete order (if in Quote status)" matTooltipPosition="left" mat-sort-header mat-header-cell
      *matHeaderCellDef><i class="fas fa-trash-alt"></i></th>
    <td class="align-middle" mat-cell *matCellDef="let element">
      <i *ngIf="
          element.orderStatus === 'quote' &&
          element.orderItems?.items?.length === 0
        " matTooltip="Delete this order" (click)="Delete(element)" class="cursor fas fa-trash-alt text-success"></i>
      <i *ngIf="
          (element.orderStatus === 'quote' &&
            element.orderItems?.items?.length > 0) ||
          element.orderStatus !== 'quote'
        " matTooltip="{{element.orderStatus === 'quote'
            ? 'You cannot delete this order, there are items added, remove the items first'
            : 'You cannot delete this order it has been ordered already'}}" class="text-secondary fas fa-trash-alt"></i>
    </td>
  </ng-container>

  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
</table>
<div class="row">
  <div class="col-2">
    <button class="btn btn-outline-success" type="button" (click)="AddNewOrder()">
      Add New Order
    </button>
  </div>

  <!-- <div class="row justify-content-sm-center"> -->
  <div class="col-2 pt-1">
    <span class="badge rounded-pill p-2 ps-4 pe-4 bg-success">All prices + VAT</span>
  </div>
  <div class="col-2 pt-1">
    <span class="badge rounded-pill p-2 ps-4 pe-4 bg-success">Delivery £25 per consignment</span>
  </div>
  <!-- </div> -->

  <div class="col-6">
    <mat-paginator #paginator [pageSize]="20" [pageSizeOptions]="[10, 20, 50, 100, 999]" showFirstLastButtons>
    </mat-paginator>
  </div>
</div>