import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { bool } from 'aws-sdk/clients/redshiftdata';
import { Constants } from 'src/app/enums/constants';
import { PermissionEnum } from 'src/app/enums/enums';
import { ColumnItem, CurtainTrack, Curve, TableRow } from 'src/app/models/orders';
import { OrderItem, OrderStatus } from 'src/app/services/API.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-order-item-table',
  templateUrl: './order-item-table.component.html',
  styleUrls: ['./order-item-table.component.scss']
})
export class OrderItemTableComponent implements OnInit, OnChanges {

  @Input() orderItems: OrderItem[] = [];
  @Output() editClicked = new EventEmitter<OrderItem>();
  @Output() deleteClicked = new EventEmitter<OrderItem>();
  @Output() viewClicked = new EventEmitter<OrderItem>();

  CurtainRows: TableRow[] = [];
  HeadrailRows: TableRow[] = [];
  ExtraRows: TableRow[] = [];

  tableColumns: ColumnItem[] = [];

  isBenditAdmin: boolean = false;

  constructor(private auth: AuthService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.orderItems.currentValue?.length !== changes.orderItems.previousValue?.length) {
      this.SetColumns();
      this.orderRows();
    }
  }
  SetColumns() {
    this.CurtainRows = [];
    this.HeadrailRows = [];
    this.ExtraRows = [];
    this.MapTableColumns();
  }
  orderRows() {
    this.CurtainRows = this.CurtainRows.sort((a, b) => a.itemNumber > b.itemNumber ? 1 : -1);
    this.HeadrailRows = this.HeadrailRows.sort((a, b) => a.itemNumber > b.itemNumber ? 1 : -1);
  }

  ngOnInit(): void {
    this.auth.CurrentUserInGroup(PermissionEnum.BendItAdministrator).subscribe(isAdmin => {
      this.isBenditAdmin = isAdmin;
      this.SetColumns();
    });
  }

  MapTableColumns() {
    this.orderItems.forEach(orderItem => {
      let tags: string[] = [];
      orderItem.product.tags.items.forEach(t => {
        tags.push(t.tag.name);
      });

      if (tags.includes('track')) {

        if (tags.includes('vertical')) {
          //if(orderItem.product..productType === 'curve')
          this.CreateCurveRow(orderItem);
        } else {
          this.CreateTrackRow(orderItem);
        }
      } else {
        this.CreateCurtainExtraRow(orderItem);
      }
    });

  }

  private CreateCurtainExtraRow(orderItem: OrderItem) {

    //      var curve = JSON.parse(orderItem.customerInputs) as CurtainTrack;

    const tableRow = new TableRow();
    tableRow.tableId = 'CE';
    tableRow.product = orderItem.product.name;
    tableRow.description = orderItem.product.description;
    tableRow.type = orderItem.product.name;
    tableRow.shape = { label: 'Shape', value: '-', tooltip: undefined };
    tableRow.system = { label: 'System', value: 'Somfy', tooltip: undefined };
    tableRow.colour = { label: 'Colour', value: orderItem.product.colour, tooltip: undefined };
    tableRow.price = orderItem.price;
    tableRow.quantity = orderItem.quantity;
    tableRow.orderStatus = orderItem.order.orderStatus;
    tableRow.orderItem = orderItem;
    tableRow.discount = orderItem.discount;
    tableRow.fullPrice = orderItem.fullPrice;
    tableRow.isEditable = this.IsEditable(orderItem.order.orderStatus);
    this.ExtraRows.push(tableRow);
  }

  private CreateTrackRow(orderItem: OrderItem) {

    var curve = JSON.parse(orderItem.customerInputs) as CurtainTrack;

    const tableRow = new TableRow();
    tableRow.tableId = 'CT';
    tableRow.type = 'Curtain Track';
    tableRow.product = orderItem.product.name;
    tableRow.description = orderItem.product.description;
    tableRow.shape = { label: 'Shape', value: curve.shape.toString(), tooltip: undefined };
    tableRow.railType = { label: 'System', value: curve.railType.toString(), tooltip: undefined };
    tableRow.location = { label: 'Location', value: curve.location, tooltip: undefined };
    tableRow.dimensionA = { label: 'Dimension AB', value: curve.dimensionAB === null ? '-' : curve.dimensionAB.toString(), tooltip: undefined };
    tableRow.dimensionB = { label: 'Dimension BC', value: curve.dimensionBC === null ? '-' : curve.dimensionBC.toString(), tooltip: undefined };
    tableRow.dimensionC = { label: 'Dimension CD', value: curve.dimensionCD === null ? '-' : curve.dimensionCD.toString(), tooltip: undefined };
    tableRow.dimensionD = { label: 'Dimension Y', value: curve.dimensionY === null ? '-' : curve.dimensionY.toString(), tooltip: undefined };
    tableRow.measurements = { label: "i", value: 'M', tooltip: this.getMeasurements(curve) };
    tableRow.system = { label: 'System', value: 'Somfy', tooltip: undefined };
    tableRow.colour = { label: 'Colour', value: 'White', tooltip: undefined };
    tableRow.price = orderItem.price;
    tableRow.quantity = orderItem.quantity;
    tableRow.orderStatus = orderItem.order.orderStatus;
    tableRow.orderItem = orderItem;
    tableRow.discount = orderItem.discount;
    tableRow.fullPrice = orderItem.fullPrice;
    tableRow.itemNumber = curve.itemNumber;
    tableRow.isEditable = this.IsEditable(orderItem.order.orderStatus);
    this.CurtainRows.push(tableRow);
  }

  getMeasurements(curve: CurtainTrack): string {
    const shape = Constants.curtain_shapes.find(s => s.name === curve.shape.toString());

    let measurements = '';
    if (!shape || shape.measurements === undefined) return measurements;
    shape.measurements.forEach(m => {
      measurements += m + ': ' + curve['dimension' + m] + 'mm, ';
    });

    return measurements;
  }


  private CreateCurveRow(orderItem: OrderItem) {

    var curve = JSON.parse(orderItem.customerInputs) as Curve;

    const tableRow = new TableRow();
    tableRow.tableId = 'VR';
    tableRow.type = 'Vertical Rail';
    tableRow.product = orderItem.product.name;
    tableRow.shape = { label: 'Shape', value: curve.shape.toString(), tooltip: undefined };
    tableRow.location = { label: 'Location', value: curve.location, tooltip: undefined };
    tableRow.dimensionA = { label: 'Dimension A', value: curve.dimensionA === 0 ? '-' : curve.dimensionA.toString(), tooltip: undefined };
    tableRow.dimensionB = { label: 'Dimension B', value: curve.dimensionB === 0 ? '-' : curve.dimensionB.toString(), tooltip: undefined };
    tableRow.dimensionC = { label: 'Dimension C', value: curve.dimensionC === 0 ? '-' : curve.dimensionC.toString(), tooltip: undefined };
    tableRow.dimensionD = { label: 'Dimension D', value: curve.dimensionD === 0 ? '-' : curve.dimensionD.toString(), tooltip: undefined };
    tableRow.system = { label: 'System', value: curve.system.toString(), tooltip: undefined };
    tableRow.colour = { label: 'Colour', value: curve.railColour.toString(), tooltip: undefined };
    tableRow.quantity = orderItem.quantity;
    tableRow.price = orderItem.price;
    tableRow.orderStatus = orderItem.order.orderStatus;
    tableRow.orderItem = orderItem;
    tableRow.discount = orderItem.discount;
    tableRow.fullPrice = orderItem.fullPrice;
    tableRow.itemNumber = curve.itemNumber;
    tableRow.isEditable = this.IsEditable(orderItem.order.orderStatus);
    this.HeadrailRows.push(tableRow);
  }

  edit(row: TableRow) {
    this.editClicked.emit(row.orderItem);
  }

  delete(row: TableRow) {
    if (row.orderItem.order.orderStatus === 'quote') {
      this.deleteClicked.emit(row.orderItem);
    }
  }

  view(row: TableRow) {
    this.viewClicked.emit(row.orderItem);
  }

  GetPriceDiscountTooltip(tableRow: TableRow) {
    if (tableRow.discount === undefined || tableRow.discount === 0) {
      return '';
    }

    return 'Full price:£' + tableRow.fullPrice + ', Discount:£' + tableRow.discount;
  }

  GetAttr(attr: string, orderItem: OrderItem) {

    // need to map the inputs to the object required, Curve CurtainRail, ExtraItem
    const inputs = JSON.parse(orderItem.customerInputs) as Curve;

    return inputs[attr] === 0 ? '-' : inputs[attr];
  }
  
  IsEditable(orderStatus: OrderStatus): boolean {
    return orderStatus === 'quote' || (orderStatus === 'ordered' && this.isBenditAdmin);
  }
}


