<!-- <mat-form-field class="full-width p-1">
    <mat-label>Customer</mat-label>
    <mat-select (focus)="ControlEnter('rail')"  formControlName="railType">
      <mat-option *ngFor="let item of railTypes" [value]="item.key">
        {{ item.value }}
      </mat-option>
    </mat-select>
  </mat-form-field> -->

<div class="card p-3 mb-5 mt-5">
  <div class="badge bg-secondary administrator p-2">Bend It Administrator Panel</div>

  <app-customer-table [customers]="customers" [loading]="loading" (selectCustomer)="SelectCustomer($event)"
    (selectCustomerOrders)="SelectCustomerOrders($event)" (refreshData)="RefreshData()">
  </app-customer-table>

</div>

<app-loading [loading]="loading"></app-loading>