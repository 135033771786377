<div class="container">

  <div class="row">
    <div class="col-3">
      hello
    </div>
    <div class="col-9">

      <div class="my-google-map">
        <google-map
          height="640px"
          width="100%"
          [zoom]="zoom"
          [center]="center"
          [options]="options">
        </google-map>
      </div>

    </div>
  </div>

</div>
