export enum btnTypeEnum {
  email,
  tel,
  idLink
}

export enum PermissionEnum {
  CWS = 'CWSadministrators',
  ClientManagers = "ClientManagers",
  ClientAdmin = "ClientAdmin",
  ClientVeiwOnly = "ClientVeiwOnly",
  Customers = "Customers",
  Guest = "Guest",
  BendItAdministrator = "BendItadministrators"
}
