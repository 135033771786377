<div class="row">
  <div class="col-md-6 my-auto">
    <h2>Quick start quide to creating your first quote with Bend It</h2>
    <ul>
      <li>Click 'Start New Quote' above</li>
      <li>Enter your reference and order notes</li>
      <li>
        Add first curve
        <ul>
          <li>Enter shape and system details</li>
          <li>Enter size details</li>
          <li>Enter Controls and options</li>
        </ul>
      </li>
      <li>Repeat until all tracks added</li>
      <li>Once happy click place order to send order to Bend It</li>
    </ul>
    <!-- <h3>Create your first quote</h3>
        <p>To get started with your first quote follow these steps: <br><br>
            Click 'Start New Quote' above<br><br>
            Add your Customer Reference and any order notes that are necessary and click 'Create Quote'<br><br>
            Add each headrail needed to your quote <br><br>
            From the 'Orders' page you can then view this quote and add/remove/edit the headrails on the quote up
            until it is placed. <br><br>
            Once you are happy with the order click 'Place Order' from the 'Orders' page to send the order to Bend It.
            <br><br>
            Once an order is placed it cannot be amended or deleted. <br><br>
            You can then add as many quotes as you like from the 'Orders' Page <br><br>
        </p> -->
  </div>
  <div class="col-md-5">
    <iframe
      src="https://scribehow.com/embed/Workflow__XCbfIIFTRjGNdppc2J5ZWw"
      width="640"
      height="640"
      allowfullscreen
      frameborder="0"
      class="mx-auto"
    ></iframe>
  </div>
</div>
