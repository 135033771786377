<div class="button-group d-flex">
    <span class="minus"><i class="fas fa-minus-square control" (click)="down()"></i></span>

    <div class="number-container">
        <div class="badge bg-secondary bg-gradient number">{{currentTens}}</div>
      </div>
      <div class="number-container">
        <div class="badge bg-secondary bg-gradient number">{{currentUnits}}</div>
      </div>

    <span class="plus"><i class="fas fa-plus-square control" (click)="up()"></i></span>
</div>
<div class="pt-1">
    <span class="btn btn-outline-success enter cursor" (click)="enter()">{{buttonText}}</span>
</div>