<div class="modal" tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Add Address</h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">add form here</div>
      <div class="modal-footer">
        <button class="btn btn-primary" (click)="addAddress()">Add</button>
      </div>
    </div>
  </div>
</div>
