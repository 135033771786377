<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 669 160.5" height="100%">
  <defs>
    <style>
      .cls-1 {
        fill: #873000;
      }

      .cls-2 {
        fill: #fdff00;
        stroke: #2e3192;
      }

      .cls-10,
      .cls-2,
      .cls-3,
      .cls-8 {
        stroke-miterlimit: 10;
      }

      .cls-10,
      .cls-3,
      .cls-8 {
        fill: none;
      }

      .cls-3 {
        stroke: #fff;
        stroke-width: 10px;
      }

      .cls-4 {
        font-size: 12px;
        font-family: MyriadPro-Regular, Myriad Pro;
      }

      .cls-5 {
        letter-spacing: -0.04em;
      }

      .cls-6 {
        letter-spacing: -0.02em;
      }

      .cls-7 {
        letter-spacing: 0em;
      }

      .cls-8 {
        stroke-width: 2px;
        stroke-dasharray: 8 2;
      }

      .cls-9 {
        letter-spacing: 0em;
      }

      .cls-10 {
        stroke: #ff371f;
      }
    </style>
  </defs>
  <g id="revealR" class="revealYes">
    <rect class="cls-1" x="615.5" y="107" width="53" height="53" />
    <path d="M668,107.5v52H616v-52h52m1-1H615v54h54v-54Z" />
  </g>
  <g id="revealL" class="revealYes">
    <rect class="cls-1" x="0.5" y="107" width="53" height="53" />
    <path d="M53,107.5v52H1v-52H53m1-1H0v54H54v-54Z" />
  </g>
  <polygon class="cls-2" points="55.2 108.84 50.8 102.16 152.05 35.49 153.56 34.75 153.89 34.66 272.44 0.5 397.34 0.5 399.03 1.24 516.61 35.09 518.19 36.15 620.15 101.13 615.85 107.87 513.76 42.82 513.19 42.43 396.3 8.78 395.66 8.5 273.56 8.5 156.44 42.25 156.03 42.45 55.2 108.84" />
  <path id="curveNoReveal" class="cls-3 revealNo" d="M95,107.5C215.11,1.52,438.43,0,571,105.16" />
  <path id="curveReveal" class="cls-3 revealYes" d="M66,134.5c135.76-142,388.16-144,538-3.13" />
  <g id="noRevealR" class="revealNo">
    <rect class="cls-1" x="615.5" y="100" width="53" height="10" />
    <path d="M668,100.5v9H616v-9h52m1-1H615v11h54v-11Z" />
  </g>
  <g id="noRevealL" class="revealNo">
    <rect class="cls-1" x="0.5" y="97" width="53" height="10" />
    <path d="M53,97.5v9H1v-9H53m1-1H0v11H54v-11Z" />
  </g>

  <g id="measureBgroup" class="dimB">
    <g id="measureB">
      <line class="cls-8 dimBs" x1="336" y1="10.9" x2="336" y2="105.1" />
      <path class="dimB" d="M326.4,24.57a1,1,0,0,0,1.38-.3L336,11.36l8.22,12.91a1,1,0,0,0,1.38.3,1,1,0,0,0,.31-1.38L336.84,9a1,1,0,0,0-1.68,0l-9.07,14.23a1,1,0,0,0-.15.54A1,1,0,0,0,326.4,24.57Z" />
      <path class="dimB" d="M326.4,91.43a1,1,0,0,1,1.38.3L336,104.64l8.22-12.91a1,1,0,0,1,1.38-.3,1,1,0,0,1,.31,1.38L336.84,107a1,1,0,0,1-1.68,0l-9.07-14.23a1,1,0,0,1-.15-.54A1,1,0,0,1,326.4,91.43Z" />
    </g>
    <text class="cls-4 dimB" transform="translate(349.77 56.38)">B - Depth</text>
    <line id="guideB" class="cls-10" x1="359.84" y1="8.89" x2="312.98" y2="8.89" />
    <line id="guideAB" class="cls-10 dimB" x1="359.84" y1="107.89" x2="312.98" y2="107.89" />
  </g>

  <g id="measureA" class="dimA">
    <line class="cls-8 dimAs" x1="56.4" y1="107.5" x2="612.6" y2="107.5" />
    <path class="dimA" d="M70.07,117.1a1,1,0,0,0-.3-1.38L56.86,107.5l12.91-8.22a1,1,0,0,0,.3-1.38,1,1,0,0,0-1.38-.31l-14.23,9.07a1,1,0,0,0,0,1.68l14.23,9.07a1,1,0,0,0,.54.15A1,1,0,0,0,70.07,117.1Z" />
    <path class="dimA" d="M598.93,117.1a1,1,0,0,1,.3-1.38l12.91-8.22-12.91-8.22a1,1,0,0,1-.3-1.38,1,1,0,0,1,1.38-.31l14.23,9.07a1,1,0,0,1,0,1.68l-14.23,9.07a1,1,0,0,1-.54.15A1,1,0,0,1,598.93,117.1Z" />
    <text class="cls-4 dimA" transform="translate(203.04 99.14)">A - Width</text>
  </g>

  <line id="guideLReveal" class="cls-10" x1="41.34" y1="89.44" x2="67.48" y2="128.33" />
  <line id="guideRReveal" class="cls-10" x1="629.47" y1="90.14" x2="601.35" y2="127.63" />
</svg>
