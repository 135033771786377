<div class="content page-content my-5">
  <div class="container">
    <div>
      <button [routerLink]="['/account-home']" type="button" class="btn btn-sm btn-warning">
        <i class="fa fa-chevron-left me-2"> Account Home</i>
      </button>
      &nbsp;
      <button [routerLink]="['/orders']" type="button" class="btn btn-sm btn-success">
        <i class="fa fa-list me-2"> View Orders</i>
      </button>
    </div>
    <div class="row">
      <div class="col-12 d-flex justify-content-center">
        <h2 class="lead">{{customer?.companyName}} Account Details</h2>
      </div>
    </div>


    <div class="row mt-2 text-center mx-auto col-9">
      <p class="lead">
        Manage your account details, contact details and addresses from here.
      </p>
    </div>

    <app-change-customer *ngIf="isBenditAdmin"></app-change-customer>

    <div class="row d-flex justify-content-center mt-2">
      <div class="card text-center col-12">
        <div class="card-body">
          <form [formGroup]="form" class="text-start">
            <div class="mb-3 row">
              <label for="companyName" class="col-2 col-form-label">Company Name</label>
              <div class="col-4">
                <input type="text" class="form-control" id="companyName" value="" formControlName="companyName" />
              </div>
            </div>

            <div class="mb-3 row">
              <label for="accountNumber" class="col-2 col-form-label">Account Number</label>
              <div class="col-4">
                {{ accountNumber }}
              </div>
            </div>

            <div class="mb-3 row">
              <label for="customerTerms" class="col-2 col-form-label">Payment Terms</label>
              <div class="col-4">
                {{ customerTerms }}
              </div>
            </div>

            <div *ngIf="mToMDiscount > 0" class="mb-3 row">
              <label for="customerTerms" class="col-2 col-form-label">Made To Mesaue Discount</label>
              <div class="col-4">
                {{ mToMDiscount }}%
              </div>
              <div class="col-6">
                <div class="alert alert-info w-100 mb-0 pt-1 pb-1">Discount only applicable to made to measure items
                </div>
              </div>
            </div>

            <div *ngIf="boxedDiscount > 0" class="mb-3 row">
              <label for="customerTerms" class="col-2 col-form-label">Boxed Items Discount</label>
              <div class="col-4">
                {{ boxedDiscount }}%
              </div>
              <div class="col-6">
                <div class="alert alert-info w-100 mb-0 pt-1 pb-1">Discount only applicable to standard boxed items
                </div>
              </div>
            </div>

            <div *ngIf="orderDiscount > 0" class="mb-3 row">
              <label for="customerTerms" class="col-2 col-form-label">Order Discount</label>
              <div class="col-4">
                {{ orderDiscount }}%
              </div>
              <div class="col-6">
                <div class="alert alert-info w-100 mb-0 pt-1 pb-1">Discount applies to the whole order, but not delivery
                </div>
              </div>
            </div>

            <div class="mb-3 row">
              <label for="userName" class="col-2 col-form-label">User Name</label>
              <div class="col-4">
                {{ user.username }}
              </div>
              <div class="col-6">
                <div class="alert alert-info w-100 mb-0 pt-1 pb-1">You cannot change your user name</div>
              </div>
            </div>

            <div class="mb-3 row">
              <label for="firstName" class="col-2 col-form-label">First Name</label>
              <div class="col-4">
                <input type="text" class="form-control" id="firstName" value="" formControlName="firstName" />
              </div>
            </div>

            <div class="mb-3 row">
              <label for="lastName" class="col-2 col-form-label">Last Name</label>
              <div class="col-4">
                <input type="text" class="form-control" id="lastName" value="" formControlName="lastName" />
              </div>
            </div>

            <div class="mb-3 row">
              <label for="email" class="col-2 col-form-label">Email Address</label>
              <div class="col-4">
                <input type="text" class="form-control" id="email" value="" formControlName="email" />
              </div>
            </div>

            <div class="mb-3 row">
              <label for="phone" class="col-2 col-form-label">Phone Number</label>
              <div class="col-4">
                <input type="text" class="form-control" id="phone" value="" formControlName="phone" />
              </div>
            </div>
            <div class="mb-3 row">
              <label for="phoneOther" class="col-2 col-form-label">Phone Other</label>
              <div class="col-4">
                <input type="text" class="form-control" id="phoneOther" value="" formControlName="phoneOther" />
              </div>
            </div>
          </form>
          <div class="d-flex justify-content-end">
            <button class="btn btn-primary text-white ml-0" (click)="mode === 'view' ? edit() : save()">
              {{ mode === "view" ? "Edit details" : "Save" }}
              <i class="fas fa-circle-notch fa-spin mx-1" *ngIf="loading"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container mt-5">
    <div class="row text-center mx-auto col-9">
      <p class="lead">Manage your addresses from here</p>
    </div>
    <div *ngIf="
        addressStep == AddressStep.ViewAll && customer?.addresses?.items?.length
      " class="d-flex justify-content-center mt-2">
      <div class="col-12">
        <div class="text-center">
          <div class="col-12 d-flex flex-wrap justify-content-center">
            <div class="card text-start col-3 me-3 shadow-sm mb-3" *ngFor="let address of customer.addresses.items">
              <div class="card-header">
                <h3 class="card-title mb-0">
                  {{ address.addressType }} Address
                </h3>
              </div>
              <div class="card-body d-flex flex-column h-100">
                <div>
                  <p>{{ address.addr1 }}</p>
                  <p>{{ address.addr2 }}</p>
                  <p>{{ address.addr3 }}</p>
                  <p>{{ address.county }}</p>
                  <p>{{ address.postCode }}</p>
                </div>
                <div class="mt-auto">
                  <p class="fw-bold">Driver Instructions:</p>
                  <p>{{ address.driverInstructions }}</p>
                  <p class="fw-bold">Notes:</p>
                  <p>{{ address.notes }}</p>
                </div>
              </div>
              <div class="card-footer d-flex justify-content-between">
                <button class="btn btn-primary text-white" (click)="editAddress(address)">
                  <i class="fa fa-edit me-1"></i>Edit
                </button>
                <button class="btn btn-danger text-white" (click)="deleteAddress(address.id)">
                  <i class="fa fa-trash me-1"></i>Delete
                </button>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-end">
            <button class="btn btn-primary text-white ml-0" (click)="AddAddress()">
              Add new
              <i class="fas fa-circle-notch fa-spin mx-1" *ngIf="loading"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="row d-flex justify-content-center mt-2 py-3" *ngIf="
        addressStep == AddressStep.ViewAll &&
        !customer?.addresses?.items?.length
      ">
      <div>No addresses have been added click below to add your first one</div>
      <button class="btn btn-primary" (click)="AddAddress()">Add</button>
    </div>

    <div *ngIf="addressStep == AddressStep.AddNew" class="row d-flex justify-content-center mt-2">
      <div class="card text-center col-12">
        <div class="card-body">
          <form [formGroup]="addressForm" class="text-start">
            <div class="mb-3 row">
              <label for="addressType" class="col-3 col-form-label">Address Type</label>
              <div class="col-9">
                <select class="form-select" id="addressType" value="type" formControlName="addressType">
                  <option *ngFor="let type of addressTypes">{{ type }}</option>
                </select>
              </div>
            </div>
            <div class="mb-3 row">
              <label for="addr1" class="col-3 col-form-label">Address Line 1</label>
              <div class="col-9">
                <input type="text" class="form-control" id="addr1" value="" formControlName="addr1" />
              </div>
            </div>
            <div class="mb-3 row">
              <label for="addr2" class="col-3 col-form-label">Address Line 2</label>
              <div class="col-9">
                <input type="text" class="form-control" id="addr2" value="" formControlName="addr2" />
              </div>
            </div>
            <div class="mb-3 row">
              <label for="addr3" class="col-3 col-form-label">Address Line 3</label>
              <div class="col-9">
                <input type="text" class="form-control" id="addr3" value="" formControlName="addr3" />
              </div>
            </div>
            <div class="mb-3 row">
              <label for="county" class="col-3 col-form-label">County</label>
              <div class="col-9">
                <input type="text" class="form-control" id="county" value="" formControlName="county" />
              </div>
            </div>
            <div class="mb-3 row">
              <label for="postCode" class="col-3 col-form-label">Postcode</label>
              <div class="col-9">
                <input type="text" class="form-control" id="postCode" value="" formControlName="postCode" />
              </div>
            </div>

            <div class="mb-3 row">
              <label for="Country" class="col-3 col-form-label">Country</label>
              <div class="col-9">
                <input type="text" class="form-control" id="Country" value="UK" formControlName="Country" disabled />
              </div>
            </div>
            <div class="mb-3 row">
              <label for="driverInstructions" class="col-3 col-form-label">Driver Instructions</label>
              <div class="col-9">
                <textarea type="text" class="form-control" id="driverInstructions" value=""
                  formControlName="driverInstructions"></textarea>
              </div>
            </div>
            <div class="mb-3 row">
              <label for="notes" class="col-3 col-form-label">Notes</label>
              <div class="col-9">
                <textarea type="text" class="form-control" id="notes" value="" formControlName="notes"></textarea>
              </div>
            </div>
          </form>
          <div class="d-flex justify-content-end">
            <button class="btn btn-primary text-white ml-0" (click)="saveAddress()">
              Save
              <i class="fas fa-circle-notch fa-spin mx-1" *ngIf="loading"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>