<mdb-card class="card-deck wow flipInX text-center" data-wow-delay="delay">
  <!--Card image-->
  <!-- <mdb-card-img [src]="src" [class]="paddingCSSclass"></mdb-card-img> -->
  <div class="card-img-top">
    <div class="row pt-4">
      <div class="col-sm-6 offset-sm-3">
        <img
          class="img-fluid"
          [src]="src"
          alt="{{ cardTitle }}"
          title="{{ cardTitle }}"
        />
        <h2 class="py-2">{{ position }}</h2>
      </div>
    </div>
  </div>
  <!--Card content-->
  <!--Text-->

  <div class="text-center">
    <p class="text-white">{{ cardText }}</p>
    <h2>{{ name }}</h2>
    <!-- <p class="text-white">{{position}}</p> -->
  </div>
  <div>
    <a
      (click)="onCardButtonClicked()"
      class="btn stylish-color-dark"
      *ngIf="showButton"
      >{{ buttonText }}</a
    >
  </div>
</mdb-card>
