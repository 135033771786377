import { Component, OnInit } from '@angular/core';
import { price } from 'src/app/models/priciing';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})
export class PricingComponent implements OnInit {

  prices: price[];
  monthlies: price[];
  hourlies: price[];

  constructor(private dataService: DataService) {
    dataService.GetPricing().subscribe((data) => {
      this.prices = data.filter(w => !w.monthlyOnly && !w.hourlyOnly);
      this.monthlies = data.filter(w => w.monthlyOnly);
      this.hourlies = data.filter(w => w.hourlyOnly);
    });
  }

  ngOnInit(): void {
  }

}
