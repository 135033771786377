import { Injectable } from '@angular/core';
import {  throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { APIService, CreateOrderInput, CreateOrderItemInput,
  Order, OrderItem, UpdateOrderInput, UpdateOrderItemInput } from './API.service';
import { map, catchError } from 'rxjs/operators';
import { Curve } from '../models/orders';

@Injectable({
  providedIn: 'root'
})

export class OrderService {

  dbOrder: Order;

  // protected orderSubject: BehaviorSubject<BendItOrder>;
  // protected order : BendItOrder;

  constructor(private http: HttpClient, private apiService: APIService) {
    //  this.order = this.GetOrder();
    //  this.orderSubject = new BehaviorSubject<BendItOrder>(this.order);
  }

  AddUpdateOrder(order: Order) {
    if (order.id === undefined || order.id === null) {
      order.id = undefined;
      return this.apiService.CreateOrder(this.CreateOrderInput(order));
    } else {
      return this.apiService.UpdateOrder(this.CreateOrderInput(order) as UpdateOrderInput);
    }
  }

  AddUpdateOrderItem(orderItem: OrderItem) {

    if (orderItem.id === undefined || orderItem.id === null) {
      orderItem.id = undefined;
      return this.apiService.CreateOrderItem(this.CreateOrderItemInput(orderItem));
    } else {
      return this.apiService.UpdateOrderItem(this.CreateOrderItemInput(orderItem) as UpdateOrderItemInput);
    }
  }

  CreateOrderInput(order: Order): CreateOrderInput {

    return {
      addressID: order.addressID,
      customerID: order.customerID,
      offerID: order.offerID,
      customerReference: order.customerReference,
      dateDelivered: order.dateDelivered,
      dateOrdered: order.dateOrdered,
      datePaid: order.datePaid,
      delivery: order.delivery,
      discount: order.discount,
      id: order.id,
      itemsTotal: order.itemsTotal,
      offerDiscount: order.offerDiscount,
      orderNo: order.orderNo,
      orderNotes: order.orderNotes,
      orderStatus: order.orderStatus,
      orderTotal: order.orderTotal,
      price: order.price,
      tax: order.tax,
      invoiceNumber: order.invoiceNumber
     

    } as CreateOrderInput;
  }

  CreateOrderItemInput(orderItem: OrderItem) : CreateOrderItemInput {

    return {
      id: orderItem.id,
      name: orderItem.name,
      fullPrice: orderItem.fullPrice,
      discount: orderItem.discount,
      tax: orderItem.tax,
      price: orderItem.price,
      quantity: orderItem.quantity,
      customerInputs: orderItem.customerInputs,
      orderItemNotes: orderItem.orderItemNotes,
      group: orderItem.group,
      orderID: orderItem.orderID,
      productID: orderItem.productID,
      offerID: orderItem.offerID
    } as CreateOrderItemInput;
  }

  // id?: string | null;
  // name: string;
  // fullPrice?: number | null;
  // discount?: number | null;
  // tax?: number | null;
  // price?: number | null;
  // quantity?: number | null;
  // customerInputs?: string | null;
  // orderItemNotes?: string | null;
  // group?: string | null;
  // orderID: string;
  // productID: string;
  // offerID: string;

  // id: string;
  // name?: string | null;
  // fullPrice?: number | null;
  // discount?: number | null;
  // tax?: number | null;
  // price?: number | null;
  // quantity?: number | null;
  // customerInputs?: string | null;
  // orderItemNotes?: string | null;
  // group?: string | null;
  // orderID?: string | null;
  // productID?: string | null;
  // offerID?: string | null;

  SendOrder(order: Order) {

    return this.http.post<string>('https://8j3gl0zm1c.execute-api.eu-west-2.amazonaws.com/default/PhoenixContactForm',
      JSON.stringify(order)).
        pipe(
          map((data) => {
            return data;
      }), catchError( error => {
        return throwError( 'Something went wrong!' );
      })
   );
  }
}
