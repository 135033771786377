<nav
  class="navbar navbar-expand-lg navbar-dark header-color wow fadeInDown"
  data-wow-delay="0.2s"
>
  <div class="container">
    <a
      class="navbar-brand logo-name letter-spacing align-middle"
      href="#"
      [routerLink]="['/home']"
    >
      <img
        src="/assets/images/BendIt_Logo_Small_Rev.svg"
        class="cws-logo pr-5 mr-5"
        alt="Bend It Ltd"
      />
    </a>

    <button
      class="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <i class="fas fa-bars"></i>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <a class="logo-number text-white" href="tel:01492 536674">01492 536674</a>

      <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
        <li
          class="nav-item"
          data-bs-toggle="collapse"
          data-bs-target=".navbar-collapse.show"
        >
          <a class="nav-link" [routerLink]="['/home']">
            <i class="fas home text-white"></i> Home
          </a>
        </li>
        <li
          class="nav-item"
          data-bs-toggle="collapse"
          data-bs-target=".navbar-collapse.show"
        >
          <a class="nav-link" [routerLink]="['/curved-headrails']">
            <i class="fas home text-white"></i> Curved Headrails
          </a>
        </li>
        <li
          class="nav-item"
          data-bs-toggle="collapse"
          data-bs-target=".navbar-collapse.show"
        >
          <a class="nav-link" [routerLink]="['/curtain-tracks']">
            <i class="fas home text-white"></i> Curtain Tracks
          </a>
        </li>

        <li
          class="nav-item"
          data-bs-toggle="collapse"
          data-bs-target=".navbar-collapse.show"
        >
          <a class="nav-link" [routerLink]="['/retailers']">
            <i class="fas map-marked-alt text-white"></i> Find Retailers
          </a>
        </li>

        <li
          class="nav-item"
          data-bs-toggle="collapse"
          data-bs-target=".navbar-collapse.show"
        >
          <a class="nav-link" [routerLink]="'.'" [fragment]="'WhoAreWe'">
            <i class="fas users text-white"></i> Who are we?
          </a>
        </li>

        <li
          class="nav-item"
          data-bs-toggle="collapse"
          data-bs-target=".navbar-collapse.show"
        >
          <a class="nav-link" [routerLink]="'.'" [fragment]="'ContactForm'">
            <i class="fas envelope text-white"></i> Contact Us
          </a>
        </li>

        <app-header-user-menu></app-header-user-menu>
      </ul>
    </div>
  </div>
</nav>
