<div class="row">
  <div *ngIf="post" class="col-12">
    <a [routerLink]="'/blog/' + post.id"><img [src]="'/assets/images/' + post.mainImg" [alt]="post.title" class="img-fluid mx-auto d-block postImg"></a>
    <div class="p-3">
      <a [routerLink]="'/blog/' + post.id"><h2>{{post.title}}</h2></a>
      <p>{{post.auther}} - {{post.date}}</p>
      <p>{{post.description}}</p>
      <a [routerLink]="'/blog/' + post.id">Read Full Article...</a>
    </div>
  </div>
</div>
