import { Component, Input, OnInit } from '@angular/core';
import { QVC } from 'src/app/models/qvc';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-qvc',
  templateUrl: './qvc.component.html',
  styleUrls: ['./qvc.component.scss']
})
export class QvcComponent implements OnInit {

  @Input() id: string;
  qvc: QVC;
  constructor(private dataService: DataService) { }

  ngOnInit(): void {
    this.dataService.GetQvcItem().subscribe( (data) => {
      this.qvc = data.find(f => f.id === this.id);
    });
  }
}
