<div class="container">
    <div class="row">
        <div *ngIf="post" class="col-12">    
            <img [src]="'/assets/images/' + post.mainImg" [alt]="post.title" class="img-fluid mainImage d-block mx-auto">
            <div class="p-3">  
                <p>{{post.auther}} - {{post.date}}</p> 
                <h1>{{post.title}}</h1>
            </div>   
            <div [innerHTML]="html" class="p-3 mb-5"></div>          
        </div>
    </div>
</div>