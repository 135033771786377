import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Tag } from 'src/app/services/API.service';
import { BlogService } from 'src/app/services/blog.service';

@Component({
  selector: 'app-tag-picker',
  templateUrl: './tag-picker.component.html',
  styleUrls: ['./tag-picker.component.scss']
})
export class TagPickerComponent implements OnInit {

  @Input() tags: Tag[] = [];
  tagsPicked: Tag[] = [];

  @Output() TagsPicked = new EventEmitter<Tag[]>();

  constructor(private blogService: BlogService ) {
//    this.tags = this.blogService.allTags;
   }

  ngOnInit(): void {
  }

  tagClicked(tag: Tag) {
    if (!this.tagsPicked.includes(tag)) {
      this.tagsPicked.push(tag);
    } else {
      this.tagsPicked = this.tagsPicked.filter(w => w.id !== tag.id);
    }

    this.TagsPicked.emit(this.tagsPicked);
  }

  clearTags() { 
    this.tagsPicked = [];
    this.TagsPicked.emit(this.tagsPicked);
  }

  isActive(tag: Tag) {
    return this.tagsPicked.includes(tag);
  }
}
