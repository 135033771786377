<div class="feature-background">
  <div class="container">
  <div class="row">

      <div class="col-sm-12 col-md-4">
        <div class="p-4">
          <div class="card-img pb-3">
              <span class="fa-html5 fa" style="color: rgb(222, 39, 39); fill: rgb(222, 39, 39);"></span>
          </div>
          <div class="card-box">
              <h4 class="py-3">Websites</h4>
              <p class="mbr-text mbr-fonts-style display-7">
                  Single page brochure websites to multi-page fully database driven e-commerce sites.<br><br>Mobile friendly websites that are<br>fully responsive. <br><br>Front-End and Back-End applications.<br>no job too small &nbsp;or too big.
              </p>
          </div>
        </div>
      </div>

      <div class="col-sm-12 col-md-4">
        <div class="p-4">
          <div class="card-img pb-3">
              <span class="fa-apple fa" style="color: rgb(35, 35, 35); fill: rgb(35, 35, 35);"></span>
          </div>
          <div class="card-box">
              <h4 class="card-title py-3 mbr-fonts-style display-5">iOS Apps</h4>
              <p class="mbr-text mbr-fonts-style display-7">iPhone / iPad / iWatch<br><br>Bespoke in-house developement we do not outsource to other countries or companies<br><br>A Stand alone App or something to compliment your website. We've got the answer.<br></p>
          </div>
        </div>
      </div>

      <div class="col-sm-12 col-md-4">
        <div class="p-4">
          <div class="card-img pb-3">
              <span class="fa-android fa" style="color: rgb(26, 116, 8); fill: rgb(26, 116, 8);"></span>
          </div>
          <div class="card-box">
              <h4 class="card-title py-3 mbr-fonts-style display-5">
                  Android Apps</h4>
              <p class="mbr-text mbr-fonts-style display-7">
                  We can build your Android apps to exact specifications and do it with the incredible AWS serverless technologies for the back end.<br>If you've got an idea we can build it and don't forget we only ask for a 10% deposit and the rest can be part of the monthly maintanance cost, so, cashflow isn't hit hard at all.
              </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
