import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HostListener } from '@angular/core';

@Component({
  selector: 'app-numeric-input',
  templateUrl: './numeric-input.component.html',
  styleUrls: ['./numeric-input.component.scss']
})
export class NumericInputComponent implements OnInit {

  @Output() numberEntered = new EventEmitter<number>();
  @Output() number = new EventEmitter<number>();
  @Input() min: number = 1;
  @Input() max: number = 99;
  @Input() buttonText = 'Enter';

  // animationState = 'default';

  quantity = 1;

  currentTens = 0;
  previousTens = 0;
  currentUnits = 1;
  previousUnits = 1;
 
  constructor() { }

  @HostListener('window:keydown.ArrowUp') onKeyUp() {
    this.up();
  }

  @HostListener('window:keydown.ArrowRight')onKeyRight() {
    this.up();
  }

  @HostListener('window:keydown.ArrowDown') onKeyDown() {
    this.down();
  }

  @HostListener('window:keydown.ArrowLeft')onKeyLeft() {
    this.down();
  }

  @HostListener('window:keydown', ['$event']) onKey(event: KeyboardEvent) {
    const key = event.key;
    if (!isNaN(Number(key))) {
      this.quantity = this.quantity * 10 + Number(key);
      if(this.quantity > 99){
        this.quantity = this.quantity % 100;
      }
      this.setUnits('key');
    }
  }

  ngOnInit(): void {
  }

  down() {
    if (this.quantity > this.min) {
      this.quantity--;
      this.setUnits('down');
    }
  }

  up() {
    if (this.quantity < this.max) {
      this.quantity++;
      this.setUnits('up');
    }
  }

  setUnits(animationState: string) {
    this.previousTens = this.currentTens;
    this.previousUnits = this.currentUnits;

    this.currentTens = Math.floor(this.quantity / 10);
    this.currentUnits = this.quantity % 10;
    this.number.emit(this.quantity);
    //  this.animationState = animationState;
  }

  enter() {
    this.numberEntered.emit(this.quantity);
  }
}
