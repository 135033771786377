<div class="container">
  <div class="row">
    <div class="col">

      <div *ngIf="typesVisible" class="p-1 ml-2">
        <app-checkbox-selector [checkList]='allTypes' (OnSelected)="UpdateTypeSelection($event)" ></app-checkbox-selector>
      </div>

      <div *ngIf="catsVisible" class="p-1 ml-2">
        <app-checkbox-selector [checkList]='allCats' (OnSelected)="UpdateCategorySelection($event)" ></app-checkbox-selector>
      </div>

      <div *ngIf="perfectFitVisible || intuVisible" class="p-1 ml-2">
        <mat-radio-group (change)="UpdateSystem()" aria-labelledby="example-radio-group-label" class="radio-group" [(ngModel)]="systemSelection">
          <mat-radio-button class="radio-button pr-3" [value]="'Any'">Any</mat-radio-button>
          <mat-radio-button *ngIf="perfectFitVisible" class="radio-button pr-3" [value]="'Perfect'">Perfect-Fit</mat-radio-button>
          <mat-radio-button *ngIf="intuVisible" class="radio-button pr-3" [value]="'Intu'">Intu</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <ngx-masonry [options]="{columnWidth: 200, gutter: 20}">
        <div ngxMasonryItem class="masonry-item" *ngFor="let item of filteredGalleryIems">
            <app-gallery-item (itemClicked)="GalleryItemClicked($event)" [item]="item"></app-gallery-item>
        </div>
      </ngx-masonry>
    </div>
  </div>
</div>

<app-gallery-full [GalleryItem]="selectedItem" [itemClosed]="GalleryClosed()"></app-gallery-full>
