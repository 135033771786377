import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-feature-card-image',
  templateUrl: './feature-card-image.component.html',
  styleUrls: ['./feature-card-image.component.scss']
})
export class FeatureCardImageComponent implements OnInit {

  @Input()
  imgsrc: string;
  safeSrc: string;

  @Input()
  title: string;

  @Input()
  bodytext: string;

  @Input()
  delay: number;

  constructor() { }

  ngOnInit(): void {
    this.safeSrc = './assets/images/' + this.imgsrc;
  }

}
