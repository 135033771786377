import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-address-modal",
  templateUrl: "./address-modal.component.html",
  styleUrls: ["./address-modal.component.scss"],
})
export class AddressModalComponent implements OnInit {
  constructor() {}
  @Input() show: boolean = false;
  ngOnInit(): void {}

  addAddress() {}
}
