<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 668 178.3" height="100%">
  <defs>
    <style>
      .cls-1,
      .cls-5,
      .cls-6 {
        fill: none;
      }

      .cls-1 {
        stroke: #fff;
        stroke-width: 10px;
      }

      .cls-1,
      .cls-4,
      .cls-5,
      .cls-6 {
        stroke-miterlimit: 10;
      }

      .cls-2 {
        fill: #873000;
      }

      .cls-3 {
        font-size: 12px;
        font-family: MyriadPro-Regular, Myriad Pro;
      }

      .cls-4 {
        fill: #fdff00;
        stroke: #2e3192;
      }

      .cls-5 {
        stroke-width: 2px;
        stroke-dasharray: 8 2;
      }

      .cls-6 {
        stroke: #ff371f;
      }
    </style>
  </defs>

  <path id="curveReveal" class="cls-1 revealYes" d="M72.57,142.55,152,62.18A47.35,47.35,0,0,1,187.9,45.55H477.44a47.36,47.36,0,0,1,35.65,16.39l80.48,82.61" />
  <path id="curveNoReveal" class="cls-1 revealNo" d="M90.57,124.55l63-64c7.24-8,21.16-15,32-15H480.94A38.2,38.2,0,0,1,509.19,58l65.38,67.54" />

  <g id="revealR" class="revealYes">
    <rect class="cls-2" x="614.5" y="124.8" width="53" height="53" />
    <path d="M667,125.3v52H615v-52h52m1-1H614v54h54v-54Z" />
  </g>
  <g id="revealL" class="revealYes">
    <rect class="cls-2" x="0.5" y="124.8" width="53" height="53" />
    <path d="M53,125.3v52H1v-52H53m1-1H0v54H54v-54Z" />
  </g>
  <g id="noRevealR" class="revealNo">
    <rect class="cls-2" x="614.5" y="117.8" width="53" height="10" />
    <path d="M667,118.3v9H615v-9h52m1-1H614v11h54v-11Z" />
  </g>
  <g id="noRevealL" class="revealNo">
    <rect class="cls-2" x="0.5" y="117.8" width="53" height="10" />
    <path d="M53,118.3v9H1v-9H53m1-1H0v11H54v-11Z" />
  </g>

  <polygon class="cls-4" points="54.44 125.33 48.7 119.76 152.88 12.57 518.29 12.57 619.47 118.81 613.68 124.33 514.86 20.57 156.26 20.57 54.44 125.33" />


    <g id="measureA">
      <line class="cls-5 dimAs" x1="157.98" y1="21.55" x2="511.17" y2="21.55" />
      <path class="dimA" d="M171.65,31.15a1,1,0,0,0-.31-1.39l-12.91-8.21,12.91-8.22a1,1,0,1,0-1.08-1.69L156,20.7a1,1,0,0,0-.47.85,1,1,0,0,0,.47.84l14.22,9.06a1,1,0,0,0,.54.16A1,1,0,0,0,171.65,31.15Z" />
      <path class="dimA" d="M497.5,31.15a1,1,0,0,1,.31-1.39l12.9-8.21-12.9-8.22a1,1,0,1,1,1.07-1.69l14.23,9.06a1,1,0,0,1,.46.85,1,1,0,0,1-.46.84l-14.23,9.06a1,1,0,0,1-.54.16A1,1,0,0,1,497.5,31.15Z" />
    </g>
    <text class="cls-3 dimA" transform="translate(300 35)">A - Front Width</text>

  <g id="measureB">
    <line class="cls-5 dimBs" x1="155.9" y1="22.27" x2="57.25" y2="123.82" />
    <path class="dimB" d="M139.49,25.39a1,1,0,0,0,1.2.74l14.89-3.53-3.1,15a1,1,0,0,0,2,.41l3.42-16.52a1,1,0,0,0-.29-.92,1,1,0,0,0-.92-.26l-16.42,3.89a1.09,1.09,0,0,0-.49.28A1,1,0,0,0,139.49,25.39Z" />
    <path d="M59.89,107.33a1,1,0,0,1,.77,1.18l-3.09,15L72.45,120a1,1,0,0,1,1.21.75,1,1,0,0,1-.74,1.2L56.5,125.8a1,1,0,0,1-1.21-1.17l3.41-16.52a1.06,1.06,0,0,1,.27-.5A1,1,0,0,1,59.89,107.33Z" />
  </g>
  <text class="cls-3 dimB" transform="translate(93 110) rotate(-45.84)">B - Return Left</text>

  <g id="measureC">
    <line class="cls-5 dimCs" x1="611.91" y1="123.81" x2="515.24" y2="23.28" />
    <path class="dimC" d="M609.35,107.31a1,1,0,0,0-.78,1.18l3,15-14.87-3.6a1,1,0,1,0-.47,1.94l16.39,4a1,1,0,0,0,.93-.25,1,1,0,0,0,.29-.92l-3.33-16.54a1,1,0,0,0-.26-.5A1,1,0,0,0,609.35,107.31Z" />
    <path class="dimC" d="M531.63,26.48a1,1,0,0,1-1.21.73l-14.86-3.6,3,15a1,1,0,1,1-2,.39l-3.33-16.54a1,1,0,0,1,.28-.91,1,1,0,0,1,.93-.26l16.4,4a1.05,1.05,0,0,1,.48.28A1,1,0,0,1,531.63,26.48Z" />
  </g>
  <text class="cls-3 dimC" transform="translate(515 50) rotate(46.41)">C - Return Right</text>

  <g id="measureD">
    <line class="cls-5 dimDs" x1="57.4" y1="125.3" x2="611.17" y2="125.3" />
    <path class="dimD" d="M71.07,134.9a1,1,0,0,0-.3-1.38L57.86,125.3l12.91-8.22a1,1,0,0,0-1.08-1.69l-14.23,9.07a1,1,0,0,0,0,1.68l14.23,9.07a1,1,0,0,0,.54.15A1,1,0,0,0,71.07,134.9Z" />
    <path class="dimD" d="M597.5,134.9a1,1,0,0,1,.31-1.38l12.9-8.22-12.9-8.22a1,1,0,1,1,1.07-1.69l14.23,9.07a1,1,0,0,1,0,1.68l-14.23,9.07a1,1,0,0,1-.54.15A1,1,0,0,1,597.5,134.9Z" />
  </g>
  <text class="cls-3 dimD" transform="translate(295 115)">D - Width (Reveal)</text>

  <line id="guideAB" class="cls-6" x1="147" y1="0.22" x2="177.07" y2="61.05" />
  <line id="guideBC" class="cls-6" x1="523.95" y1="0.4" x2="494.07" y2="64.05" />
  <line id="guideAD" class="cls-6" x1="35.73" y1="106.7" x2="77.07" y2="147.05" />
  <line id="guideCD" class="cls-6" x1="632.07" y1="107.05" x2="587.07" y2="151.05" />
</svg>
