import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-banner-content",
  templateUrl: "./banner-content.component.html",
  styleUrls: ["./banner-content.component.scss"],
})
export class BannerContentComponent implements OnInit {
  @Input() theme: "dark" | "light" = "light";
  @Input() size: "narrow" | "wide" = "wide";

  constructor() {}

  ngOnInit(): void {}
}
