import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";

import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";
import { AddressModalComponent } from "src/app/components/address-modal/address-modal.component";
import { PermissionEnum } from "src/app/enums/enums";
import {
  Order,
  APIService,
  OrderStatus,
  Customer,
  UpdateCustomerInput,
  AddressType,
  CreateAddressInput,
  UpdateAddressInput,
  DeleteAddressInput,
  CreateCustomerInput,
  Address,
} from "src/app/services/API.service";
import { AuthService, AuthState } from "src/app/services/auth.service";

enum AddressStep {
  ViewAll,
  AddNew,
}

@Component({
  selector: "app-account",
  templateUrl: "./account.component.html",
  styleUrls: ["./account.component.scss"],
})
export class AccountComponent implements OnInit {
  @ViewChild("addressModal") addressModal: TemplateRef<any>;
  userState: AuthState;
  authSub: Subscription;
  customer: Customer;
  isBenditAdmin: boolean = false;
  editedCustomer: UpdateCustomerInput;
  addressType: AddressType;
  newAddress: CreateAddressInput;
  updatedAddress: UpdateAddressInput;
  customerTerms: string = "Pro-Forma";
  accountNumber: string;
  boxedDiscount: number = 0;
  mToMDiscount: number = 0;
  orderDiscount: number = 0;
  addresses;
  user;
  mode: "edit" | "view" = "view";
  loading: boolean = false;
  AddressStep = AddressStep;
  addressStep = AddressStep.ViewAll;
  addressTypes = [
    AddressType.delivery,
    AddressType.home,
    AddressType.invoice,
    AddressType.work,
  ];

  public form = new FormGroup({
    id: new FormControl(""),
    companyName: new FormControl("", [Validators.required]),
    userName: new FormControl("", [Validators.required]),
    firstName: new FormControl("", [Validators.required]),
    lastName: new FormControl("", [Validators.required]),
    email: new FormControl("", [Validators.required]),
    phone: new FormControl("", [Validators.required]),
    phoneOther: new FormControl("", [Validators.required]),
   // accountNumber: new FormControl( {value: '',disabled: true }),
   // customerTerms: new FormControl({value: '',disabled: true }),
    // addresses: new FormControl(""),
  });

  public addressForm = new FormGroup({
    id: new FormControl(""),
    customerID: new FormControl(""),
    addressType: new FormControl("", [Validators.required]),
    addr1: new FormControl("", [Validators.required]),
    addr2: new FormControl(""),
    addr3: new FormControl(""),
    county: new FormControl("", [Validators.required]),
    postCode: new FormControl("", [Validators.required]),
    Country: new FormControl("UK"),
    driverInstructions: new FormControl("", [Validators.maxLength(250)]),
    notes: new FormControl("", [Validators.maxLength(250)]),
   // customerAddressesId: new FormControl(""),
  });

  constructor(
    private authService: AuthService,
    private api: APIService,
    private toastrService: ToastrService
  ) {}

  ngOnInit(): void {
    if (this.mode === "view") {
      this.form.disable();
    }
    this.authSub = this.authService.auth$.subscribe((userState) => {
      this.userState = userState;
      this.authService.CurrentUserInGroup(PermissionEnum.BendItAdministrator).subscribe(auth => 
        {
          this.isBenditAdmin = auth
        });
      this.user = userState;
      this.customer = this.authService.customer;
      this.customerTerms = this.customer?.customerTerms.terms ??  "";
      this.accountNumber = this.customer?.accountNumber ?? "";
      this.boxedDiscount = this.customer?.standardItemsDiscount ?? 0;
      this.mToMDiscount = this.customer?.madeToMeasureDiscount ?? 0
      this.orderDiscount = this.customer?.orderDiscount ?? 0;
      // this.addresses = this.customer.addresses;
      if (this.customer) {
        this.form.patchValue({
          id: this.customer.id,
          userName: this.customer.userName,
          companyName: this.customer.companyName ?? "",
          firstName: this.customer.firstName ?? "",
          lastName: this.customer.lastName ?? "",
          phone: this.customer.phone ?? "",
          phoneOther: this.customer.phoneOther ?? "",
        });
      }
    });
  }

  edit() {
    this.mode = "edit";
    this.form.enable();
  }

  save() {
    this.mode = "view";
    this.form.disable();
    this.loading = true;

    this.editedCustomer = this.form.value;
   // console.log("char edit cust", this.editedCustomer);

    this.api
      .UpdateCustomer(this.editedCustomer)
      .then((data) => {
     //   console.log("charley", data);
        this.loading = false;
        this.toastrService.success("Account details updated");
      })
      .catch((error) => {
     //   console.log("Order Update Error: " + error.response);
        this.toastrService.error("Error updating details, please try again");
        this.loading = false;
      });
  }

  AddAddress() {
    this.addressStep = AddressStep.AddNew;
    this.addressForm.patchValue({
      customerID: this.customer.id,
     // customerAddressesId: this.customer.id,
      addressType: AddressType.delivery,
    });
  }

  saveAddress(addressId?) {
    this.addressForm.disable();
    this.loading = true;
    this.newAddress = this.addressForm.value;
    this.updatedAddress = this.addressForm.value;
   // console.log("char address", this.newAddress);

    if (this.addressForm.get("id").value) {
      this.updatedAddress.id = this.addressForm.get("id").value;
      this.api
        .UpdateAddress(this.updatedAddress)
        .then((data) => {
     //     console.log("char data", data);
          this.loading = false;
          this.addressStep = AddressStep.ViewAll;
          this.toastrService.success("Address updated successfully");
     //     console.log("char all addresses", this.customer.addresses);
        })
        .catch((error) => {
          console.log("Address Update Error: " + error.response);
          this.toastrService.error("Error adding Address, please try again");
          this.loading = false;
        });
    } else {
      this.newAddress = {
        ...this.newAddress,
        id: undefined,
        customerID: this.customer.id,
      };
      this.newAddress.id = undefined;
      this.api
        .CreateAddress(this.newAddress)
        .then((data) => {
      //    console.log("char data", data);
          this.customer.addresses.items.push(this.newAddress as Address);
          this.loading = false;
          this.addressStep = AddressStep.ViewAll;
          this.toastrService.success("Address added successfully");
        })
        .catch((error) => {
          console.log("Address Update Error: " + error.response);
          this.toastrService.error("Error adding Address, please try again");
          this.loading = false;
          this.addressForm.enable();
          this.addressForm.get("Country").disable();
        });
    }
  }

  deleteAddress(id) {
    this.api
      .DeleteAddress({id: id} as DeleteAddressInput)
      .then((data) => {
        this.customer.addresses.items = this.customer.addresses.items.filter(w => w.id !== id);
        this.loading = false;
        this.toastrService.success("Address deleted successfully");
      })
      .catch((error) => {
        console.log("Address Delete Error: " + error.response);
        this.toastrService.error("Error deleting Address, please try again");
      });
  }

  editAddress(address) {
    this.addressStep = AddressStep.AddNew;
    this.addressForm.patchValue(address);
  }
}
